import './App.css';
import './global-styles.css';
import './styles/select-styles.css';
import './styles/base-elements.css';
import { Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserProfile from './features/UserProfile/UserProfile';
import routes, { RouteElement } from './route-definitions';
import FamilyTree from './features/FamilyTree/FamilyTree';
import LoginInformation from './features/UserProfile/LoginInformation';
import LandingPage from './features/LandingPage/LandingPage';
import AboutPage from './features/AboutPage/AboutPage';
import ApiClient, { getUserId, initializeClient, isUserAdmin } from './utils/api-client';
import LoadingIcon from './features/xShared/LoadingIcon';
import RelativesSearchPage from './features/RelativesSearch/RelativesSearchPage';
import Map from './features/Map/Map';
import RegistrationPage, { RegistrationBasePage } from './features/Registration/RegistrationPage';
import UserSearchPage from './features/Admin/UserSearch/UserSearchPage';
import withMainLayout from './hooks/withMainLayout';
import GeneticDataView from './features/GeneticDataView/GeneticDataView';
import PhylogeneticTree from './features/PhylogeneticTree/PhylogeneticTree';
import Logs from './features/Logs/Logs';
import GeneticDataConflictResolution from './features/GeneticDataConflictResolution/GeneticDataConflictResolution';
import RightsManagement from './features/RightsManagement/RightsManagement';
import KitViewPage from './features/Admin/KitView/KitViewPage';
import AncestorProfile from './features/UserProfile/AncestorProfile';
import Errors from './features/xShared/Errors';
import ConfirmEmailChange from './features/Confirmation/ConfirmEmailChange';
import SetNewPassword from './features/Confirmation/SetNewPassword';
import ConfirmEmail from './features/Confirmation/ConfirmEmail';
import StaffManagement from './features/StaffManagement/StaffManagement';
import ConsentConfirmation from './features/Confirmation/ConsentConfirmation';
import LoginRedirection from './features/LandingPage/LoginRedirection';

const views: [RouteElement, JSX.Element][] = [
  [routes.landingRoute, <LandingPage />],
  [routes.mapRoute, withMainLayout(<Map />)],
  [routes.phyloTreeRoute, withMainLayout(<PhylogeneticTree />)],
  [routes.familyTreeRoute, withMainLayout(<FamilyTree />)],
  [routes.userFormRoute, <UserProfile />],
  [routes.changePasswordRoute, <LoginInformation />],
  [routes.ancestorFormRoute, <AncestorProfile />],
  [routes.relativesSearchRoute, <RelativesSearchPage />],
  [routes.aboutPageRoute, <AboutPage />],
  [routes.registrationRoute, <RegistrationPage />],
  [routes.registrationBaseRoute, <RegistrationBasePage />],
  [routes.adminSearchRoute, withMainLayout(<UserSearchPage />)],
  [routes.adminKitViewRoute, withMainLayout(<KitViewPage />)],
  [routes.geneticDataView, withMainLayout(<GeneticDataView />)],
  [routes.loggingRoute, withMainLayout(<Logs />)],
  [routes.geneticDataConflictResolution, withMainLayout(<GeneticDataConflictResolution />)],
  [routes.rightsManagement, withMainLayout(<RightsManagement />)],
  [routes.emailChangeConfirmRoute, <ConfirmEmailChange />],
  [routes.accountConfirmRoute, <ConfirmEmail />],
  [routes.passwordResetRoute, <SetNewPassword />],
  [routes.staffManagement, withMainLayout(<StaffManagement />)],
  [routes.consentConfirmRoute, <ConsentConfirmation />],
  [routes.loginRedirectRoute, <LoginRedirection />],
];

const theme = createTheme({
  palette: {
    success: {
      main: '#3D22A1',
    },
  },
});

function App() {
  if (!ApiClient.current) initializeClient();
  const { t } = useTranslation('errorCodes');

  return (
    <div className="app-container">
      <Errors
        ref={(Errs) => {
          ApiClient.ErrorContainer = Errs;
        }}
        t={t}
      />
      <ApolloProvider client={ApiClient.current}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LoadingIcon className="mt-5 min-vw-100" />}>
            <Routes>
              {views.map((item) => (
                <Route key={item[0].path} path={item[0].path} element={item[1]} />
              ))}
              <Route
                path="/"
                element={getUserId() ? withMainLayout(isUserAdmin() ? <Map /> : <GeneticDataView />) : <LandingPage />}
              />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
