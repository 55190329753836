import './GeneticDataView.css';
import './GeneticDataView.phone.css';
import { useTranslation } from 'react-i18next';
import { Container, Spinner } from 'react-bootstrap';
import { Alert, Button, Checkbox } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faExclamationTriangle, faEye, faSave } from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';
import { LoadingButton } from '@mui/lab';
import Tabs from '../xShared/Tabs';
import {
  AggregatedMarkersOrigin,
  GeneticDataTab,
  isAggregatedMarkersOrigin,
  Marker,
  MarkerSet,
  Origin,
  OriginsSet,
  ViewableUserData,
} from './models';
import {
  downloadSourceAsCsv,
  getAvailableData,
  getGeneticDataOf,
  uploadGeneticDataForUser,
  uploadManualGeneticDataInput,
} from './data-providers';
import { getUserId } from '../../utils/api-client';
import ManualDataUploadModal from '../Registration/ManualDataUploadModal';
import { RegistrationData } from '../Registration/types';

export default function GeneticDataView() {
  const { t } = useTranslation('dataView');
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(null);
  const [tabs, setTabs] = useState<GeneticDataTab[]>([]);
  const [displayedMarkers, setDisplayedMarkers] = useState<MarkerSet>([]);
  const [origins, setOrigins] = useState<OriginsSet>([]);
  const [loadingNewUserData, setLoadingNewUserData] = useState(true);
  const [loadingTabs, setLoadingTabs] = useState(true);
  const [uploadingGeneticDataFile, setUploadingGeneticDataFile] = useState(false);
  const [fileUploadFailed, setFileUploadFailed] = useState(false);
  const [fileUploadSuccessful, setFileUploadSuccessful] = useState(false);
  const [usersHaplogroup, setUsersHaplogroup] = useState<string>();
  const [manualMarkerData, setManualMarkerData] = useState(new RegistrationData());
  const [manualMarkerInputShown, setManualMarkerInputShown] = useState(false);
  const geneticDataFileUploadInput = useRef<HTMLInputElement>();

  const editableUserSelected = tabs[selectedTabIndex]?.data.isEditable;

  renameAggregatedMarkersLabelIfNecessary(origins, setOrigins, t);

  useEffect(() => {
    setLoadingTabs(true);

    getAvailableData(getUserId(), { firstTabLabel: t('myData') })
      .then((data) => {
        setTabs(data);
        setSelectedTabIndex(0);
      })
      .finally(() => setLoadingTabs(false));
  }, []);

  useEffect(() => {
    if (selectedTabIndex === null) return;

    fetchUserData({ useCache: true });
  }, [tabs, selectedTabIndex]);

  function fetchUserData(options: { useCache: boolean }) {
    const userData = tabs[selectedTabIndex].data;

    setLoadingNewUserData(true);
    setUsersHaplogroup(null);

    getGeneticDataOf(userData.id, { aggregatedDataLabel: t('aggregatedApprovedMarkers'), ...options }).then((data) => {
      setOrigins(data.origins);
      setUsersHaplogroup(data.haplogroup);
      setLoadingNewUserData(false);
    });
  }

  useEffect(() => {
    setFileUploadFailed(false);
    setFileUploadSuccessful(false);
  }, [selectedTabIndex]);

  useEffect(() => {
    if (!origins || origins.length === 0) return;

    resolveOriginsCheckboxValidity();

    const newMarkersForDisplay = mergeMarkersFromOrigins(origins);
    setDisplayedMarkers(newMarkersForDisplay);
  }, [origins, t]); // `t` is there because we might want to change some origins name depending on the language

  useEffect(() => {
    if (!tabs || tabs.length === 0) return;

    setTabs((prevTabs) => {
      prevTabs[0].label = t('myData');
      return [...prevTabs];
    });
  }, [t]);

  function resolveOriginsCheckboxValidity() {
    const aggregatedMarkersOrigin = origins[0];

    if (noOriginSelected() || onlyAggregatedMarkersOriginSelected()) {
      if (!aggregatedMarkersOrigin.selected || !aggregatedMarkersOrigin.checkboxDisabled) {
        aggregatedMarkersOrigin.selected = true;
        aggregatedMarkersOrigin.checkboxDisabled = true;
        setOrigins([...origins]);
      }
    } else if (aggregatedMarkersOrigin.checkboxDisabled) {
      aggregatedMarkersOrigin.checkboxDisabled = false;
      setOrigins([...origins]);
    }
  }

  function handleTabsChanged(tabsData: ViewableUserData, index: number) {
    if (uploadingGeneticDataFile) return;

    setSelectedTabIndex(index);
  }

  function switchSelected(index: number) {
    setOrigins((prev) => {
      const modifiedOrigins = [...prev];
      modifiedOrigins[index].selected = !modifiedOrigins[index].selected;

      return modifiedOrigins;
    });
  }

  function allOriginsSelected() {
    return origins.reduce((allSelectedSofar, origin) => allSelectedSofar && origin.selected, true);
  }

  function noOriginSelected(otherThanGlobalOrigins: Origin[] = null) {
    const queriedOrigins = otherThanGlobalOrigins ?? origins;
    return queriedOrigins.reduce((noOriginSelectedSoFar, o) => noOriginSelectedSoFar && !o.selected, true);
  }

  function onlyAggregatedMarkersOriginSelected() {
    const [aggregatedMarkersOrigin, ...otherOrigins] = origins;
    return aggregatedMarkersOrigin.selected && noOriginSelected(otherOrigins);
  }

  function selectOrUnselectAll() {
    const selectedValueForAll = !allOriginsSelected();

    setOrigins((prev) => {
      const modifiedOrigins = [...prev];
      modifiedOrigins.forEach((o) => {
        o.selected = selectedValueForAll;
      });

      const aggregatedMarkersOrigin = getAggregatedOrigin();
      aggregatedMarkersOrigin.selected = true;

      return modifiedOrigins;
    });
  }

  function getAggregatedOrigin() {
    return origins.find(isAggregatedMarkersOrigin);
  }

  function onlyOneSelected() {
    return origins.filter((o) => o.selected).length === 1;
  }

  function handleFTDNAFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.value;

    if (!file) return;

    const { userId } = tabs[selectedTabIndex];

    setFileUploadFailed(false);
    setUploadingGeneticDataFile(true);
    setFileUploadSuccessful(false);

    uploadGeneticDataForUser(userId, geneticDataFileUploadInput.current.files)
      .then(() => {
        setFileUploadSuccessful(true);
        fetchUserData({ useCache: false });
      })
      .catch(() => {
        setFileUploadFailed(true);
      })
      .finally(() => {
        setUploadingGeneticDataFile(false);
      });
  }

  function handleManualUploadOfGeneticData() {
    const { userId } = tabs[selectedTabIndex];

    setFileUploadFailed(false);
    setUploadingGeneticDataFile(true);
    setFileUploadSuccessful(false);

    let dataHasBeenSended = false;

    setManualMarkerData((data) => {
      if (dataHasBeenSended) {
        return data;
      }

      uploadManualGeneticDataInput(userId, data)
        .then(() => {
          setFileUploadSuccessful(true);
          fetchUserData({ useCache: false });
        })
        .catch(() => {
          setFileUploadFailed(true);
        })
        .finally(() => {
          setUploadingGeneticDataFile(false);
          setManualMarkerInputShown(false);
        });

      dataHasBeenSended = true;
      return data;
    });
  }

  const markersWithConflictWarnings =
    onlyOneSelected() && getAggregatedOrigin().selected
      ? new Set<string>(getAggregatedOrigin().conflictingMarkers)
      : new Set<string>();

  return (
    <Container>
      <h1 className="header-highlight header mt-5 mb-5">{t('geneticData')}</h1>
      {loadingTabs ? (
        <div className="genetic-data-spinner">
          <Spinner />
        </div>
      ) : (
        <Tabs
          tabs={tabs}
          hideTabs={tabs.length === 1}
          selectedTabIndex={selectedTabIndex}
          renderFunction={(tab) => (
            <div className="gen-data-tab-content">
              {tab.label}{' '}
              {tab.data.isEditable ? (
                <FontAwesomeIcon icon={faEdit} className="gen-data-view-edit-icon" />
              ) : (
                <FontAwesomeIcon icon={faEye} className="gen-data-view-view-icon" />
              )}
            </div>
          )}
          onFocusedChanged={(d, i) => handleTabsChanged(d, i)}
        >
          <div className="container pt-3 pb-3">
            <div className="genetic-data-container">
              {/* Table */}
              <div className="actual-gen-data-data-container">
                {usersHaplogroup && (
                  <Alert className="gen-data-haplogroup mb-3" severity="info" variant="standard">
                    {t('usersHaplogroup')} {usersHaplogroup}v
                  </Alert>
                )}
                <div className="markers-table">
                  {displayedMarkers.map((m) => (
                    <MarkerCell markersWithConflictWarnings={markersWithConflictWarnings} key={m.name} marker={m} />
                  ))}
                </div>
              </div>

              {/* Origins */}
              <div className="markers-file-list ms-5">
                <h3 className="marker-files-title mt-2">{t('dataOrigins')}</h3>
                <div className="origins-container mt-3 ms-2">
                  {origins.map((o, i) => (
                    <OriginRow key={o.name} origin={o} checkBoxClick={() => switchSelected(i)} />
                  ))}

                  <div className="mt-3">
                    <div>
                      <Button
                        variant="contained"
                        color={allOriginsSelected() ? 'error' : 'success'}
                        onClick={() => selectOrUnselectAll()}
                      >
                        {allOriginsSelected() ? t('unselectAll') : t('selectAll')}
                      </Button>
                    </div>

                    {editableUserSelected && (
                      <div className="mt-3">
                        <LoadingButton
                          component="label"
                          loading={uploadingGeneticDataFile}
                          variant="outlined"
                          loadingPosition="start"
                          startIcon={<FontAwesomeIcon icon={faSave} />}
                        >
                          {uploadingGeneticDataFile ? t('uploadingDataSource') : t('uploadDataSource')}
                          <input
                            ref={geneticDataFileUploadInput}
                            type="file"
                            hidden
                            onChange={(e) => handleFTDNAFileUpload(e)}
                          />
                        </LoadingButton>
                        <LoadingButton
                          component="label"
                          className="ms-2"
                          loading={uploadingGeneticDataFile}
                          variant="outlined"
                          loadingPosition="start"
                          startIcon={<FontAwesomeIcon icon={faSave} />}
                          onClick={() => {
                            setManualMarkerInputShown(true);
                          }}
                        >
                          {uploadingGeneticDataFile ? t('uploadingDataSource') : t('uploadDataSourceManually')}
                        </LoadingButton>
                      </div>
                    )}

                    {fileUploadFailed && (
                      <Alert severity="error" className="mt-2 gen-data-failed-alert">
                        {t('fileUploadFailed')}
                      </Alert>
                    )}

                    {fileUploadSuccessful && (
                      <Alert severity="success" className="mt-2">
                        {t('fileUploadSuccessful')}
                      </Alert>
                    )}

                    <ManualDataUploadModal
                      onHide={() => {
                        setManualMarkerInputShown(false);
                      }}
                      show={manualMarkerInputShown}
                      data={manualMarkerData}
                      setData={setManualMarkerData}
                      onNext={() => handleManualUploadOfGeneticData()}
                    />
                  </div>
                </div>
              </div>

              {loadingNewUserData && (
                <div className="genetic-data-spinner-overlay">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </Tabs>
      )}
    </Container>
  );
}

function OriginRow({ origin, checkBoxClick }: { origin: Origin | AggregatedMarkersOrigin; checkBoxClick: () => void }) {
  const { t } = useTranslation('dataView');

  return (
    <div key={origin.name} className="origin-container">
      <Checkbox
        disabled={origin.checkboxDisabled}
        className="origin-checkbox"
        checked={origin.selected}
        onChange={checkBoxClick}
      />
      {isAggregatedMarkersOrigin(origin) && origin.conflictingMarkers.length !== 0 && (
        <Warning message={t('unresolvedConflicts')} className="me-2" />
      )}
      <div className="origin-name">{origin.name}</div>

      <button type="button" className="download-origin-file-btn ms-2" onClick={() => downloadSourceAsCsv(origin)}>
        <FontAwesomeIcon className="download-origin-file-icon" icon={faDownload} />
      </button>
    </div>
  );
}

function MarkerCell({
  marker,
  markersWithConflictWarnings,
}: {
  marker: Marker;
  markersWithConflictWarnings: Set<string>;
}) {
  const { t } = useTranslation('dataView');

  return (
    <div key={marker.name} className="marker-cell">
      <div className="marker-name">{marker.name}</div>
      <div className="marker-values-container">
        {marker.values.map((v, i) => (
          <div className="value-dash-container" key={v.originName}>
            {markersWithConflictWarnings.has(marker.name) && (
              <Warning message={t('conflictingMarkerValue')} className="me-1" />
            )}
            <div className="marker-value">
              {v.value}
              <div className="origin">{v.originName}</div>
            </div>
            <div className="dash">{i !== marker.values.length - 1 ? '≠' : ''}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

function renameAggregatedMarkersLabelIfNecessary(
  origins: Origin[],
  setOrigins: (o: Origin[]) => void,
  t: TFunction<'dataView', undefined>
) {
  if (!origins || origins.length === 0) return;

  const aggregatedMarkersOrigin = origins[0];
  const aggregatedMarkersLabel = t('aggregatedApprovedMarkers');

  if (aggregatedMarkersOrigin.name === aggregatedMarkersLabel) return;

  setOrigins(renameOriginName(origins, { oldName: aggregatedMarkersOrigin.name, newName: aggregatedMarkersLabel }));
}

function renameOriginName(origins: Origin[], renameParams: { oldName: string; newName: string }) {
  const newArray = [...origins];

  newArray.forEach((o) => {
    if (o.name === renameParams.oldName) o.name = renameParams.newName;
    o.markers.forEach((m) => {
      m.values.forEach((v) => {
        if (v.originName === renameParams.oldName) v.originName = renameParams.newName;
      });
    });
  });

  return newArray;
}

function mergeMarkersFromOrigins(origins: Origin[]): Marker[] {
  const mergedMarkers: Marker[] = [];

  origins
    .filter((o) => o.selected)
    .forEach((o) => {
      o.markers.forEach((m) => {
        const marker = mergedMarkers.find((mm) => mm.name === m.name);

        if (marker)
          marker.values = mergeValues(
            marker.values,
            m.values.map((v) => ({ ...v, originName: o.name }))
          );
        else
          mergedMarkers.push({
            name: m.name,
            values: m.values.map((v) => ({ ...v, originName: o.name })),
          });
      });
    });

  return mergedMarkers;
}

function Warning({ message, className }: { message: string; className?: string }) {
  const [iconVisible, setIconVisible] = useState(false);
  // this prevents marker table from weird glitching
  setTimeout(() => setIconVisible(true), 50);

  return (
    <div className={`genetic-data-view-warning-container ${className}`}>
      {iconVisible && <FontAwesomeIcon icon={faExclamationTriangle} />}
      <div className="genetic-data-view-warning-message">
        {message.split('\n').map((line) => (
          <div key={line}>{line}</div>
        ))}
      </div>
    </div>
  );
}

function mergeValues(vals1: { value: number; originName: string }[], vals2: { value: number; originName: string }[]) {
  const newValues = vals1.map((v) => ({ ...v }));

  vals2.forEach((v2) => {
    const newValue = newValues.find((v) => v.value === v2.value);

    if (newValue) {
      newValue.originName += `\n${v2.originName}`;
    } else {
      newValues.push({ ...v2 });
    }
  });

  return newValues;
}
