import ApiClient from '../../utils/api-client';
import { MarkerDto, resolveMarkersMutation } from './api-mutations';
import { conflictingUsersQuery, RawUserReferences, RawDataOrigins, dataOriginsQuery } from './api-queries';
import { ConflictingGeneticData, MarkerValuesRow, UserReference } from './models';
import makeDataSourceNamesUnique from './shared-with-genetic-data-view';

export async function getConflictingUsers(): Promise<UserReference[]> {
  const rawConflictingUsers = await ApiClient.current.query<RawUserReferences>({
    query: conflictingUsersQuery,
  });

  return rawConflictingUsers.data.strConflicts.map((raw) => ({
    id: raw.user.id,
    name: `${raw.user.profile.givenName} ${raw.user.profile.familyName}`,
  }));
}

export async function getConflictingData(userId: number): Promise<ConflictingGeneticData> {
  const rawGeneticData = await ApiClient.current.query<RawDataOrigins>({
    query: dataOriginsQuery,
    variables: {
      userIds: [userId],
    },
  });

  const dataSources = makeDataSourceNamesUnique(
    rawGeneticData.data.strConflicts[0].user.geneticData.originalStrDataSources
  );

  const originMetaData = dataSources
    .map((source) => ({
      id: source.id,
      name: source.testProvider.name,
    }))
    .sort((o1, o2) => (o1.name > o2.name ? 1 : -1));

  const markerRowDictionary: { [markerName: string]: MarkerValuesRow } = {};

  dataSources
    .map((origin) => origin.strMarkers.map((marker) => ({ ...marker, originName: origin.testProvider.name })))
    .flat()
    .forEach((marker) => {
      const markerRow = markerRowDictionary[marker.name] ?? {
        markerName: marker.name,
        valuesByOrigin: {},
      };

      const markerValues = markerRow.valuesByOrigin[marker.originName] ?? [];
      markerValues.push(marker.value);

      markerRow.valuesByOrigin[marker.originName] = markerValues;
      markerRowDictionary[marker.name] = markerRow;
    });

  const conflictingMarkers = Object.values(markerRowDictionary).filter(isConflictingMarkerRow);
  const alignedMarkers = Object.values(markerRowDictionary)
    .filter(isAlignedMarkerRow)
    .map((m) => ({ ...m, noConflict: true }));

  return {
    dataOrigins: originMetaData,
    conflictingMarkers,
    alignedMarkers,
  };
}

export async function saveResolvedMarkers(
  userId: number,
  markerValues: { [markerName: string]: number }
): Promise<void> {
  const markerValuesDto: MarkerDto[] = Object.keys(markerValues).map((markerName) => ({
    name: markerName,
    value: markerValues[markerName],
  }));

  await ApiClient.current.mutate<void>({
    mutation: resolveMarkersMutation,
    variables: {
      userId,
      newMarkerValues: markerValuesDto,
    },
  });
}

function isConflictingMarkerRow(markerRow: MarkerValuesRow): boolean {
  const valueSet = new Set(Object.values(markerRow.valuesByOrigin).flat());
  return valueSet.size !== 1;
}

function isAlignedMarkerRow(markerRow: MarkerValuesRow): boolean {
  return !isConflictingMarkerRow(markerRow);
}
