import { useTranslation } from 'react-i18next';
import { GlobalCounts, HaplogroupFamilyNameCount } from '../../apiTypes/map';

interface Props {
  haplogroupFamilyNameCounts: HaplogroupFamilyNameCount[];
  globalCounts: GlobalCounts;
}

/**
 * Haplogroup distribution table for registered users
 * @param regionDetail API data of haplogroup distributions in region, including breakdown by family name
 */
function RegisteredMapModalTable({ haplogroupFamilyNameCounts, globalCounts }: Props) {
  const { t } = useTranslation('map');
  const haplogroupsWithUsers = haplogroupFamilyNameCounts.filter((hgfnc) => hgfnc.familyNameCounts.length > 0);

  return (
    <table className="haplo-table">
      <thead>
        <tr>
          <th>{t('haplogroup')}</th>
          <th>{t('userCount')}</th>
          <th>{t('countryUserCount')}</th>
          <th>{t('familyName')}</th>
          <th>{t('familyNameRegionCount')}</th>
          <th>{t('familyNameCountryCount')}</th>
        </tr>
      </thead>
      <tbody>
        {haplogroupsWithUsers.map((hgfnc) => {
          const hgName = hgfnc.haplogroup.name;
          const hgCount = hgfnc.familyNameCounts.reduce((acc, fnc) => acc + fnc.count, 0);
          const hgGlobalCount = globalCounts[hgfnc.haplogroup.id]?.total;
          return hgfnc.familyNameCounts.map((fnc, i) => (
            <tr>
              <td>{i === 0 ? hgName : ''}</td>
              <td>{i === 0 ? hgCount : ''}</td>
              <td>{i === 0 ? hgGlobalCount : ''}</td>
              <td>{fnc.familyName}</td>
              <td>{fnc.count}</td>
              <td>{globalCounts[hgfnc.haplogroup.id]?.byName[fnc.familyName]}</td>
            </tr>
          ));
        })}
      </tbody>
    </table>
  );
}

export default RegisteredMapModalTable;
