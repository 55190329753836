/* eslint-disable no-useless-concat */ // it is convenient way how to concat conditional classes in HTML template
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getConflictingData, getConflictingUsers, saveResolvedMarkers } from './conflict-resolution-providers';
import './GeneticDataConflictResolution.css';
import { ConflictingGeneticData, MarkerValuesRow, UserReference } from './models';

export default function GeneticDataConflictResolution() {
  const { t } = useTranslation('geneticDataConflictResolution');
  const [usersWithConflicts, setUsersWithConflicts] = useState<UserReference[]>();
  const [currentUser, setCurrentUser] = useState<UserReference>();
  const [currentGeneticData, setCurrentGeneticData] = useState<ConflictingGeneticData>();
  const [resultingMarkers, setResultingMarkers] = useState<{ [markerName: string]: number }>({});
  const [displayAlignedMarkers, setDisplayAlignedMarkers] = useState(false);
  const [displayedMarkerRows, setDisplayedMarkerRows] = useState<MarkerValuesRow[]>([]);
  const [successfullySaved, setSuccessfullySaved] = useState<boolean>(false);

  useEffect(() => {
    getConflictingUsers().then((users) => {
      const sortedUsers = [...users].sort((u1, u2) => (u1.name > u2.name ? 1 : -1));
      setUsersWithConflicts(sortedUsers);

      if (!noConflictsToBeResolved(sortedUsers)) {
        setCurrentUser(sortedUsers[0]);
      }
    });
  }, []);

  useEffect(() => {
    setCurrentGeneticData(null);

    if (!currentUser) return;
    setSuccessfullySaved(false);

    getConflictingData(currentUser.id).then((data) => {
      setCurrentGeneticData(data);

      const newResultingMarkers = getResultingMarkersFromAlignedData(data.alignedMarkers);

      setResultingMarkers(newResultingMarkers);
      setDisplayAlignedMarkers(false);
    });
  }, [currentUser]);

  useEffect(() => {
    if (!currentGeneticData) return;
    setSuccessfullySaved(false);

    let newRows = [...currentGeneticData.conflictingMarkers];

    if (displayAlignedMarkers) {
      newRows = newRows.concat(currentGeneticData.alignedMarkers);
    } else {
      const freshAlignedDataValues = getResultingMarkersFromAlignedData(currentGeneticData.alignedMarkers);
      const newResultingMarkers = { ...resultingMarkers, ...freshAlignedDataValues };

      setResultingMarkers(newResultingMarkers);
    }

    newRows.sort((a, b) => (a.markerName > b.markerName ? 1 : -1));

    setDisplayedMarkerRows(newRows);
  }, [currentGeneticData, displayAlignedMarkers]);

  function handleCurrentUserChanged(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const newUserId = event.target.value as unknown as number;

    setCurrentUser(usersWithConflicts.find((u) => u.id === newUserId));
  }

  function handleResultingMarkersChanged(markerName: string, value: number | null) {
    const newResultingMarkers = { ...resultingMarkers };

    if (value === null) {
      delete newResultingMarkers[markerName];
    } else {
      newResultingMarkers[markerName] = value;
    }

    setResultingMarkers(newResultingMarkers);
  }

  function handleSaveMarkers() {
    saveResolvedMarkers(currentUser.id, resultingMarkers).then(() => {
      setUsersWithConflicts(usersWithConflicts.filter((u) => u.id !== currentUser.id));

      setCurrentUser(null);
      setCurrentGeneticData(null);
      setSuccessfullySaved(true);
    });
  }

  if (!usersWithConflicts) return <Spinner className="conflicting-data-spinner" />;

  return (
    <div className="container">
      <h1 className="header mt-5">{t('geneticDataConflictResolutionTitle')}</h1>

      {noConflictsToBeResolved() && (
        <Alert severity="success" variant="filled" className="mt-5 no-conflict-alert">
          {t('noConflictsToResolve')}
        </Alert>
      )}

      {thereAreConflictsToBeResolved() && (
        <>
          <TextField
            select
            label={t('user')}
            value={currentUser?.id ?? ''}
            onChange={(e) => handleCurrentUserChanged(e)}
            size="small"
            className="mt-5 user-with-conflict-select"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {usersWithConflicts.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </TextField>

          <h3 className="header mt-5 mb-4">{t('geneticDataTitle')}</h3>

          {successfullySaved && (
            <Alert severity="success" variant="filled" className="markers-successfully-saved-alert">
              {t('markersSuccessfullySaved')}
            </Alert>
          )}

          {!currentUser && (
            <Alert severity="warning" variant="filled" className="mt-2 no-selected-user-alert">
              {t('noUserSelected')}
            </Alert>
          )}

          {currentUser && !currentGeneticData && <Spinner className="mt-3" />}

          {currentGeneticData && (
            <>
              <div className="conflict-table-controls-row mt-4">
                <Button
                  onClick={() => handleSaveMarkers()}
                  disabled={!allConflictsResolved()}
                  variant="contained"
                  color="success"
                >
                  {t('saveMarkers')}
                </Button>
                <FormControlLabel
                  label={t('displayNonConflictingMarkers')}
                  className="display-aligned-marker-values-checkbox ms-3"
                  control={
                    <Checkbox
                      checked={displayAlignedMarkers}
                      onChange={() => setDisplayAlignedMarkers(!displayAlignedMarkers)}
                    />
                  }
                />
              </div>

              <TableContainer className="genetic-data-conflicts-table-container mt-4" component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('markers')}</TableCell>

                      {currentGeneticData.dataOrigins.map((origin) => (
                        <TableCell key={`${origin.name} ${currentUser?.id}`} align="right">
                          {origin.name}
                        </TableCell>
                      ))}

                      <TableCell align="right"> {t('resultingMarkers')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedMarkerRows.map((markerRow) => (
                      <TableRow
                        key={markerRow.markerName}
                        className={
                          (resultingMarkers[markerRow.markerName] === undefined ? 'conflict-table-row-unfilled ' : '') +
                          (markerRow.noConflict ? ' conflict-table-row-no-conflict ' : '') +
                          (resultingMarkers[markerRow.markerName] &&
                          !Object.values(markerRow.valuesByOrigin)
                            .flat()
                            .includes(resultingMarkers[markerRow.markerName])
                            ? ' custom-value '
                            : '')
                        }
                      >
                        <TableCell className="pe-5" component="th" scope="row">
                          {markerRow.markerName}
                        </TableCell>
                        {currentGeneticData.dataOrigins.map((origin) => (
                          <TableCell key={`${origin.name} ${currentUser?.id}`} component="th" scope="row">
                            {markerRow.valuesByOrigin[origin.name]?.map((value) => (
                              <div key={`${origin.name}-${markerRow.markerName}-${value}`}>
                                <FormControlLabel
                                  label={value}
                                  control={
                                    <Checkbox
                                      style={{ padding: 0, paddingRight: 5 }}
                                      size="small"
                                      checked={markerValueIsChecked(markerRow.markerName, value)}
                                      onChange={() => handleResultingMarkersChanged(markerRow.markerName, value)}
                                    />
                                  }
                                />
                              </div>
                            ))}
                          </TableCell>
                        ))}
                        <TableCell className="ps-5" component="th" scope="row">
                          <TextField
                            type="text"
                            name="marker"
                            size="small"
                            id="outlined-basic"
                            variant="outlined"
                            autoComplete="off"
                            className="resulting-marker-value-input"
                            value={resultingMarkers[markerRow.markerName] ?? ''}
                            onChange={(e) =>
                              handleResultingMarkersChanged(markerRow.markerName, toPositiveNumber(e.target.value))
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                onClick={() => handleSaveMarkers()}
                disabled={!allConflictsResolved()}
                variant="contained"
                color="success"
                className="mt-4 mb-4"
              >
                {t('saveMarkers')}
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );

  function markerValueIsChecked(markerName: string, value: number) {
    const resultValue = resultingMarkers[markerName];
    return resultValue === value;
  }

  function noConflictsToBeResolved(users?: UserReference[]) {
    return (users ?? usersWithConflicts).length === 0;
  }

  function toPositiveNumber(input: string): number | null {
    return input !== '' ? +input.replace(/\D/g, '') : null;
  }

  function thereAreConflictsToBeResolved() {
    return !noConflictsToBeResolved();
  }

  function allConflictsResolved() {
    return [...currentGeneticData.conflictingMarkers, ...currentGeneticData.alignedMarkers].every(
      (makerRow) =>
        // !!! cannot use `!!resultingMarkers[makerRow.markerName]` as `!!0` is `false` but 0 is valid marker value
        resultingMarkers[makerRow.markerName] !== undefined && resultingMarkers[makerRow.markerName] !== null
    );
  }

  function getValueFromAligned(markerRow: MarkerValuesRow) {
    const allValues = Object.keys(markerRow.valuesByOrigin)
      .map((key) => markerRow.valuesByOrigin[key])
      .flat();
    return allValues[0];
  }

  function getResultingMarkersFromAlignedData(alignedMarkers: MarkerValuesRow[]): { [markerName: string]: number } {
    const markerValues = {};
    alignedMarkers.forEach((m) => {
      markerValues[m.markerName] = getValueFromAligned(m);
    });
    return markerValues;
  }
}
