import { User } from './user';
import { CollectionSegment } from './collectionSegment';

export enum KitStage {
  Analysed = 'ANALYSED',
  Pending = 'PENDING',
  Sent = 'SENT',
  Received = 'RECEIVED',
}

export enum KitEventEnum {
  Analyse = 'ANALYSE',
  Create = 'CREATE',
  Send = 'SEND',
  Receive = 'RECEIVE',
}

export interface KitEvent {
  date: string;
  event: KitEventEnum;
  note: string;
}

export interface Kit {
  actionRequired?: boolean;
  active?: boolean;
  id?: number;
  note?: string;
  stage?: KitStage;
  user?: User;
  history: KitEvent[];
}

export interface KitQueryResult {
  kits: CollectionSegment<Kit>;
}

export interface SequencerInput {
  firstTableRow?: FirstTableRow;
  secondTableRows?: SecondTableRow[];
  version?: string;
}

interface FirstTableRow {
  applicationType: string;
  barcodeNumber: string;
  capillaryLength: string;
  comments: string;
  numberOfWells: string;
  ownerName: string;
  plateName: string;
  polymer: string;
}

interface SecondTableRow {
  assay: string;
  comments: string;
  fileNameConvention: string;
  resultsGroup: string;
  sampleName: string;
  sampleType: string;
  userDefinedField1: string;
  userDefinedField2: string;
  userDefinedField3: string;
  userDefinedField4: string;
  userDefinedField5: string;
  well: string;
}

export interface PrepareSequencerQueryResult {
  prepareSequencerInput: SequencerInput;
}
