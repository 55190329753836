import dayjs from 'dayjs';
import { deepCopyOfSimpleObject, unique } from '../../utils/js-helpers';
import config from './config';
import {
  FamilyNodeEditLogic,
  FamilyTreeNode,
  NodeId,
  FamilyTreeEditFormState,
  Gender,
  createValidResult,
  FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT,
  createError,
  ValidationResult,
  Relations,
  PersonData,
} from './models';
import {
  addNewNodeToTheTree,
  find,
  getAllPeopleInSameSpouseCluster,
  hasBothParents,
  hasFather,
  hasMother,
  hasNoParents,
  isPlaceHolder,
  treeHasOneComponent,
  updateNode,
  treeContainsNodeWhoseParentsAreNotSpouses,
} from './tree-manipulations';

const TREE_IS_INCONSISTENT_ERROR_KEY = 'treeIsInconsistent';
const NODE_HAS_PARENT_WHICH_ARE_NOT_SPOUSES_ERROR_KEY = 'nodeHasParentsWhichAreNotSpouses';

function getAddParentLogic(childId: NodeId, tree: FamilyTreeNode[]): FamilyNodeEditLogic {
  const child = find(childId, tree);

  const { possibleGenders, justOneGenderOption } = getPossibleGendersForParent(child, tree);
  const predeterminedSpouseId = getPredeterminedParentSpouseOf(child, tree);

  return {
    keyToTitle: 'addParentFormTitle',
    defaultValues: deleteAllNulls({
      gender: justOneGenderOption ? possibleGenders[0] : null,
      spouses: predeterminedSpouseId ? [predeterminedSpouseId] : null,
      children: predeterminedSpouseId ? [...find(predeterminedSpouseId, tree).rels.children] : [child.id],
    }),
    disabled: {
      gender: justOneGenderOption,
      spouses: true,
      children: true,
      mother: true,
      father: true,
    },
    getPersonName: getDictionaryFunctionFrom(tree),
    optionsForSelects: {
      gender: possibleGenders,
      father: [],
      mother: [],
      spouses: predeterminedSpouseId ? [predeterminedSpouseId] : [],
      children: predeterminedSpouseId ? [...find(predeterminedSpouseId, tree).rels.children] : [child.id],
    },
    validate: {
      realtime: getPersonDataValidationFunction(),
      beforeSubmit: getEmptyValidationFunction(),
    },
    updateTreeWith: getAddNewNodeFunction({ mainNode: child }),
  };
}

function getAddChildLogic(parentId: NodeId, tree: FamilyTreeNode[]): FamilyNodeEditLogic {
  const parent = find(parentId, tree);

  const realSpouses = parent.rels.spouses?.filter((spouseId) => !isPlaceHolder(spouseId, tree)) ?? [];

  const suggestedOtherParent = realSpouses.length === 1 ? realSpouses[0] : null;

  return {
    keyToTitle: 'addChildFormTitle',
    defaultValues: deleteAllNulls({
      mother: parent.data.gender === Gender.Female ? parent.id : suggestedOtherParent,
      father: parent.data.gender === Gender.Male ? parent.id : suggestedOtherParent,
    }),
    disabled: {
      spouses: true,
      children: true,
      mother: parent.data.gender === Gender.Female,
      father: parent.data.gender === Gender.Male,
    },
    getPersonName: getDictionaryFunctionFrom(tree),
    optionsForSelects: {
      children: [],
      father: parent.data.gender === Gender.Male ? [parent.id] : [...realSpouses],
      mother: parent.data.gender === Gender.Female ? [parent.id] : [...realSpouses],
      gender: [Gender.Male, Gender.Female],
      spouses: [],
    },
    validate: {
      realtime: getPersonDataValidationFunction(),
      beforeSubmit: getEmptyValidationFunction(),
    },
    updateTreeWith: getAddChildNodeFunction(parent, tree),
  };
}

function getAddChildNodeFunction(parent: FamilyTreeNode, t: FamilyTreeNode[]) {
  const addNewNodeFunction = getAddNewNodeFunction({ mainNode: parent });
  const parentPlaceHolderSpouse = (parent.rels?.spouses ?? []).map((nId) => find(nId, t)).filter(isPlaceHolder)[0];

  return (state: FamilyTreeEditFormState, tree: FamilyTreeNode[]) => {
    let finalState = state;

    if (parentPlaceHolderSpouse) {
      if (parentPlaceHolderSpouse.data.gender === Gender.Male) {
        finalState = { ...state, father: parentPlaceHolderSpouse.id };
      } else {
        finalState = { ...state, mother: parentPlaceHolderSpouse.id };
      }
    }

    return addNewNodeFunction(finalState, tree);
  };
}

function getAddSpouseLogic(nodeId: NodeId, tree: FamilyTreeNode[]): FamilyNodeEditLogic {
  const node = find(nodeId, tree);

  const suggestedChildren = node.rels.children.filter((ch) => !hasBothParents(ch, tree));

  return {
    keyToTitle: 'addSpouseFormTitle',
    defaultValues: {
      spouses: [node.id],
      children: suggestedChildren,
      gender: node.data.gender === Gender.Male ? Gender.Female : Gender.Male,
    },
    disabled: {
      spouses: true,
      gender: true,
      mother: true,
      father: true,
    },
    getPersonName: getDictionaryFunctionFrom(tree),
    optionsForSelects: {
      children: [...suggestedChildren],
      father: [],
      mother: [],
      spouses: [node.id],
      gender: node.data.gender === Gender.Male ? [Gender.Female] : [Gender.Male],
    },
    validate: {
      realtime: getPersonDataValidationFunction(),
      beforeSubmit: getEmptyValidationFunction(),
    },
    updateTreeWith: getAddNewNodeFunction({ mainNode: node }),
  };
}

function getEditLogic(nodeId: NodeId, tree: FamilyTreeNode[]): FamilyNodeEditLogic {
  const node = find(nodeId, tree);

  const father = hasFather(node, tree) ? node.rels.father : null;
  const mother = hasMother(node, tree) ? node.rels.mother : null;

  const fatherOptions = (find(node.rels.mother, tree)?.rels.spouses ?? [])
    .concat([father])
    .filter((n) => !!n && !isPlaceHolder(n, tree));
  const motherOptions = (find(node.rels.father, tree)?.rels.spouses ?? [])
    .concat([mother])
    .filter((n) => !!n && !isPlaceHolder(n, tree));

  const childrenOptions = (node.rels.spouses ?? []).map((spouseId) => find(spouseId, tree)?.rels.children ?? []).flat();
  const spousesOption = getAllPeopleInSameSpouseCluster(node, tree).filter(
    (personId) => find(personId, tree).data.gender !== node.data.gender
  );

  return {
    keyToTitle: 'editPerson',
    defaultValues: deleteAllNulls({
      father: father ?? '',
      mother: mother ?? '',
      spouses: (node.rels.spouses ?? []).filter((spouseId) => !isPlaceHolder(spouseId, tree)),
      children: [...(node.rels.children ?? [])],
      firstName: node.data.firstName,
      familyName: node.data.familyName,
      dateOfBirth: node.data.dateOfBirth,
      gender: node.data.gender,
    }),
    disabled: {
      // not really progressive 😢 but `family-chart` library is not designed with that in mind
      gender: true,
    },
    getPersonName: getDictionaryFunctionFrom(tree),
    optionsForSelects: {
      father: unique(fatherOptions),
      mother: unique(motherOptions),
      children: [...childrenOptions],
      spouses: [...spousesOption],
      gender: [Gender.Male, Gender.Female],
    },
    validate: {
      realtime: getPersonDataValidationFunction(),
      beforeSubmit: getEditIsConsistentFunction(node, tree),
    },
    updateTreeWith: getUpdateFunctionOf(nodeId),
  };
}

function getEditIsConsistentFunction(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  return (state: FamilyTreeEditFormState): ValidationResult => {
    const treeCopy = deepCopyOfSimpleObject(tree);
    const nodeCopy = deepCopyOfSimpleObject(node);
    const stateCopy = deepCopyOfSimpleObject(state);

    const updateNodeFunction = getUpdateFunctionOf(nodeCopy.id);
    const editedTree = updateNodeFunction(stateCopy, treeCopy);

    if (!treeHasOneComponent(editedTree)) {
      return createError(TREE_IS_INCONSISTENT_ERROR_KEY);
    }

    if (treeContainsNodeWhoseParentsAreNotSpouses(editedTree)) {
      return createError(NODE_HAS_PARENT_WHICH_ARE_NOT_SPOUSES_ERROR_KEY);
    }

    return createValidResult();
  };
}

function getEmptyValidationFunction() {
  return createValidResult;
}

function getUpdateFunctionOf(nodeId: NodeId) {
  return (formState: FamilyTreeEditFormState, tree: FamilyTreeNode[]) => {
    const node = find(nodeId, tree);

    const nodeData = getDataFrom(formState);
    const nodeRelations = getRelationsFrom(formState);

    returnPlaceHolderSpousesToRelations(node, tree, nodeRelations);
    returnPlaceHOldersParents(node, tree, nodeRelations);

    updateNode(node, nodeData, nodeRelations, tree);

    return tree;
  };
}

function returnPlaceHOldersParents(node: FamilyTreeNode, tree: FamilyTreeNode[], relations: Relations) {
  if (hasBothParents(node, tree) || hasNoParents(node, tree)) {
    return;
  }

  if (hasFather(node, tree) && !relations.mother) {
    relations.mother = node.rels.mother;
  }

  if (hasMother(node, tree) && !relations.father) {
    relations.father = node.rels.father;
  }
}

function returnPlaceHolderSpousesToRelations(node: FamilyTreeNode, tree: FamilyTreeNode[], relations: Relations) {
  (node.rels?.spouses ?? [])
    .map((spouseId) => find(spouseId, tree))
    .filter(isPlaceHolder)
    .forEach((placeHolderSpouse) => {
      relations.spouses.push(placeHolderSpouse.id);
    });
}

function getAddNewNodeFunction(options?: { mainNode?: FamilyTreeNode }) {
  return (formState: FamilyTreeEditFormState, tree: FamilyTreeNode[]) => {
    const nodeData = getDataFrom(formState);
    const nodeRelations = getRelationsFrom(formState);

    const treeWithNode = addNewNodeToTheTree(nodeData, nodeRelations, tree);

    if (options?.mainNode) {
      // node at the the beginning is considered main by the `family-chart` library
      shuffleNodeToFront(options.mainNode.id, treeWithNode);
    }

    return treeWithNode;
  };
}

function getRelationsFrom(formState: FamilyTreeEditFormState): Relations {
  return {
    children: [...formState.children],
    spouses: [...formState.spouses],
    father: formState.father,
    mother: formState.mother,
  };
}

function getDataFrom(formState: FamilyTreeEditFormState): PersonData {
  return {
    firstName: formState.firstName,
    familyName: formState.familyName,
    gender: formState.gender,
    dateOfBirth: formState.dateOfBirth,
    avatar: config.getAvatarFor(formState.gender),
  };
}

function shuffleNodeToFront(nodeId: NodeId, tree: FamilyTreeNode[]) {
  const futureFrontNode = tree.find((n) => n.id === nodeId);
  const futureFrontIndex = tree.indexOf(futureFrontNode);

  const previousFrontNode = tree[0];
  tree[0] = futureFrontNode;
  tree[futureFrontIndex] = previousFrontNode;
}

function getPredeterminedParentSpouseOf(child: FamilyTreeNode, tree: FamilyTreeNode[]) {
  if (hasFather(child, tree)) return child.rels.father;
  if (hasMother(child, tree)) return child.rels.mother;

  return null;
}

function getPossibleGendersForParent(child: FamilyTreeNode, tree: FamilyTreeNode[]) {
  const possibleGenders = [];

  if (!hasFather(child, tree)) possibleGenders.push(Gender.Male);
  if (!hasMother(child, tree)) possibleGenders.push(Gender.Female);

  return {
    possibleGenders,
    justOneGenderOption: possibleGenders.length === 1,
  };
}

function getDictionaryFunctionFrom(tree: FamilyTreeNode[]) {
  return (id: NodeId) => {
    const node = find(id, tree);
    return node ? `${node.data.firstName} ${node.data.familyName}` : '';
  };
}

function getPersonDataValidationFunction() {
  return (state: FamilyTreeEditFormState) =>
    state.firstName.trim() &&
    state.familyName.trim() &&
    dayjs(state.dateOfBirth, FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT).isValid() &&
    !!state.gender;
}

function deleteAllNulls<T>(object: T) {
  Object.keys(object).forEach((key) => {
    if (object[key] === null) {
      delete object[key];
    }
  });
  return object;
}

const editingLogicsProviders = {
  getAddParentLogic,
  getAddChildLogic,
  getAddSpouseLogic,
  getEditLogic,
};

export default editingLogicsProviders;
