import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { FormEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { uploadAnalyzedQuery } from './queries';

function UploadAnalysedModal({ show, onHide }: ModalProps) {
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation('kits');
  const [upload] = useMutation(uploadAnalyzedQuery);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { files } = inputRef.current;
    if (files.length === 0) return;
    const file = files[0];
    upload({ variables: { file } }).then(() => window.location.reload());
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('uploadAnalysedTitle')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('analysisResult')}</Form.Label>
            <Form.Control ref={inputRef} type="file" accept=".csv" />
            <Form.Control.Feedback type="invalid">{t('analysedFileInvalid')}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {t('upload')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UploadAnalysedModal;
