/* eslint-disable react/jsx-props-no-spreading */
import { Spinner } from 'react-bootstrap';

function LoadingIcon({
  className = '',
  ...props
}: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={`${className} w-100 text-center`} {...props}>
      <Spinner />
    </div>
  );
}

export default LoadingIcon;
