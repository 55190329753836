import Papa from 'papaparse';
import { User } from '../../../apiTypes/user';

function userSearchToCsv(users: User[]): string {
  const fields = ['Jmeno', 'Prijmeni', 'Haploskupina', 'ID'];
  const data = users.map((u) => [u.profile.givenName, u.profile.familyName, u.geneticData.haplogroup.name, u.id]);
  return Papa.unparse({ fields, data });
}

export default userSearchToCsv;
