import { useState } from 'react';
import './MapModal.css';
import './MapModal.phone.css';
import { gql, useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import { GlobalCounts, MapRegionDetail, RegionLayer, RegionUserFilter } from '../../apiTypes/map';
import RegisteredMapModalTable from './RegisteredMapModalTable';
import UnregisteredMapModalTable from './UnregisteredMapModalTable';
import { getUserId } from '../../utils/api-client';

interface Props {
  region: any;
  regionDetail: MapRegionDetail;
  userFilter: RegionUserFilter;
  top: number;
  left: number;
  onClose: () => void;
}

const modalDimensions = {
  height: 350,
  width: 700,
  marginRight: 30,
  marginBottom: 30,
};

function getRealTopLeft(desiredTop: number, desiredLeft: number) {
  const maxTop = window.innerHeight - modalDimensions.height - modalDimensions.marginBottom;
  const maxLeft = window.innerWidth - modalDimensions.width - modalDimensions.marginRight;

  return {
    top: Math.min(desiredTop, maxTop),
    left: Math.min(desiredLeft, maxLeft),
  };
}

function toPoint(pos) {
  if (pos.clientX && pos.clientY) return { x: pos.clientX, y: pos.clientY };

  if (pos.left && pos.top) return { x: pos.left, y: pos.top };

  return null;
}

let lastMousePoint = { x: 0, y: 0 };
let nextZIndex = 0;

const currentMouseMoveListeners = {};

// We only use three map layers (orp=0, okres=1, kraj=2), so layer 3 is guaranteed to contain the global region
const GLOBAL_REGION_LAYER = 3;

const globalRegionIdQuery = gql`
  query GetGlobalRegionId {
    regionLayer(layerId: ${GLOBAL_REGION_LAYER}) {
      regions {
        id
        userCount
      }
    }
  }
`;

const globalRegionHaplogroupsQuery = gql`
  query GetGlobalRegionData($id: Int!) {
    regionDetail(regionId: $id) {
      haplogroupUserCounts {
        haplogroup {
          name
          id
        }
        count
      }
    }
  }
`;

// FIXME: this is the same query as the one in Map, will dedupe those once gql handling is refactored
const globalRegionFamilyNameHaplogroupsQuery = gql`
  query GetGlobalRegionFamilyNameData($id: Int!, $userFilter: RegionUserFilterInput) {
    regionDetail(regionId: $id, userFilterInput: $userFilter) {
      haplogroupFamilyNameCounts {
        haplogroup {
          id
        }
        familyNameCounts {
          familyName
          count
        }
      }
    }
  }
`;

function MapModal({ region, regionDetail, userFilter, top, left, onClose }: Props) {
  const loggedIn = !!getUserId();
  const query = loggedIn ? globalRegionFamilyNameHaplogroupsQuery : globalRegionHaplogroupsQuery;
  const [globalCountsByFamilyName, setGlobalCountsByFamilyName] = useState<GlobalCounts>({});
  const { data: layerData, loading: layerLoading } = useQuery<{ regionLayer: RegionLayer }>(globalRegionIdQuery);
  const { loading: regionLoading } = useQuery<{ regionDetail: MapRegionDetail }>(query, {
    skip: !layerData,
    variables: { id: layerData?.regionLayer.regions[0].id, userFilter },
    onCompleted: (data) => {
      const map = {};
      if (loggedIn)
        data.regionDetail.haplogroupFamilyNameCounts.forEach((hfnc) => {
          map[hfnc.haplogroup.id] = { total: 0, byName: {} };
          hfnc.familyNameCounts.forEach((fnc) => {
            map[hfnc.haplogroup.id].byName[fnc.familyName] = fnc.count;
            map[hfnc.haplogroup.id].total += fnc.count;
          });
        });
      else
        data.regionDetail.haplogroupUserCounts.forEach((huc) => {
          map[huc.haplogroup.id] = { total: huc.count };
        });
      setGlobalCountsByFamilyName(map);
    },
  });

  const notOverflowingPos = getRealTopLeft(top, left);
  const [modalPosition, setModalPosition] = useState<{ x: number; y: number }>(toPoint(notOverflowingPos));
  const [dragging, setDragging] = useState<Boolean>(false);
  const [modalZIndex, setModalZIndex] = useState<number>(nextZIndex++);

  if (!currentMouseMoveListeners[region]) currentMouseMoveListeners[region] = null;
  const loading = layerLoading || regionLoading;

  function moveToForeground() {
    setModalZIndex(nextZIndex++);
  }

  function dragHasBegun(event) {
    lastMousePoint = toPoint(event);
    setDragging(true);

    event.preventDefault();
  }

  function dragHasStopped(event) {
    setDragging(false);

    event.preventDefault();
  }

  function drag(event) {
    if (!dragging) return;

    const mousePt = toPoint(event);
    const diff = {
      x: mousePt.x - lastMousePoint.x,
      y: mousePt.y - lastMousePoint.y,
    };

    setModalPosition({
      x: modalPosition.x + diff.x,
      y: modalPosition.y + diff.y,
    });

    lastMousePoint = mousePt;
  }

  function replaceMouseMoveListener(listener) {
    const body = document.querySelector('body'); // has to be on `body` !!! it doesn't work if the event is on modal (if mouse leaves div it stops to receive events)

    body.removeEventListener('mousemove', currentMouseMoveListeners[region]);
    currentMouseMoveListeners[region] = listener;

    if (currentMouseMoveListeners[region]) body.addEventListener('mousemove', currentMouseMoveListeners[region]);
  }

  function internalOnClose() {
    replaceMouseMoveListener(null);
    onClose();
  }

  replaceMouseMoveListener(drag);
  const Table = loggedIn ? (
    <RegisteredMapModalTable
      haplogroupFamilyNameCounts={regionDetail.haplogroupFamilyNameCounts}
      globalCounts={globalCountsByFamilyName}
    />
  ) : (
    <UnregisteredMapModalTable
      haplogroupUserCounts={regionDetail.haplogroupUserCounts}
      globalCounts={globalCountsByFamilyName}
    />
  );

  return (
    <div
      className="map-modal window"
      style={{ top: modalPosition.y, left: modalPosition.x, zIndex: modalZIndex }}
      onMouseDown={moveToForeground}
      role="button"
      tabIndex={-1}
    >
      <div
        className={`haplo-modal-title ${dragging ? 'dragging-modal' : ''}`}
        onMouseDown={dragHasBegun}
        onMouseUp={dragHasStopped}
        role="button"
        tabIndex={-1}
      >
        <div>
          <span>{region.name}</span>
        </div>
      </div>

      <button type="button" className="haplo-modal-close" onClick={internalOnClose}>
        {'\u00d7'}
      </button>

      <div className="haplo-table-container w-100">
        {loading ? (
          <div className="mt-3 text-center">
            <Spinner />
          </div>
        ) : (
          Table
        )}
      </div>
    </div>
  );
}

export default MapModal;
