import ApiClient, { getProfileName, getUserId } from '../../utils/api-client';
import downloadBlob from '../../utils/downloadBlob';
import makeDataSourceNamesUnique from '../GeneticDataConflictResolution/shared-with-genetic-data-view';
import { ViewableUserData, OriginsSet, GeneticDataTab, Origin, ManualMarkersData } from './models';
import { uploadGeneticDataSourceMutation, uploadGeneticDataManuallyMutation } from './mutations';
import { availableEditDataQuery, availableViewDataQuery, RawUserGeneticData, userGeneticDataQuery } from './queries';

export async function getGeneticDataOf(
  userId: number,
  options: { aggregatedDataLabel: string; useCache: boolean }
): Promise<{ origins: OriginsSet; haplogroup: string }> {
  const userGeneticData = await ApiClient.current.query<RawUserGeneticData>({
    query: userGeneticDataQuery,
    variables: { userId },
    fetchPolicy: options.useCache ? 'cache-first' : 'no-cache',
  });

  const aggregatedMarkers = userGeneticData.data.user.geneticData.strMarkers.map((m) => ({
    name: m.name,
    values: [{ value: m.value, originName: options.aggregatedDataLabel }],
  }));

  const otherOrigins = makeDataSourceNamesUnique(userGeneticData.data.user.geneticData.originalStrDataSources).map(
    (o) => ({
      name: o.testProvider.name,
      selected: false,
      markers: o.strMarkers.map((m) => ({
        name: m.name,
        values: [{ value: m.value, originName: m.name }],
      })),
    })
  );

  const origins = [
    {
      name: options.aggregatedDataLabel,
      markers: aggregatedMarkers,
      selected: true,
      conflictingMarkers: [...(userGeneticData.data.user.geneticData.strConflict?.conflictingMarkers ?? [])],
    },
    ...otherOrigins,
  ];

  origins.forEach((o) => {
    o.name = replaceSpacesWithUnbreakable(o.name);
  });

  return { origins, haplogroup: userGeneticData.data.user.geneticData.haplogroup.name };
}

export function replaceSpacesWithUnbreakable(s: string) {
  return s.split(' ').join('\xA0');
}

export async function getAvailableData(userId: number, options: { firstTabLabel: string }): Promise<GeneticDataTab[]> {
  const viewableUsersPromise = ApiClient.current.query<{ userViewSettings: { viewableUsers: ViewableUserData[] } }>({
    query: availableViewDataQuery,
    variables: { userId },
  });

  const editableUsersPromise = ApiClient.current.query<{
    userManagementSettings: { managedUsers: ViewableUserData[] };
  }>({
    query: availableEditDataQuery,
    variables: { userId },
  });

  const [viewableUsers, editableUsers] = await Promise.all([viewableUsersPromise, editableUsersPromise]);

  const otherUsersData = [
    ...viewableUsers.data.userViewSettings.viewableUsers.map((u) => ({
      userId: u.id,
      key: u.id,
      label: `${u.profile.familyName} ${u.profile.givenName} `,
      data: { ...u, isEditable: false },
    })),
    ...editableUsers.data.userManagementSettings.managedUsers.map((u) => ({
      userId: u.id,
      key: u.id,
      label: `${u.profile.familyName} ${u.profile.givenName} `,
      data: { ...u, isEditable: true },
    })),
  ]
    .sort((u1, u2) => (u1.label > u2.label ? -1 : 1))
    // remove duplicities if user is editable and viewable
    .reduce((acc, curr) => {
      if (acc.length === 0) return [curr];

      const prev = acc[0];
      if (prev.userId === curr.userId) {
        prev.data.isEditable = true;
        return acc;
      }

      return [curr, ...acc];
    }, [] as GeneticDataTab[]);

  return [
    {
      userId,
      key: userId,
      label: options.firstTabLabel,
      data: {
        id: getUserId(),
        profile: {
          givenName: getProfileName().split(' ')[0],
          familyName: getProfileName().split(' ')[1],
        },
        isEditable: true,
      } as ViewableUserData,
    },
    ...otherUsersData,
  ];
}

export function uploadGeneticDataForUser(userId: number, files: FileList): Promise<unknown> {
  return ApiClient.current.mutate({
    mutation: uploadGeneticDataSourceMutation,
    variables: { userId, file: files[0] },
  });
}

export function uploadManualGeneticDataInput(userId: number, data: ManualMarkersData) {
  const variables = {
    userId,
    strMarkers: data.markers,
    geneticTestProvider: { name: data.dataSource },
    file: data.file,
  };

  return ApiClient.current.mutate({
    mutation: uploadGeneticDataManuallyMutation,
    variables,
  });
}

export function downloadSourceAsCsv(source: Origin) {
  const markersRow = source.markers.map((m) => m.name).join(',');
  const valuesRow = source.markers.map((m) => `"${m.values.map((v) => v.value).join('-')}"`).join(',');

  const csvContent = `${markersRow}\n${valuesRow}`;

  downloadBlob(new Blob([csvContent]), `${source.name}.csv`);
}
