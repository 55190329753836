import { gql } from '@apollo/client';

// query for searching a single kit based on its ID
export const kitSearchQuery = gql`
  query GetKit($id: Int) {
    kits(where: { id: { eq: $id } }) {
      totalCount
      items {
        stage
        id
        user {
          id
          profile {
            givenName
            familyName
            residenceAddress {
              street
              town
              zipCode
            }
          }
        }
      }
    }
  }
`;

// query for fetching (a page of) all kits that are in a particular stage
export const kitsInStageQuery = gql`
  query Kits($skip: Int, $take: Int, $stage: KitStage) {
    kits(skip: $skip, take: $take, where: { stage: { eq: $stage } }) {
      totalCount
      items {
        stage
        id
        note
        user {
          id
          profile {
            givenName
            familyName
            residenceAddress {
              street
              town
              zipCode
            }
          }
        }
      }
    }
  }
`;

// mutation for sending a list of kits to their next stage
export const sendToNextStageMutation = gql`
  mutation SendToNext($kitActions: [KitActionInput!]!) {
    advanceKitsToNextStage(kitActions: $kitActions) {
      id
    }
  }
`;

// query for fetching the sequencer configuration (which is used to generate the actual text file the sequencer accepts)
export const prepareSequencerQuery = gql`
  query PrepareSequencerInput($kitIds: [Int!]!) {
    prepareSequencerInput(kitIds: $kitIds) {
      firstTableRow {
        applicationType
        barcodeNumber
        capillaryLength
        comments
        numberOfWells
        ownerName
        plateName
        polymer
      }
      secondTableRows {
        assay
        comments
        fileNameConvention
        resultsGroup
        sampleName
        sampleType
        userDefinedField1
        userDefinedField2
        userDefinedField3
        userDefinedField4
        userDefinedField5
        well
      }
      version
    }
  }
`;

export const uploadAnalyzedQuery = gql`
  mutation UploadAnalyzed($file: Upload!) {
    uploadLabStrFile(file: $file) {
      kitsAdvanced
    }
  }
`;

export const updateKitMutation = gql`
  mutation UpdateKit($id: Int!, $note: String!) {
    updateKit(kitUpdateInput: { kitId: $id, note: $note }) {
      id
    }
  }
`;
