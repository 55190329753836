import { Form, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormEvent, useRef, useState } from 'react';
import { GeneticDataType, RegistrationProps } from './types';
import { ContinuationButton } from './Buttons';

interface Props extends RegistrationProps {
  isOpen: boolean;
  onHide: () => void;
}

function FtdnaDataUploadModal({ setData, onNext, isOpen, onHide }: Props) {
  const { t } = useTranslation('registration');
  const [isValid, setValid] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { files } = fileInputRef.current;
    if (files?.length === 0) {
      setValid(false);
      return;
    }
    setValid(true);
    setData((data) => data.cloneWithMap({ sendKit: false, dataType: GeneticDataType.Ftdna, file: files[0] }));
    onNext();
  };

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header closeButton>{t('geneticTest.ftdnaUploadHeader')}</Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup>
            <FormLabel>{t('geneticTest.ftdnaUploadLabel')}</FormLabel>
            <FormControl ref={fileInputRef} type="file" accept=".csv" isInvalid={!isValid} />
            <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ContinuationButton />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default FtdnaDataUploadModal;
