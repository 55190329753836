export function deepCopyOfSimpleObject<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export function removeFromArray<T>(item: T, arr: T[]) {
  if (!arr) return;

  const itemIndexToRemove = arr.indexOf(item);
  if (itemIndexToRemove === -1) return;

  arr.splice(itemIndexToRemove, 1);
}

export function getRandomString() {
  return Math.random().toString(36).substring(2);
}

export function addEventBeforeAnyOtherEvent(
  element: HTMLElement | SVGElement,
  eventName: keyof HTMLElementEventMap,
  eventListener: EventListenerOrEventListenerObject
) {
  const executeOnCapturingPhaseOfEvent = true;
  element.addEventListener(eventName, eventListener, executeOnCapturingPhaseOfEvent);
}

export function placeEventAtTheEndOfEventQueue(event: () => void) {
  setTimeout(event, 1);
}

export function clearArray<T>(arr: T[]) {
  arr.splice(0, arr.length);
}

export function isEmail(value: string) {
  return /(.+)@(.+){1,}\.(.+){1,}/.test(value);
}

export function symmetricDifference<T>(arr1: T[], arr2: T[]): T[] {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  const result = new Set([...arr1].filter((x) => !set2.has(x)).concat([...arr2].filter((x) => !set1.has(x))));
  return Array.from(result);
}

export function unique<T>(arr: T[]) {
  const set = new Set(arr);
  return Array.from(set);
}
