import { gql } from '@apollo/client';

export interface CreatedFamilyTreeResponse {
  addFamilyTree: {
    id: number;
  };
}

export const createNewFamilyTreeMutation = gql`
  mutation CreateFamilyTree($userId: Int!) {
    addFamilyTree(userId: $userId) {
      id
    }
  }
`;

export const updateFamilyTreeMutation = gql`
  mutation UpdateFamilyTree($userId: Int!, $treeId: Int!, $newTree: [FamilyTreeNodeInput!]!) {
    updateFamilyTree(userId: $userId, treeId: $treeId, nodes: $newTree) {
      id
    }
  }
`;
