import { gql } from '@apollo/client';

export const loginQuery = gql`
  query LoginByPassword($userName: String!, $password: String!) {
    loginByPassword(userName: $userName, password: $password) {
      token
      userId
      userType
    }
  }
`;

export const profileQuery = gql`
  query GetProfileName($userId: Int!) {
    user(userId: $userId) {
      profile {
        givenName
        familyName
      }
    }
  }
`;

export const passwordResetQuery = gql`
  query RequestPasswordReset($email: String!, $language: Language!) {
    requestPasswordReset(email: $email, language: $language) {
      operationSuccessful
    }
  }
`;
