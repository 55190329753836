import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stateUpdater, validateAndSubmit } from './utils';
import { RegistrationProps } from './types';
import { ButtonRow } from './Buttons';

function RegistrationLoginForm({ data, setData, onNext, onBack }: RegistrationProps) {
  const { t } = useTranslation('registration');
  const handleSubmit = () => {
    if (data.password === data.passwordRepeat) onNext();
  };

  return (
    <Form onSubmit={validateAndSubmit(handleSubmit, () => {})}>
      <h3>{t('loginInfo.title')}</h3>
      <Form.Group>
        <Form.Label>{t('loginInfo.email')}</Form.Label>
        <Form.Control required type="email" name="email" value={data.email} onChange={stateUpdater('email', setData)} />
        <Form.Control.Feedback type="invalid">{t('validation.invalidEmail')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('loginInfo.password')}</Form.Label>
        <Form.Control
          required
          minLength={9}
          type="password"
          name="password"
          value={data.password}
          onChange={stateUpdater('password', setData)}
        />
        <Form.Control.Feedback type="invalid">{t('validation.passwordCheck')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('loginInfo.passwordRepeat')}</Form.Label>
        <Form.Control
          type="password"
          name="passwordRepeat"
          id="registration-password-repeat"
          isInvalid={data.password !== data.passwordRepeat}
          value={data.passwordRepeat}
          onChange={stateUpdater('passwordRepeat', setData)}
        />
        <Form.Control.Feedback type="invalid">{t('validation.passwordMustMatch')}</Form.Control.Feedback>
      </Form.Group>
      <ButtonRow onBackClick={onBack} />
    </Form>
  );
}

export default RegistrationLoginForm;
