import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <p className="mb-0">
          Výzkumná databáze DNA profilů. Copyright © 2023 Kriminalistický ústav PČR a Matematicko-fyzikální fakulta UK.
        </p>
        <p className="mb-0">
          Odpovědná osoba za projekt Genetika a Příjmení: Mgr. Vlastimil Stenzl, Kriminalistický ústav PČR, email:
          vlastimil.stenzl@pcr.cz
        </p>
        <p>
          <a href="/about">Důležité informace.</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
