import './MainLayout.css';
import './MainLayout.phone.css';
import { PropsWithChildren } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopBar from '../TopBar/TopBar';
import SideMenu from '../SideMenu/SideMenu';
import { SideMenuItem } from '../SideMenu/side-menu-items';
import Footer from '../Footer/Footer';
import { getProfileName, isMasking, unmaskUser } from '../../../utils/api-client';
import LoginExpirationPopup from '../LoginExpirationPopup/LoginExpirationPopup';

function BackClickButton() {
  const navigate = useNavigate();
  const { t } = useTranslation('admin');
  const handleClick = async () => {
    const unmaskPath = await unmaskUser();
    navigate(unmaskPath);
  };

  return (
    <Button variant="link" onClick={handleClick}>
      {t('returnMessage')}
    </Button>
  );
}

interface Props {
  sideMenuItems: SideMenuItem[];
}

function MainLayout({ sideMenuItems, children }: PropsWithChildren<Props>) {
  const { t } = useTranslation('admin');

  return (
    <div className="layout-container">
      <div className="login-expiration-container">
        {/* random key ensures that login popup is open every time user navigates to other page if needed */}
        <LoginExpirationPopup key={getRandomKey()} />
      </div>

      <div className="top-bar-in-main-layout-container">
        <TopBar />
      </div>

      {isMasking() && (
        <div className="masking-notice-container">
          {t('maskMessage', { user: getProfileName() })} <BackClickButton />
        </div>
      )}

      <div className="side-menu-in-main-layout-container">
        <SideMenu items={sideMenuItems} />
      </div>

      <div className="content-container">{children}</div>

      <div className="layout-footer-container">
        <Footer />
      </div>
    </div>
  );
}

function getRandomKey() {
  return Math.random();
}

export default MainLayout;
