import { gql } from '@apollo/client';

export const municipalitiesQuery = gql`
  query GetMunicipalities {
    administrativeRegions(regionType: MUNICIPALITY) {
      id
      name
    }
  }
`;

export const registerWithTestMutation = gql`
  mutation RegisterWithTest($input: RegisterWithLabTestInput!) {
    registerWithLabTest(input: $input) {
      userId
    }
  }
`;

export const registerWithSupportedProviderMutation = gql`
  mutation RegisterWithSupported($input: RegisterWithSupportedExternalTestProviderInput!) {
    registerWithSupportedExternalTestProvider(input: $input) {
      userId
    }
  }
`;

export const registerWithUnsupportedProviderMutation = gql`
  mutation RegisterWithUnsupported($input: RegisterWithNotSupportedExternalTestProviderInput!) {
    registerWithNotSupportedExternalTestProvider(input: $input) {
      userId
    }
  }
`;
