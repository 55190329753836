import { Pagination } from 'react-bootstrap';
import { PropsWithChildren } from 'react';

function range(start: number, end: number) {
  return Array.from({ length: end - start }, (x, i) => i + start);
}

interface Props {
  current: number;
  count: number;
  onSetPage: (n: number) => void;
  surrounding?: number;
}

function PaginationRow({ current, count, onSetPage, surrounding }: Props) {
  const endOffset = Math.min(count - 1 - current - surrounding, 0);
  const start = Math.max(current - surrounding + endOffset, 0);
  const end = Math.min(start + 2 * surrounding + 1, count);
  return (
    <Pagination className="justify-content-center">
      <Pagination.First onClick={() => onSetPage(0)} disabled={current === 0} />
      <Pagination.Prev onClick={() => onSetPage(current - 1)} disabled={current === 0} />
      {range(start, end).map((n) => (
        <Pagination.Item active={n === current} onClick={() => onSetPage(n)}>
          {n + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={() => onSetPage(current + 1)} disabled={current === count - 1} />
      <Pagination.Last onClick={() => onSetPage(count - 1)} disabled={current === count - 1} />
    </Pagination>
  );
}

function Paged({ current, count, onSetPage, surrounding = 2, children }: PropsWithChildren<Props>) {
  return (
    <div className="paged">
      <PaginationRow current={current} count={count} onSetPage={onSetPage} surrounding={surrounding} />
      {children}
      <PaginationRow current={current} count={count} onSetPage={onSetPage} surrounding={surrounding} />
    </div>
  );
}

export default Paged;
