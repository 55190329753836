import { Button, Col, Form, Modal, ModalProps, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Kit } from '../../../apiTypes/kits';
import { getAddressSummary, getFullName } from './utils';
import { maskUser } from '../../../utils/api-client';
import routes from '../../../route-definitions';
import KitsTab from './KitsTab';
import { updateKitMutation } from './queries';

interface Props extends ModalProps {
  kit: Kit;
}

function KitDetailModal({ show, onHide, kit }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(['kits', 'profile']);
  const [note, setNote] = useState(kit?.note ?? '');

  const [updateNote, { loading }] = useMutation(updateKitMutation);

  useEffect(() => {
    if (kit) setNote(kit.note);
  }, [kit]);

  const handleShowDetail = async () => {
    if (!kit.user.id) throw Error('user has no ID');
    else await maskUser(kit.user, routes.adminKitViewRoute.path);
    navigate(routes.userFormRoute.path);
  };

  const handleUpdateNote = () => {
    updateNote({ variables: { id: kit.id, note } }).then(() => window.location.reload());
  };

  if (!kit) return null;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label as="b">{t('fullName')}</Form.Label>
            <Form.Control readOnly plaintext value={getFullName(kit)} />
          </Form.Group>
          <Form.Group>
            <Form.Label as="b">{t('profile:address')}</Form.Label>
            <Form.Control readOnly plaintext value={getAddressSummary(kit)} />
          </Form.Group>
          <Form.Group>
            <Form.Label as="b">{t('status')}</Form.Label>
            <Form.Control readOnly plaintext value={t(`stage.${kit.stage}`)} />
          </Form.Group>
          <Form.Group>
            <Form.Label as="b">{t('note')}</Form.Label>
            <Row>
              <Col xs={9}>
                <Form.Control as="textarea" value={note} onChange={(e) => setNote(e.target.value)} />
              </Col>
              <Col xs={3}>
                <Button variant="success" disabled={loading} onClick={handleUpdateNote}>
                  {loading ? <Spinner /> : t('upload')}
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowDetail}>
          {loading ? <Spinner /> : t('showProfile')}
        </Button>
        <KitsTab tab={kit.stage} kit={kit} />
      </Modal.Footer>
    </Modal>
  );
}

export default KitDetailModal;
