import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationProps } from './types';
import { ButtonRow } from './Buttons';
import { stateUpdater, validateAndSubmit } from './utils';

function RegistrationConsentForm({ setData, onNext, onBack }: RegistrationProps) {
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation('registration');

  return (
    <Form noValidate validated={validated} onSubmit={validateAndSubmit(onNext, setValidated)}>
      <h3>{t('consent')}</h3>
      <iframe title="consent" src="/IS_web.pdf" height="600px" width="800px" />
      <Form.Group>
        <Form.Check
          onChange={stateUpdater('consent', setData, 'checked')}
          required
          type="checkbox"
          name="consent"
          label={t('description.consent')}
        />
      </Form.Group>
      <ButtonRow onBackClick={onBack} />
    </Form>
  );
}
export default RegistrationConsentForm;
