import { CreatedFamilyTreeResponse, createNewFamilyTreeMutation, updateFamilyTreeMutation } from './api-mutations';
import ApiClient from '../../utils/api-client';
import { familyTreeQuery, RawFamilyTree, RawFamilyTreeIds, userFamilyTreesQuery } from './api-queries';
import { FamilyTreeNode, Gender } from './models';
import { removePlaceholdersFrom } from './tree-manipulations';
import familyTreeConfig from './config';

const API_GENDER_MALE = 'MALE';
const API_GENDER_FEMALE = 'FEMALE';

export async function fetchFamilyTreeFromServer(userId: number) {
  const availableTreeResponse = await ApiClient.current.query<RawFamilyTreeIds>({
    query: userFamilyTreesQuery,
    variables: {
      userId,
    },
  });

  const userTrees = availableTreeResponse.data.familyTreesOfUser.map((idObject) => idObject.id);
  const familyTreeId = userTrees.length > 0 ? userTrees[0] : await createNewTreeForUser(userId);

  const rawFamilyTree = await ApiClient.current.query<RawFamilyTree>({
    query: familyTreeQuery,
    variables: {
      treeId: familyTreeId,
      userId,
    },
  });

  return { treeFromServer: convertFromDto(rawFamilyTree.data), treeId: familyTreeId };
}

async function createNewTreeForUser(userId: number): Promise<number> {
  const response = await ApiClient.current.mutate<CreatedFamilyTreeResponse>({
    mutation: createNewFamilyTreeMutation,
    variables: {
      userId,
    },
  });

  return response.data.addFamilyTree.id;
}

function convertFromDto(rawFamilyTree: RawFamilyTree): FamilyTreeNode[] {
  return rawFamilyTree.familyTree
    .map((rawNode) => ({
      id: rawNode.id,
      data: {
        firstName: rawNode.personData.givenName,
        familyName: rawNode.personData.familyName,
        dateOfBirth: rawNode.personData.birthDate,
        avatar: familyTreeConfig.getAvatarFor(getGender(rawNode)),
        gender: getGender(rawNode),
      },
      rels: {
        mother: rawNode.motherId,
        father: rawNode.fatherId,
        children: [...(rawNode.children ?? [])],
        spouses: [...(rawNode.partners ?? [])],
      },
    }))
    .map(deleteNullsFromRels);

  function getGender(rawNode: { personData: { gender: string } }) {
    return rawNode.personData.gender === API_GENDER_MALE ? Gender.Male : Gender.Female;
  }
}

function deleteNullsFromRels(node: FamilyTreeNode) {
  Object.keys(node.rels).forEach((key) => {
    if (!node.rels[key]) {
      delete node.rels[key];
    }
  });
  return node;
}

export async function saveTreeToTheServer(userId: number, treeId: number, tree: FamilyTreeNode[]): Promise<void> {
  const treeDto = convertToDto(tree);

  await ApiClient.current.mutate({
    mutation: updateFamilyTreeMutation,
    variables: {
      userId,
      treeId,
      newTree: treeDto.familyTree,
    },
  });
}

function convertToDto(tree: FamilyTreeNode[]): RawFamilyTree {
  const treeWithoutPlaceHolders = removePlaceholdersFrom(tree);

  return {
    familyTree: treeWithoutPlaceHolders.map((n) => ({
      id: n.id,
      personData: {
        avatar: '', // possibly can add custom avatar somewhen in future
        birthDate: n.data.dateOfBirth,
        familyName: n.data.familyName,
        givenName: n.data.firstName,
        gender: n.data.gender === Gender.Male ? API_GENDER_MALE : API_GENDER_FEMALE,
      },
      children: n.rels.children ?? [],
      partners: n.rels.spouses ?? [],
      fatherId: n.rels.father ?? null,
      motherId: n.rels.mother ?? null,
    })),
  };
}
