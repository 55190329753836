import { useEffect, useMemo, useRef, useState } from 'react';
import './ContextMenu.css';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { windowHeight, windowWidth } from '../../utils/window-dimensions';
import Tooltip from './Tooltip';

const contextMenuMargin = 20; // px
const lifespanOfSubContextMenu = 50000; // ms
export interface Option {
  label: string;
  callback: () => void;
  disabled?: boolean;
  disabledExplanation?: string;
  dangerColor?: boolean;
  subOptions?: Option[];
}

export default function ContextMenu({ position, options, onMouseEnter, onMouseLeave }: ContextMenuParams) {
  const contextMenuContainer = useRef<HTMLDivElement>(null);
  const [realPosition, setRealPosition] = useState(position);
  const [toolTipData, setToolTipData] = useState<TooltipData>();
  const [subContextMenuData, setSubContextMenuData] = useState<{ params: ContextMenuParams; opt: Option }>();
  const timerStore = useMemo<{ timer: NodeJS.Timeout }>(() => ({ timer: null }), []);

  useEffect(() => {
    if (!contextMenuContainer.current) return;
    const height = contextMenuContainer.current.clientHeight;
    const width = contextMenuContainer.current.clientWidth;

    const newPosition = { ...position };

    if (windowHeight() < height + position.top + contextMenuMargin) {
      newPosition.top = position.top - height;
    }

    if (windowWidth() < width + position.left + contextMenuMargin) {
      newPosition.left = position.left - width;
    }

    setRealPosition(newPosition);
  }, [contextMenuContainer.current]);

  function getAdditionalClasses(opt: Option) {
    let classes = '';

    if (opt.disabled) {
      classes += ' disabled-option ';
    }

    if (opt.dangerColor) {
      classes += ' warning-color ';
    }

    return classes;
  }

  function handleMouseEnter(e: { clientX: number; clientY: number }, opt: Option, optIndex: number) {
    if (opt.disabled && opt.disabledExplanation) {
      setToolTipData({
        id: optIndex,
        message: opt.disabledExplanation,
        top: e.clientY,
        left: e.clientX,
      });
    }

    if (!opt.disabled && opt.subOptions) {
      const optionElementHeight = contextMenuContainer.current.clientHeight / options.length;

      setSubContextMenuData({
        params: {
          position: {
            top: position.top + optIndex * optionElementHeight,
            left: position.left + contextMenuContainer.current.clientWidth,
          },
          options: opt.subOptions,
        },
        opt,
      });
    }

    if (subContextMenuData && subContextMenuData.opt === opt && timerStore.timer) {
      clearTimeout(timerStore.timer);
    }
  }

  function hideSubContextMenu() {
    setSubContextMenuData(null);
  }

  function handleMouseLeftSubMenu() {
    timerStore.timer = setTimeout(hideSubContextMenu, lifespanOfSubContextMenu);

    if (onMouseLeave) onMouseLeave();
  }

  function handleMouseEnteredSubMenu() {
    if (timerStore.timer) {
      clearTimeout(timerStore.timer);
      timerStore.timer = null;
    }

    if (onMouseEnter) onMouseEnter();
  }

  function handleMouseLeave(opt: Option) {
    setToolTipData(null);

    if (subContextMenuData && subContextMenuData.opt === opt) {
      timerStore.timer = setTimeout(hideSubContextMenu, lifespanOfSubContextMenu);
    }
  }

  function handleMouseMove(e: { clientX: number; clientY: number }) {
    setToolTipData((prev) => (prev ? { ...prev, top: e.clientY, left: e.clientX } : null));
  }

  function handleClick(opt: Option) {
    if (!opt.disabled && opt.callback) opt.callback();
  }

  return (
    <>
      <div
        ref={contextMenuContainer}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="context-menu-container"
        style={realPosition}
      >
        {options.map((opt, i) => (
          <button
            type="button"
            key={opt.label}
            className={`context-menu-option ${getAdditionalClasses(opt)}`}
            onClick={() => handleClick(opt)}
            onMouseEnter={(e) => handleMouseEnter(e, opt, i)}
            onMouseLeave={() => handleMouseLeave(opt)}
            onMouseMove={(e) => handleMouseMove(e)}
          >
            <div className="option-label-container">
              <div className="option-text-label">{opt.label}</div>
              {opt.subOptions && <FontAwesomeIcon icon={faChevronRight} className="option-arrow-icon" />}
            </div>
          </button>
        ))}
      </div>
      {subContextMenuData && (
        <ContextMenu
          position={subContextMenuData.params.position}
          options={subContextMenuData.params.options}
          onMouseEnter={() => handleMouseEnteredSubMenu()}
          onMouseLeave={() => handleMouseLeftSubMenu()}
        />
      )}
      {toolTipData && (
        <Tooltip key={toolTipData.id} top={toolTipData.top} left={toolTipData.left}>
          {toolTipData.message.split('\n').map((line) => (
            <div key={line}>{line}</div>
          ))}
        </Tooltip>
      )}
    </>
  );
}

ContextMenu.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

interface ContextMenuParams {
  position: { top: number; left: number };
  options: Option[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface TooltipData {
  id: number;
  message: string;
  top: number;
  left: number;
}
