import { gql } from '@apollo/client';

export interface RawEditUserRights {
  userManagementSettings: {
    managers: RawUserForRights[];
    managedUsers: RawUserForRights[];
  };
}

export const editRightsQuery = gql`
  query EditRights($userId: Int!) {
    userManagementSettings(userId: $userId) {
      managers {
        id
        profile {
          givenName
          familyName
          email
        }
      }
      managedUsers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
    }
  }
`;

export interface RawViewUserRights {
  userViewSettings: {
    viewers: RawUserForRights[];
    viewableUsers: RawUserForRights[];
  };
}

export const viewRightsQuery = gql`
  query ViewRights($userId: Int!) {
    userViewSettings(userId: $userId) {
      viewers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
      viewableUsers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
    }
  }
`;

export interface RawUserForRights {
  id: number;
  profile: {
    givenName: string;
    familyName: string;
    email: string;
  };
}
