import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OperationResult } from '../../apiTypes/general';
import LoadingIcon from '../xShared/LoadingIcon';
import { logOut } from '../../utils/api-client';
import useMount from '../../hooks/useMount';
import routes from '../../route-definitions';
import { confirmEmailMutation } from './queries';

function ConfirmEmail() {
  const [userId] = useQueryParam('userId', NumberParam);
  const [token] = useQueryParam('token', StringParam);
  const { t } = useTranslation('confirm');

  const [confirm, { loading, data }] = useMutation<{ confirmEmail: OperationResult }>(confirmEmailMutation, {
    variables: {
      userId,
      token,
    },
  });

  const sendConfirmation = useCallback(confirm, []);

  useMount(() => {
    sendConfirmation().then((response) => {
      if (!response.errors && response.data.confirmEmail.operationSuccessful) {
        logOut();
      }
    });
  });

  if (loading) return <LoadingIcon className="mt-5 min-vw-100" />;

  return (
    <Container>
      {data?.confirmEmail.operationSuccessful && (
        <Col sm={6}>
          <h3>{t('success')}</h3>
          <p>{t('confirmedEmail')}</p>
          <p>{t('goHome')}</p>
          <Link to={routes.rootRoute.path}>
            <Button variant="secondary">{t('home')}</Button>
          </Link>
        </Col>
      )}
    </Container>
  );
}

export default ConfirmEmail;
