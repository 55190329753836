import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { StringParam, NumberParam, useQueryParam } from 'use-query-params';
import { setNewPasswordMutation } from './queries';
import { OperationResult } from '../../apiTypes/general';
import { logOut } from '../../utils/api-client';

function SetNewPassword() {
  const { t } = useTranslation(['profile', 'confirm']);
  const [userId] = useQueryParam('userId', NumberParam);
  const [token] = useQueryParam('token', StringParam);

  const [changed, setChanged] = useState('');
  const [confirm, setConfirm] = useState('');
  const [update, { loading, data }] = useMutation<{ resetPassword: OperationResult }>(setNewPasswordMutation);

  if (data?.resetPassword.operationSuccessful) {
    return (
      <Container>
        <h3>{t('confirm:success')}</h3>
        <p>{t('confirm:setNewPwd')}</p>
        <p>{t('confirm:goHome')}</p>
      </Container>
    );
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    update({
      variables: {
        userId,
        token,
        newPassword: changed,
      },
    }).then((result) => {
      if (result.data?.resetPassword.operationSuccessful) logOut();
    });
  };

  return (
    <Container>
      <Col sm={6}>
        <Form onSubmit={handleSubmit}>
          <h2 className="mb-4">{t('changePassword')}</h2>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              {t('newPwd')}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="password"
                name="newPassword"
                value={changed}
                onChange={(e) => setChanged(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={3}>
              {t('newPwdRepeat')}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="password"
                name="newAgain"
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                isInvalid={confirm !== changed}
              />
              <Form.Control.Feedback type="invalid">{t('matchPasswords')}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Button type="submit" variant="secondary" disabled={loading || confirm !== changed}>
            {loading ? <Spinner /> : t('update')}
          </Button>
        </Form>
      </Col>
    </Container>
  );
}

export default SetNewPassword;
