import { Form, Modal, ModalProps, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { passwordResetQuery } from './queries';
import { Language } from '../../apiTypes/general';

function ForgotPasswordModal({ show, onHide }: ModalProps) {
  const { t, i18n } = useTranslation('skeleton');
  const [email, setEmail] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [sendRequest, { loading, error }] = useLazyQuery(passwordResetQuery);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setRequestSent(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const language = i18n.language === 'cs' ? Language.Czech : Language.English;
    // TODO figure out when this can return false
    sendRequest({ variables: { email, language: String(language) } }).then(() => setRequestSent(true));
  };

  return (
    <Modal onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{t('forgottenPassword')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {requestSent && !error ? (
            <p>{t('passwordResetSent')}</p>
          ) : (
            <TextField
              fullWidth
              value={email}
              className="mb-3 mt-3"
              onChange={handleChange}
              size="small"
              label="Email"
              variant="outlined"
              error={!!error}
              helperText={error ? t('somethingWentWrong') : ''}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" color="success" variant="contained" disabled={loading || requestSent}>
            {loading ? <Spinner /> : t('send')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ForgotPasswordModal;
