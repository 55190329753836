export interface FamilyTreeNode {
  id: NodeId;
  to_add?: boolean;
  main?: boolean;
  rels: Relations;
  data: PersonData;
}

export interface Relations {
  spouses: NodeId[];
  children: NodeId[];
  father?: NodeId;
  mother?: NodeId;
}

export interface PersonData {
  firstName: string;
  familyName: string;
  dateOfBirth: string;
  avatar: string;
  gender: Gender;
}

export interface FamilyTreeEditFormState {
  firstName: string;
  familyName: string;
  dateOfBirth: string;
  gender: Gender;

  mother: NodeId;
  father: NodeId;
  spouses: NodeId[];
  children: NodeId[];
}

type FlagsOf<T> = {
  [K in keyof T]?: boolean;
};

export interface FamilyNodeEditLogic {
  keyToTitle: string;
  disabled: FlagsOf<FamilyTreeEditFormState>;
  defaultValues: Partial<FamilyTreeEditFormState>;
  optionsForSelects: {
    gender: Gender[];
    father: NodeId[];
    mother: NodeId[];
    spouses: NodeId[];
    children: NodeId[];
  };
  validate: {
    realtime: (state: FamilyTreeEditFormState) => boolean;
    beforeSubmit: (state: FamilyTreeEditFormState) => ValidationResult;
  };
  getPersonName: (id: NodeId) => string;
  updateTreeWith: (state: FamilyTreeEditFormState, tree: FamilyTreeNode[]) => FamilyTreeNode[];
}

export interface ValidationResult {
  onValid: (callBack: () => void) => ValidationResult;
  onError: (callBack: (keyToMessage: string) => void) => ValidationResult;
}

export function createError(keyToMessage: string): ValidationResult {
  const errorResult = {
    onValid: () => errorResult,
    onError: (callback) => {
      callback(keyToMessage);
      return errorResult;
    },
  };
  return errorResult;
}

export function createValidResult(): ValidationResult {
  const validResult = {
    onError: () => validResult,
    onValid: (callback) => {
      callback();
      return validResult;
    },
  };
  return validResult;
}

export enum Gender {
  Male = 'M',
  Female = 'F',
}

export type Flagged<T> = { visited?: boolean } & T;

export type NodeId = string;

export const FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT = 'DD.MM.YYYY';
