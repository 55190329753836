import { useQuery } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MainLayout from '../AppSkeleton/MainLayout/MainLayout';
import { userSideMenuItems } from '../AppSkeleton/SideMenu/side-menu-items';
import ApiClient, { ClientType, getUserId, setProfileName } from '../../utils/api-client';
import LoadingIcon from '../xShared/LoadingIcon';
import { Profile, User } from '../../apiTypes/user';
import { userQuery, updateProfileMutation } from './queries';
import MunicipalitySelect from '../xShared/MunicipalitySelect';

async function updateProfile(client: ClientType, profile: Profile) {
  const cleanProfile = {
    ...profile,
    __typename: undefined,
    residenceAddress: {
      ...profile.residenceAddress,
      __typename: undefined,
    },
  };

  const { data } = await client.mutate({
    mutation: updateProfileMutation,
    variables: { id: Number(getUserId()), profile: cleanProfile },
  });
  return data.updateProfile;
}

function UserProfile() {
  const userId = getUserId();
  const { t } = useTranslation(['profile', 'skeleton']);
  const [userProfile, setUserProfile] = useState<Profile>(null);

  useQuery<{ user: User }>(userQuery, {
    variables: { id: userId },
    onCompleted: (d) => setUserProfile({ ...d.user.profile, residenceAddress: { ...d.user.profile.residenceAddress } }),
  });

  const profileChange = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newProfile = { ...userProfile, [key]: value };
    setUserProfile(newProfile);
  };

  const addressChange = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newProfile = { ...userProfile };
    newProfile.residenceAddress[key] = e.target.value;
    setUserProfile(newProfile);
  };

  const municipalityChange = (value: string) => {
    const newProfile = { ...userProfile };
    newProfile.residenceAddress.municipality = value;
    setUserProfile(newProfile);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    updateProfile(ApiClient.current, userProfile).then((profile) => {
      setProfileName(profile);
      ApiClient.ErrorContainer?.addMessage(t('profileUpdateSuccess'), 'success', false);
    });
  };

  return (
    <MainLayout sideMenuItems={userSideMenuItems}>
      <Container className="mt-5 user-form-container">
        {userProfile ? (
          <Form onSubmit={handleUpdate}>
            <h2 className="mb-5">{t('skeleton:profile')}</h2>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('firstName')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="text" value={userProfile.givenName} onChange={profileChange('givenName')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('lastName')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="text" value={userProfile.familyName} onChange={profileChange('familyName')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('birthDate')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="date" value={userProfile.birthDate} onChange={profileChange('birthDate')} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('phoneNum')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="text" value={userProfile.phoneNumber} onChange={profileChange('phoneNumber')} />
              </Col>
            </Form.Group>
            <h4 className="mt-4 mb-3">{t('address')}</h4>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('street')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  value={userProfile.residenceAddress.street}
                  onChange={addressChange('street')}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('town')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="text" value={userProfile.residenceAddress.town} onChange={addressChange('town')} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('zipCode')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  value={userProfile.residenceAddress.zipCode}
                  onChange={addressChange('zipCode')}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
              <Form.Label column sm={2}>
                {t('municipality')}
              </Form.Label>
              <Col sm={10}>
                <MunicipalitySelect onChange={municipalityChange} />
              </Col>
            </Form.Group>
            <Button variant="secondary" type="submit">
              {t('update')}
            </Button>
          </Form>
        ) : (
          <LoadingIcon />
        )}
      </Container>
    </MainLayout>
  );
}

export default UserProfile;
