import { gql } from '@apollo/client';
import { UserType } from '../../apiTypes/user';

export interface RawStaff {
  staff: {
    id: number;
    profile: {
      email: string;
    };
    adminType: UserType;
  }[];
}

export const getStaffQuery = gql`
  query GetStaff {
    staff {
      id
      profile {
        email
      }
      adminType
    }
  }
`;
