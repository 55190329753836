import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationProps } from './types';
import { ButtonRow } from './Buttons';
import { stateUpdater, validateAndSubmit } from './utils';

function RegistrationAncestorInfoForm({ data, setData, onNext, onBack }: RegistrationProps) {
  const [validated, setValidated] = useState(false);
  const { t } = useTranslation('registration');

  return (
    <Form noValidate validated={validated} onSubmit={validateAndSubmit(onNext, setValidated)}>
      <h3>{t('ancestorInfo.title')}</h3>
      <p className="text-secondary">{t('description.ancestor')}</p>
      <Form.Group>
        <Form.Label>{t('userInfo.name')}</Form.Label>
        <Form.Control name="ancestorName" value={data.ancestorName} onChange={stateUpdater('ancestorName', setData)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.surname')}</Form.Label>
        <Form.Control
          name="ancestorSurname"
          value={data.ancestorSurname}
          onChange={stateUpdater('ancestorSurname', setData)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.birthDate')}</Form.Label>
        <Form.Control
          name="ancestorBirthday"
          type="date"
          value={data.ancestorBirthday}
          onChange={stateUpdater('ancestorBirthday', setData)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('ancestorInfo.dateOfDeath')}</Form.Label>
        <Form.Control
          name="ancestorDateOfDeath"
          type="date"
          value={data.ancestorDateOfDeath}
          onChange={stateUpdater('ancestorDateOfDeath', setData)}
        />
      </Form.Group>
      <h4 className="mt-4">{t('ancestorInfo.birthplace')}</h4>
      <Form.Group>
        <Form.Label>{t('userInfo.street')}</Form.Label>
        <Form.Control
          name="ancestorStreet"
          value={data.ancestorStreet}
          onChange={stateUpdater('ancestorStreet', setData)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.town')}</Form.Label>
        <Form.Control name="ancestorTown" value={data.ancestorTown} onChange={stateUpdater('ancestorTown', setData)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.zipCode')}</Form.Label>
        <Form.Control
          name="ancestorZipCode"
          value={data.ancestorZipCode}
          onChange={stateUpdater('ancestorZipCode', setData)}
        />
      </Form.Group>
      <ButtonRow onBackClick={onBack} />
    </Form>
  );
}

export default RegistrationAncestorInfoForm;
