// eslint-disable-next-line import/prefer-default-export
export function cleanUpObject(obj: any) {
  if (typeof obj !== 'object') return obj;
  // eslint-disable-next-line no-underscore-dangle
  obj.__typename = undefined;
  Object.getOwnPropertyNames(obj).forEach((prop) => {
    cleanUpObject(obj[prop]);
  });
  return obj;
}
