import './Errors.css';
import { Component } from 'react';
import { Alert, AlertColor, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  heading?: string;
  severity?: AlertColor;
  message: string;
}

function ErrorMessage({ onClose, heading, message, severity = 'error' }: Props) {
  const { t } = useTranslation('skeleton');

  return (
    <Alert severity={severity} onClose={onClose} className="message-popup">
      {heading && <AlertTitle>{heading}</AlertTitle>}
      {message.trim() || t('unknownErrorOccurred')}
    </Alert>
  );
}

// this is a bit of a hack to allow us to update the component state from outside the React tree
// eslint-disable-next-line react/prefer-stateless-function
class Errors extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  removeMessage(i: number) {
    return () =>
      this.setState((state) => {
        const newErrors = [...state.errors];
        newErrors.splice(i, 1);
        return { ...state, errors: newErrors };
      });
  }

  // the method is used, just not in this file (see api-client.ts)
  // eslint-disable-next-line react/no-unused-class-component-methods
  addMessage(text: string, severity: AlertColor = 'error', translate = true) {
    this.setState((state) => ({
      ...state,
      errors: [...state.errors, { message: text, severity, translate }],
    }));
  }

  render() {
    const { t } = this.props;
    return (
      <div className="message-popup-container">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.state.errors.map(({ message, severity, translate }, i) => (
          <ErrorMessage
            key={message}
            severity={severity}
            onClose={this.removeMessage(i)}
            message={translate ? t(message) : message}
          />
        ))}
      </div>
    );
  }
}

export default Errors;
