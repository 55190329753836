import Select from 'react-select';
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

const municipalitiesQuery = gql`
  query GetMunicipalities {
    administrativeRegions(regionType: MUNICIPALITY) {
      id
      name
    }
  }
`;

interface Props {
  onChange: (newVal: string | null) => void;
}

function MunicipalitySelect({ onChange }: Props) {
  const [municipalities, setMunicipalities] = useState<string[]>([]);
  const { loading } = useQuery(municipalitiesQuery, {
    onCompleted: (d) => {
      setMunicipalities(d.administrativeRegions.map((m) => m.name).sort());
    },
  });
  return (
    <Select
      required
      isClearable
      name="municipalities"
      onChange={(val) => onChange(val?.value)}
      isLoading={loading}
      options={municipalities.map((m) => ({ value: m, label: m }))}
    />
  );
}

export default MunicipalitySelect;
