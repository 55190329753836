export default function makeDataSourceNamesUnique<
  T extends {
    id: number;
    testProvider: {
      name: string;
    };
  }
>(sources: T[]): T[] {
  const nameOccurrences: { [name: string]: number } = {};
  const lastUsedIndices: { [name: string]: number } = {};

  sources.forEach((source) => {
    const sourceName = source.testProvider.name;

    nameOccurrences[sourceName] = (nameOccurrences[sourceName] ?? 0) + 1;
    lastUsedIndices[sourceName] = 0;
  });

  return [...sources]
    .sort((s1, s2) => s1.id - s2.id)
    .map((source) => {
      const sourceName = source.testProvider.name;
      const nameSuffix = nameOccurrences[sourceName] > 1 ? ` (${++lastUsedIndices[sourceName]})` : '';

      return { ...source, testProvider: { ...source.testProvider, name: sourceName + nameSuffix } };
    });
}
