import { Modal } from 'react-bootstrap';
import './GeneticComparisonModal.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { PublicProfile } from '../../apiTypes/user';
import { StrMarker } from '../../apiTypes/geneticData';
import { UserRelativeDistance } from '../../apiTypes/relativesSearch';

// If you change this dont forget to change CSS grid accordingly
const MARKERS_PER_ROW = 8;

interface Props {
  show: boolean;
  handleClose: () => void;
  currentUser: number[];
  comparison: UserRelativeDistance;
  markers: string[];
}

function fullName(profile: PublicProfile) {
  return `${profile.givenName} ${profile.familyName}`;
}

function getMarker(markers: StrMarker[], name) {
  return markers.find((e) => e.name === name);
}

function zipStrMarkers(names: string[], counts: number[]): StrMarker[] {
  return names.map((name, i) => ({ name, count: counts[i] }));
}

function GeneticComparisonModal({ show, handleClose, currentUser, comparison, markers }: Props) {
  const { t } = useTranslation('relatives');

  const currentUserMarkerSet = zipStrMarkers(markers, currentUser);
  const comparisonMarkerSet = zipStrMarkers(markers, comparison.markerSetValues);

  return (
    <Modal className="genetic-comparison" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <h3 className="header mt-2">{t('modalHeader', { name: fullName(comparison.user.profile) })}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="container comparison-container mt-3">
          {markers.map((marker, i) => {
            const markerA = getMarker(currentUserMarkerSet, marker);
            const markerB = getMarker(comparisonMarkerSet, marker);

            return (
              <>
                {i % MARKERS_PER_ROW === 0 && (
                  <MarkerCompareCell
                    title="Marker"
                    firstRow={t('you')}
                    secondRow={fullName(comparison.user.profile)}
                    compare={false}
                  />
                )}
                <MarkerCompareCell title={marker} firstRow={markerA.count} secondRow={markerB.count} compare />
              </>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}

function MarkerCompareCell({
  title,
  firstRow,
  secondRow,
  compare,
}: {
  title: string;
  firstRow: any;
  secondRow: any;
  compare: boolean;
}) {
  const valuesAreSame = firstRow === secondRow;

  let rowClasses = '';
  if (compare) {
    rowClasses = valuesAreSame ? 'values-are-same' : 'values-are-different';
  }

  return (
    <div className={`comparison-cells-container ${!compare ? 'no-compare-cells' : ''}`}>
      <div className={`comparison-cell-title ${rowClasses}`}>
        <div>{title}</div>
        {compare && !valuesAreSame && (
          <FontAwesomeIcon className="comparison-cell-title-are-different-icon" icon={faTriangleExclamation} />
        )}
        {compare && valuesAreSame && (
          <FontAwesomeIcon className="comparison-cell-title-are-same-icon" icon={faCircleCheck} />
        )}
      </div>
      <div className={`comparison-cell-row ${rowClasses}`}>{firstRow}</div>
      <div className={`comparison-cell-row ${rowClasses}`}>
        {secondRow ?? <span className="no-marker-value">x</span>}
      </div>
    </div>
  );
}

export default GeneticComparisonModal;
