import { MarkerInputType } from '../Registration/types';

export interface Marker {
  name: string;
  values: {
    value: number;
    originName: string;
  }[];
}

export type MarkerSet = Marker[];

export interface ViewableUserData {
  id: number;
  profile: {
    familyName: string;
    givenName: string;
  };
  isEditable: boolean;
  /**
   * Aggregated set of markers.
   * This can differ from manual aggregation of all origins
   * as Admin (or other person responsible) can make
   * manual changes to a marker value in case that there is an collision of marker value.
   */
  aggregatedMarkers?: MarkerSet[];
}

export interface ManagedGeneticData {
  viewableUsers: ViewableUserData[];
}

export interface Origin {
  name: string;

  selected: boolean;
  checkboxDisabled?: boolean;

  markers: MarkerSet;
}

export interface AggregatedMarkersOrigin extends Origin {
  conflictingMarkers: string[];
}

export function isAggregatedMarkersOrigin(origin: Origin | AggregatedMarkersOrigin): origin is AggregatedMarkersOrigin {
  return !!(origin as any).conflictingMarkers;
}

export type OriginsSet = (Origin | AggregatedMarkersOrigin)[];

export interface GeneticDataTab {
  userId: number;
  key: number;
  label: string;
  data: ViewableUserData;
}

export interface ManualMarkersData {
  dataSource: string;
  file?: File;
  markers: MarkerInputType[];
}
