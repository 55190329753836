import { faMagnifyingGlass, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../../route-definitions';
import { getUserId, maskUser } from '../../utils/api-client';
import { isEmail } from '../../utils/js-helpers';
import { UserReference, UsersRights } from './models';
import fetchRights, { addEditRight, addViewRight, removeEditRight, removeViewRight } from './rights-provider';
import './RightsManagement.css';
import './RightsManagement.phone.css';

export default function RightsManagement() {
  const { t } = useTranslation('manageRights');
  const [users, setUsers] = useState<UsersRights>({});

  const [canViewTableLoading, setCanViewTableLoading] = useState(false);
  const [canEditTableLoading, setCanEditTableLoading] = useState(false);

  const [canViewAddingError, setCanViewAddingError] = useState(false);
  const [canEditAddingError, setCanEditAddingError] = useState(false);

  const loggedUserId = getUserId();

  const navigate = useNavigate();

  useEffect(() => {
    refreshDataInTables();
  }, []);

  function handleUserRemove(
    removingFunction: (userId: number, toBeRemovedId: number) => Promise<UsersRights>,
    setLoadingFunction: (boolean) => void
  ) {
    return (toBeRemovedId: number) => {
      setLoadingFunction(true);
      removingFunction(loggedUserId, toBeRemovedId)
        .then((patch) => {
          setUsers({ ...users, ...patch });
        })
        .finally(() => {
          setLoadingFunction(false);
        });
    };
  }

  function handleUserAdd(
    addingFunction: (userId: number, userEmailToBeAdded: string) => Promise<UsersRights>,
    setLoading: (boolean) => void,
    setError: (boolean) => void
  ) {
    return (userEmailToBeAdded: string) => {
      setLoading(true);
      addingFunction(loggedUserId, userEmailToBeAdded.trim())
        .then((patch) => {
          setUsers({ ...users, ...patch });
          setError(false);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  }

  function handleMaskUser(userId: number) {
    const user = [...users.canView, ...users.canEdit]
      .filter((u) => u.id === userId)
      .map((u) => ({
        id: u.id,
        profile: {
          givenName: u.name.split(' ')[0],
          familyName: u.name.split(' ')[1],
        },
      }))[0];

    maskUser(user, routes.rightsManagement.path).then(() => {
      navigate(routes.userFormRoute.path);
    });
  }

  return (
    <div className="rights-page-container container">
      <h1 className="header mt-5 mb-5">{t('manageRightsTitle')}</h1>

      <div className="manage-rights-feature-container mt-4 mb-2">
        <div className="rights-section-container">
          <h3 className="header">{t('viewRights')}</h3>
          <UserTable
            users={users.withViewRight}
            loading={canViewTableLoading}
            onRemove={handleUserRemove(removeViewRight, setCanViewTableLoading)}
          />
          {users.withViewRight && (
            <AddNewUserForm
              onAddUser={handleUserAdd(addViewRight, setCanViewTableLoading, setCanViewAddingError)}
              error={canViewAddingError}
              loading={canViewTableLoading}
            />
          )}
        </div>

        <div className="rights-section-container">
          <h3 className="header">{t('editRights')}</h3>
          <UserTable
            users={users.withEditRight}
            loading={canEditTableLoading}
            onRemove={handleUserRemove(removeEditRight, setCanEditTableLoading)}
          />
          {users.withEditRight && (
            <AddNewUserForm
              onAddUser={handleUserAdd(addEditRight, setCanEditTableLoading, setCanEditAddingError)}
              error={canEditAddingError}
              loading={canEditTableLoading}
            />
          )}
        </div>

        <div className="rights-section-container">
          <h3 className="header">{t('canView')}</h3>
          <UserTable users={users.canView} onMaskUser={(id) => handleMaskUser(id)} />
        </div>

        <div className="rights-section-container">
          <h3 className="header">{t('canEdit')}</h3>
          <UserTable users={users.canEdit} onMaskUser={(id) => handleMaskUser(id)} />
        </div>
      </div>
    </div>
  );

  function refreshDataInTables() {
    fetchRights(getUserId()).then(setUsers);
  }
}

function UserTable({
  users,
  onRemove,
  loading,
  onMaskUser,
}: {
  users: UserReference[];
  loading?: boolean;
  onRemove?: (userId: number) => void;
  onMaskUser?: (userId: number) => void;
}) {
  const { t } = useTranslation('manageRights');

  if (!users) {
    return <Spinner />;
  }

  if (users.length === 0) {
    return (
      <Alert severity="warning" className="mt-4">
        {t('noUsersToDisplay')}
      </Alert>
    );
  }

  return (
    <TableContainer component={Paper} className={`mt-4 ${loading ? 'user-right-table-is-loading' : ''}`}>
      <Table aria-label="simple table" className="manage-user-rights-table">
        <TableHead>
          <TableRow>
            <TableCell>{t('userName')}</TableCell>
            <TableCell align="right">{t('userEmail')}</TableCell>
            {onRemove && <TableCell align="right" />}
            {onMaskUser && <TableCell align="right" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.name}
              </TableCell>
              <TableCell align="right">{user.email}</TableCell>
              {onRemove && (
                <TableCell align="right">
                  <Button color="error" variant="contained" onClick={() => onRemove(user.id)}>
                    <FontAwesomeIcon icon={faTrashCan} className="me-2 user-rights-trash-icon" />
                    <div className="user-rights-remove-btn">{t('removeUser')}</div>
                  </Button>
                </TableCell>
              )}
              {onMaskUser && (
                <TableCell align="right">
                  <Button
                    color="warning"
                    variant="contained"
                    className="rights-management-explore-user-btn"
                    onClick={() => onMaskUser(user.id)}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="" />
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AddNewUserForm({
  onAddUser,
  error,
  loading,
}: {
  onAddUser: (email: string) => void;
  error?: boolean;
  loading?: boolean;
}) {
  const { t } = useTranslation('manageRights');
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="manage-rights-add-user-container mt-3">
      <TextField
        fullWidth
        value={inputValue}
        className="manage-user-email-input"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        size="small"
        label={t('addUserEmailLabel')}
        variant="outlined"
        error={error}
        helperText={error ? t('inputError') : ''}
      />
      <Button
        disabled={!isEmail(inputValue) || loading}
        variant="contained"
        className="manage-rights-add-user-btn ms-2"
        color="success"
        onClick={() => onAddUser(inputValue)}
      >
        {t('addUser')}
      </Button>
    </div>
  );
}
