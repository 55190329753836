/* eslint-disable jsx-a11y/no-static-element-interactions */
import './FamilyTree.css';
import f3 from 'family-chart';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { FamilyNodeEditLogic, FamilyTreeNode, NodeId } from './models';
import ContextMenu, { Option } from '../xShared/ContextMenu';
import {
  hasFather,
  hasMother,
  breaksTreeIfRemoved,
  remove,
  isPlaceHolder,
  getMainNode,
  find,
  getFullName,
  getInitialTree,
} from './tree-manipulations';
import EditFamilyNodeModal from './EditFamilyNodeModal';
import editingLogicsProviders from './edit-form-logic';
import {
  addEventBeforeAnyOtherEvent,
  deepCopyOfSimpleObject,
  placeEventAtTheEndOfEventQueue,
} from '../../utils/js-helpers';
import { fetchFamilyTreeFromServer, saveTreeToTheServer } from './family-tree-provider';
import ErrorAlert from '../xShared/ErrorAlert';
import familyTreeConfig from './config';
import { getUserId } from '../../utils/api-client';

interface ContextMenuData {
  position: {
    top: number;
    left: number;
  };
  node: FamilyTreeNode;
}

const CARD_CONTAINER_CLASS = 'card_cont';
const UNKNOWN_CARD_CONTAINER_CLASS = 'unknown';

const ALL_PERSON_CARD_QUERY = `.${CARD_CONTAINER_CLASS}`;
const KNOWN_PERSON_CARD_QUERY = `.${CARD_CONTAINER_CLASS}:not(.${UNKNOWN_CARD_CONTAINER_CLASS})`;

export default function FamilyTree() {
  const { t } = useTranslation('familyTree');
  const familyTreeContainer = useRef<HTMLDivElement>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [f3Store, setF3Store] = useState<any>(null);
  const [tree, setTree] = useState<FamilyTreeNode[]>(null);
  const [firstFetchHasBeenPerformed, setFirstFetchHasBeenPerformed] = useState(false);
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>(null);
  const [editFormOpened, setEditFormOpened] = useState(false);
  const [editFormLogic, setEditFormLogic] = useState<FamilyNodeEditLogic>(null);
  const lastValidTreeStore = useMemo<{ validTree: FamilyTreeNode[] }>(() => ({ validTree: null }), []);
  const [selectedNode, setSelectedNode] = useState<FamilyTreeNode>();
  const persistentStore = useMemo<any>(() => ({}), []);
  const [loadingMessageKey, setLoadingMessageKey] = useState<string>();
  const [currentTreeId, setCurrentTreeId] = useState<number>();

  useEffect(() => {
    setLoadingMessageKey('fetchingTreeFromServer');

    fetchFamilyTreeFromServer(getUserId()).then(({ treeId, treeFromServer }) => {
      const newTree = treeFromServer.length !== 0 ? treeFromServer : getInitialTree();

      setTree(newTree);
      setCurrentTreeId(treeId);

      setFirstFetchHasBeenPerformed(true);
      setLoadingMessageKey(null);
      persistentStore.treeJustFetched = true;
    });
  }, []);

  useEffect(() => {
    initializeSvgTree();
  }, [familyTreeContainer, firstFetchHasBeenPerformed]);

  useEffect(() => {
    if (!tree) return;

    updateSvgTree();
    setSelectedNode(getMainNode(tree));

    if (!persistentStore.treeJustFetched) {
      setLoadingMessageKey('savingTreeToTheServer');
      saveTreeToTheServer(getUserId(), currentTreeId, tree).then(() => {
        setLoadingMessageKey(null);
      });
    }

    persistentStore.treeJustFetched = false;
  }, [tree, f3Store]);

  function handleAddParent(node: FamilyTreeNode) {
    handleTreeEditWith(editingLogicsProviders.getAddParentLogic, node);
  }

  function handleAddChild(node: FamilyTreeNode) {
    handleTreeEditWith(editingLogicsProviders.getAddChildLogic, node);
  }

  function handleAddSpouse(node: FamilyTreeNode) {
    handleTreeEditWith(editingLogicsProviders.getAddSpouseLogic, node);
  }

  function handleEditNode(node: FamilyTreeNode) {
    handleTreeEditWith(editingLogicsProviders.getEditLogic, node);
  }

  function handleRemoveNode(node: FamilyTreeNode) {
    const treeWithoutNode = remove(node, tree);

    if (node.main) {
      focus(treeWithoutNode[0].id);
    }

    setTree(treeWithoutNode);
    hideContextMenu();
  }

  function handleUserSavedEditedNode(newTree: FamilyTreeNode[]) {
    hideEditForm();
    setTree([...newTree]);
  }

  function handleMainNodeChanged(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const newNodeId = event.target.value as NodeId;
    const newNode = find(newNodeId, tree);

    setSelectedNode(newNode);
    focus(newNode);
  }

  return (
    <>
      <div className="family-tree-search-box-container">
        {selectedNode && (
          <TextField
            select
            value={selectedNode.id}
            label={t('mainNode')}
            onChange={(e) => handleMainNodeChanged(e)}
            size="small"
            className="family-tree-search-box"
            fullWidth
          >
            {tree
              ?.filter((n) => !isPlaceHolder(n))
              .map((n) => ({ id: n.id, name: getFullName(n, tree) }))
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((n) => (
                <MenuItem key={n.id} value={n.id}>
                  {n.name}
                </MenuItem>
              ))}
          </TextField>
        )}
        {loadingMessageKey && (
          <div className="family-tree-spinner-container">
            <Spinner />
            <div className="family-tree-spinner-message">{t(loadingMessageKey)}</div>
          </div>
        )}
      </div>

      <div className="f3" ref={familyTreeContainer} />

      {contextMenuData && (
        <ContextMenu
          key={contextMenuData.node.id}
          position={contextMenuData.position}
          options={getContextMenuOptions(contextMenuData.node)}
        />
      )}

      <EditFamilyNodeModal
        show={editFormOpened}
        tree={tree ? [...tree] : []}
        onCancel={() => hideEditForm()}
        onSave={(newTree) => handleUserSavedEditedNode(newTree)}
        formLogic={editFormLogic}
      />

      <ErrorAlert messages={errorMessages} />
    </>
  );

  function handleTreeEditWith(
    logicProvider: (node: NodeId, tree: FamilyTreeNode[]) => FamilyNodeEditLogic,
    node: FamilyTreeNode
  ) {
    const treeCopy = deepCopyOfSimpleObject(tree);

    setEditFormLogic(logicProvider(node.id, treeCopy));

    hideContextMenu();
    showEditForm();
  }

  function focus(node: NodeId | FamilyTreeNode) {
    const nodeId = typeof node === 'string' ? node : node.id;

    f3Store.update.mainId(nodeId);
    f3Store.update.tree();
  }

  function hideContextMenu() {
    setContextMenuData(null);
  }

  function hideEditForm() {
    setEditFormOpened(false);
  }

  function showEditForm() {
    setEditFormOpened(true);
  }

  function initializeSvgTree() {
    if (!familyTreeContainer?.current || !firstFetchHasBeenPerformed) return;

    const store = f3.createStore({
      data: tree,
      ...familyTreeConfig.f3StoreProperties,
    });

    setF3Store(store);

    const view = f3.d3AnimationView({
      store,
      cont: familyTreeContainer.current,
    });

    const card = f3.elements.Card({
      store,
      svg: view.svg,
      ...familyTreeConfig.f3CardProperties,
    });

    view.setCard(card);
    store.setOnUpdate((props) => view.update(props || {}));
    store.update.tree({ initial: true });

    // `family-chart` library stops propagation of these events => so I have to execute my handlers before theirs
    addEventBeforeAnyOtherEvent(familyTreeContainer.current, 'wheel', hideContextMenu);
    addEventBeforeAnyOtherEvent(familyTreeContainer.current, 'mousedown', hideContextMenu);
  }

  function refreshNodesElements() {
    d3.selectAll(ALL_PERSON_CARD_QUERY)
      // `this` is bind only if "function" lambda is used
      // eslint-disable-next-line func-names
      .each(function (d: { data: FamilyTreeNode }) {
        const cardElement = this as SVGGElement;

        resolveClassesForElement(d.data, cardElement);

        persistentStore.tree = tree;
        persistentStore.clickOnCardCallBack =
          persistentStore.clickOnCardCallBack ??
          (() => {
            placeEventAtTheEndOfEventQueue(() => {
              refreshNodesElements();
              const mainNode = getMainNode(persistentStore.tree);
              setSelectedNode(mainNode);
            });
          });

        cardElement.removeEventListener('click', persistentStore.clickOnCardCallBack);

        // WARNING magic ahead
        // `family-chart` library stops event propagation (which is dumb but it is what it is)
        // so I have to ensure that my event runs before `click` event of the library.
        // But I also want to run my `refreshNodesElements` event after library creates
        // new visible nodes and that's why I call `placeEventAtTheEndOfEventQueue`
        addEventBeforeAnyOtherEvent(cardElement, 'click', persistentStore.clickOnCardCallBack);
      });

    d3.selectAll(KNOWN_PERSON_CARD_QUERY).on('contextmenu', (e: PointerEvent, d: { data: FamilyTreeNode }) => {
      e.preventDefault();

      setContextMenuData({
        position: { top: e.clientY, left: e.clientX },
        node: d.data,
      });
    });

    function resolveClassesForElement(node: FamilyTreeNode, nodeElement: SVGGElement) {
      nodeElement.classList.remove(UNKNOWN_CARD_CONTAINER_CLASS);

      if (isPlaceHolder(node)) {
        nodeElement.classList.add(UNKNOWN_CARD_CONTAINER_CLASS);
      }
    }
  }

  function updateSvgTree() {
    if (!tree || !f3Store) return;

    if (errorMessages?.length !== 0) {
      setErrorMessages([]);
    }

    try {
      f3Store.update.data(tree);
      f3Store.update.tree();
    } catch {
      // some invalid operations have been made to the tree
      const successful = restoreLastValidTree();

      if (successful) {
        setErrorMessages((prev) => [t('faultyTree->restoringPreviousTree'), ...prev]);
      }
    }

    lastValidTreeStore.validTree = deepCopyOfSimpleObject(tree);

    refreshNodesElements();
  }

  function restoreLastValidTree() {
    if (!lastValidTreeStore.validTree) {
      // this can happen only if tree on server is invalid (that should not happen 😅)
      setErrorMessages((prev) => [t('treeOnServerIsInvalid'), ...prev]);
      return false;
    }

    setTree(lastValidTreeStore.validTree);
    return true;
  }

  function getContextMenuOptions(node: FamilyTreeNode) {
    const options = [
      { label: t('editNode'), callback: () => handleEditNode(node) },
      {
        label: t('addNewNode'),
        subOptions: [
          {
            label: t('addParent'),
            callback: () => handleAddParent(node),
            disabled: hasFather(node, tree) && hasMother(node, tree),
            disabledExplanation: t('nodeHasBothParents'),
          },
          { label: t('addChild'), callback: () => handleAddChild(node) },
          { label: t('addSpouse'), callback: () => handleAddSpouse(node) },
        ],
      },
      {
        label: t('remove'),
        callback: () => handleRemoveNode(node),
        dangerColor: true,
        disabled: breaksTreeIfRemoved(node, tree) || tree.length === 1,
        disabledExplanation: tree.length === 1 ? t('cannotRemoveLastNode') : t('cannotRemoveInnerNode'),
      },
    ] as Option[];

    return options;
  }
}
