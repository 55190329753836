import { gql } from '@apollo/client';

const uploadGeneticDataSourceMutation = gql`
  mutation UploadGeneticDataFile($userId: Int!, $file: Upload!) {
    addStrMarkersFile(userId: $userId, file: $file) {
      __typename
    }
  }
`;

const uploadGeneticDataManuallyMutation = gql`
  mutation UploadGeneticDataManually(
    $userId: Int!
    $strMarkers: [StrMarkerInput!]!
    $geneticTestProvider: GeneticTestProviderInput!
    $file: Upload!
  ) {
    addStrMarkers(userId: $userId, file: $file, geneticTestProvider: $geneticTestProvider, strMarkers: $strMarkers) {
      __typename
    }
  }
`;

export { uploadGeneticDataManuallyMutation, uploadGeneticDataSourceMutation };
