import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import MainLayout from '../AppSkeleton/MainLayout/MainLayout';
import { userSideMenuItems } from '../AppSkeleton/SideMenu/side-menu-items';
import { requestEmailUpdateQuery, updatePasswordMutation, userQuery } from './queries';
import ApiClient, { getUserId } from '../../utils/api-client';
import { getCurrentLanguage, OperationResult } from '../../apiTypes/general';
import { User } from '../../apiTypes/user';

function ChangeEmail() {
  const { t, i18n } = useTranslation('profile');
  const [current, setCurrent] = useState('');
  const [changed, setChanged] = useState('');
  useQuery<{ user: User }>(userQuery, {
    variables: { id: getUserId() },
    onCompleted: (data) => setCurrent(data.user.profile.email),
  });
  const [update, { loading }] = useLazyQuery<{ requestEmailChange: OperationResult }>(requestEmailUpdateQuery);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    update({ variables: { userId: getUserId(), newEmail: changed, language: getCurrentLanguage(i18n) } }).then(
      (result) => {
        if (result.data?.requestEmailChange.operationSuccessful) {
          setCurrent(changed);
          setChanged('');
          ApiClient.ErrorContainer?.addMessage(t('emailChangeSuccess'), 'success', false);
        }
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2>{t('changeEmail')}</h2>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={3}>
          {t('currentEmail')}
        </Form.Label>
        <Col sm={9}>
          <Form.Control plaintext readOnly value={current} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={3}>
          {t('newEmail')}
        </Form.Label>
        <Col sm={9}>
          <Form.Control value={changed} onChange={(e) => setChanged(e.target.value)} />
        </Col>
      </Form.Group>
      <Button type="submit" variant="secondary">
        {loading ? <Spinner /> : t('update')}
      </Button>
    </Form>
  );
}

function ChangePassword() {
  const { t } = useTranslation(['profile', 'skeleton']);
  const [current, setCurrent] = useState('');
  const [changed, setChanged] = useState('');
  const [confirm, setConfirm] = useState('');
  const [update, { loading }] = useMutation(updatePasswordMutation);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    update({ variables: { userId: getUserId(), currentPassword: current, newPassword: changed } }).then((result) => {
      if (!result.errors) {
        setCurrent('');
        setChanged('');
        setConfirm('');
        ApiClient.ErrorContainer?.addMessage(t('pwdChangeSuccess'), 'success', false);
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <h2 className="mb-4">{t('changePassword')}</h2>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={3}>
          {t('currentPwd')}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="password"
            name="oldPassword"
            value={current}
            onChange={(e) => setCurrent(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={3}>
          {t('newPwd')}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="password"
            name="newPassword"
            value={changed}
            onChange={(e) => setChanged(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2">
        <Form.Label column sm={3}>
          {t('newPwdRepeat')}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="password"
            name="newAgain"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            isInvalid={confirm !== changed}
          />
          <Form.Control.Feedback type="invalid">{t('matchPasswords')}</Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Button type="submit" variant="secondary" disabled={confirm !== changed}>
        {loading ? <Spinner /> : t('update')}
      </Button>
    </Form>
  );
}

function LoginInformation() {
  return (
    <MainLayout sideMenuItems={userSideMenuItems}>
      <Container className="mt-5 user-form-container">
        <div className="mb-5">
          <ChangeEmail />
        </div>
        <div className="mt-5">
          <ChangePassword />
        </div>
      </Container>
    </MainLayout>
  );
}

export default LoginInformation;
