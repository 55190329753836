import { Button, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { fetchKitLabels, fetchSequencerInput, getCheckedKitIds, useSendToNextStage } from './utils';
import { Kit, PrepareSequencerQueryResult, KitStage } from '../../../apiTypes/kits';
import { prepareSequencerQuery } from './queries';

const TABS: Record<KitStage, (props: ComponentProps) => JSX.Element> = {
  PENDING: RegisteredKitsTab,
  SENT: SentKitsTab,
  RECEIVED: ReceivedKitsTab,
  ANALYSED: () => <div />,
};

interface KitsTabProps {
  tab: KitStage;
  kit?: Kit;
}

// Row of buttons with actions specific to each kit stage
function KitsTab({ tab, kit }: KitsTabProps) {
  const { t } = useTranslation('kits');
  // kit is present when the tab is loaded in the modal and not when in the page
  const realT = (key) => t(`${key}.${kit ? 'modal' : 'page'}`);
  const kitIdsFn = kit ? () => [kit.id] : getCheckedKitIds;
  const Tab = TABS[tab];
  return (
    <ButtonToolbar className="my-2">
      <Tab translate={realT} kitIdsFn={kitIdsFn} />
    </ButtonToolbar>
  );
}

KitsTab.defaultProps = {
  kit: null,
};

interface ComponentProps {
  translate: (key: string) => string;
  kitIdsFn: () => number[];
}

function RegisteredKitsTab({ translate, kitIdsFn }: ComponentProps) {
  const [send] = useSendToNextStage();
  return (
    <>
      <Button className="me-2" onClick={() => fetchKitLabels(kitIdsFn())}>
        {translate('printLabels')}
      </Button>
      <Button onClick={() => send(kitIdsFn())}>{translate('sendKits')}</Button>
    </>
  );
}

function SentKitsTab({ translate, kitIdsFn }: ComponentProps) {
  const [send] = useSendToNextStage();
  return <Button onClick={() => send(kitIdsFn())}>{translate('receiveKits')}</Button>;
}

function ReceivedKitsTab({ translate, kitIdsFn }: ComponentProps) {
  const [getConfig] = useLazyQuery<PrepareSequencerQueryResult>(prepareSequencerQuery);
  const handleClick = () => {
    getConfig({ variables: { kitIds: kitIdsFn() } }).then((res) => fetchSequencerInput(res.data.prepareSequencerInput));
  };
  return <Button onClick={handleClick}>{translate('generateSequencerInput')}</Button>;
}

export default KitsTab;
