import chroma from 'chroma-js';
import './PhylogeneticTree.css';
import * as d3 from 'd3';
import { childrenAccessor, contractNode, expandNode, isExpandable, Node } from './models/graph';
import graphSettings from './graph-settings';
import { Point } from '../../utils/geometry';
import { TooltipCollSetters } from './models/tooltip-data';
import { ContextMenuSetters } from './models/context-menu-data';
import { Transform } from './models/transform';

export type PointNode = d3.HierarchyPointNode<Node>;
export type PointLink = d3.HierarchyPointLink<Node>;
export type NodesSelection = d3.Selection<d3.BaseType, d3.HierarchyPointNode<Node>, SVGGElement, unknown>;

const NODE_SIZE: [number, number] = [graphSettings.nodeDisplacement, graphSettings.nodeDisplacement];
const LEFT_MOUSE_BUTTON = 1;

export function getTreeLayout() {
  const separationSettings = graphSettings.styles.graph.separation;
  return d3
    .tree<Node>()
    .nodeSize(NODE_SIZE)
    .separation((a, b) => (a.parent === b.parent ? separationSettings.siblings : separationSettings.others));
}

export function diagonal(d: { source: Point; target: Point }) {
  return `M${d.source.x},${d.source.y}C${d.source.x},${(d.source.y + d.target.y) / 2} ${d.target.x},${
    (d.source.y + d.target.y) / 2
  } ${d.target.x},${d.target.y}`;
}

export function setNodeEnterExitState(
  nodes: d3.Selection<SVGGElement, PointNode, SVGGElement, unknown> | d3.Transition<any, unknown, SVGGElement, unknown>,
  position?: Point
) {
  nodes.attr('transform', (node: PointNode) => `translate(${position.x ?? node.x},${position.y ?? node.y})`);
  nodes.attr('fill-opacity', 0);
  nodes.attr('stroke-opacity', 0);
}

export default function getGetTextColorForBackground(backgroundColor: string) {
  if (chroma(backgroundColor).luminance() > graphSettings.styles.labels.luminanceThreshold) {
    return graphSettings.styles.labels.darkColor;
  }

  return graphSettings.styles.labels.lightColor;
}

export function getTreeOffset(
  svgD3: d3.Selection<d3.BaseType, unknown, HTMLElement, any>,
  nodes: d3.HierarchyPointNode<Node>[]
): Transform {
  const width = Number.parseInt(svgD3.style('width'), 10);
  const height = Number.parseInt(svgD3.style('height'), 10);

  const mainNode = nodes.find((n) => n.data.isMain);
  const centerNode = mainNode ?? nodes.find((n) => !n.parent);

  return {
    scale: graphSettings.initialScale,
    x: -centerNode.x + width / 2,
    y: -centerNode.y + height / 2,
  };
}

export function getNodeScale(node: Node) {
  if (isExpandable(node)) return graphSettings.styles.nodes.expandable.scale;
  if (node.count.inside !== 0) return graphSettings.styles.nodes.withPeopleInside.scale;
  return graphSettings.styles.nodes.default.scale;
}

export function setNodeFinalState(nodes: d3.Transition<SVGGElement, PointNode, SVGGElement, unknown>) {
  nodes
    .attr('transform', (node) => `translate(${node.x},${node.y}), scale(${getNodeScale(node.data)})`)
    .attr('fill-opacity', 1)
    .attr('stroke-opacity', 1);
}

export function getUpdatedTreeStructureFor(rawRoot: Node) {
  return d3.hierarchy(rawRoot, childrenAccessor);
}

function highlightRootNode(nodes: NodesSelection) {
  const rootStyles = graphSettings.styles.nodes.root;
  const animationSettings = rootStyles.radiusAnimation;

  const root = nodes.filter((n) => !n.data.parent);
  if (root.empty()) return;

  root
    .select('circle')
    .transition()
    .attr('stroke', rootStyles.stroke.color)
    .attr('stroke-width', rootStyles.stroke.width);

  function animate() {
    root
      .select('circle')
      .transition()
      .duration(animationSettings.duration)
      .attr('stroke', rootStyles.stroke.color)
      .attr('stroke-width', rootStyles.stroke.width)
      .attr('r', animationSettings.minR)
      .transition()
      .duration(animationSettings.duration)
      .attr('r', animationSettings.maxR)
      .on('end', animate);
  }
  animate();
}

export function styleNodes(nodes: NodesSelection) {
  // default styling
  const defaultStyles = graphSettings.styles.nodes.default;

  nodes
    .select('circle')
    .transition()
    .duration(defaultStyles.transition.duration)
    .attr('r', defaultStyles.r)
    .attr('stroke', defaultStyles.stroke.color)
    .attr('stroke-width', defaultStyles.stroke.width)
    .attr('fill', (n) => n.data.color);

  nodes.attr('scale', defaultStyles.scale);

  // root
  highlightRootNode(nodes);

  // expandable nodes
  const expandableNodeStyles = graphSettings.styles.nodes.expandable;

  const expandableNodes = nodes.filter((n) => isExpandable(n.data));
  expandableNodes
    .select('circle')
    .transition()
    .attr('stroke', expandableNodeStyles.stroke.color)
    .attr('stroke-width', expandableNodeStyles.stroke.width)
    .attr('r', expandableNodeStyles.r)
    .duration(expandableNodeStyles.transition.duration);
}

function highlightMainNode(nodes: d3.Selection<SVGGElement, d3.HierarchyPointNode<Node>, SVGGElement, unknown>) {
  const pulsingCircleStyles = graphSettings.styles.nodes.main.pulsingCircle;
  const main = nodes.filter((n) => n.data.isMain);

  if (main.empty()) return;

  const mainCircle = main
    .append('circle')
    .attr('r', pulsingCircleStyles.r)
    .attr('fill', pulsingCircleStyles.fill)
    .attr('stroke', pulsingCircleStyles.stroke.color)
    .attr('stroke-width', pulsingCircleStyles.stroke.width);

  function animateMain() {
    mainCircle
      .transition()
      .duration(pulsingCircleStyles.animationPeriod)
      .attr('r', pulsingCircleStyles.r)
      .transition()
      .duration(pulsingCircleStyles.animationPeriod)
      .attr('r', pulsingCircleStyles.rAfterExpansion)
      .on('end', animateMain);
  }
  animateMain();
}

function highlightNonEmptyNodes(nodes: d3.Selection<SVGGElement, d3.HierarchyPointNode<Node>, SVGGElement, unknown>) {
  const borderCircleStyles = graphSettings.styles.nodes.withPeopleInside.borderCircle;
  nodes
    .filter((n) => n.data.count.inside !== 0)
    .append('circle')
    .attr('r', borderCircleStyles.r)
    .attr('fill', borderCircleStyles.fill)
    .attr('stroke', borderCircleStyles.stroke.color)
    .attr('stroke-width', borderCircleStyles.stroke.with);
}

export function updateNodes(
  eventPosition: Point,
  nodes: NodesSelection,
  updateCallBack: (source: Point) => void,
  tooltips: TooltipCollSetters,
  contextMenus: ContextMenuSetters
) {
  const enteredNodes = nodes.enter().append('g');

  enteredNodes
    .on('mousedown', (event, d) => {
      // 'mousedown' event is better as click is not evoked if you accidentally drag a mouse
      //    => this results in poor user experience because the node is not expanded or collapsed
      if (event.which !== LEFT_MOUSE_BUTTON) return;

      const node = d.data;

      if (isExpandable(node)) {
        expandNode(node);
      } else {
        contractNode(node);
      }

      updateCallBack({ x: d.x, y: d.y });
    })
    .on('contextmenu', (event, n) => {
      event.preventDefault();

      contextMenus.setContextMenuData({
        position: {
          top: event.clientY,
          left: event.clientX,
        },
        node: n,
      });

      contextMenus.setContextMenuVisible(true);
    })
    .on('mouseenter', (event, n) => {
      if (n.data.usersSample.length === 0) return;

      const moreUsersCount = n.data.count.inside - n.data.usersSample.length;

      tooltips.setTooltipData({
        top: event.clientY,
        left: event.clientX,
        content: n.data.usersSample,
        moreUsersCount: moreUsersCount > 0 ? moreUsersCount : null,
      });

      tooltips.setTooltipVisible(true);
    })
    .on('mouseleave', () => {
      tooltips.setTooltipVisible(false);
    })
    .call(setNodeEnterExitState, eventPosition);

  enteredNodes.append('circle');
  enteredNodes
    .append('text')
    .attr('fill', (n) => getGetTextColorForBackground(n.data.color))
    .attr('y', graphSettings.styles.labels.yOffsets.haplogroupName)
    .text((node) => node.data.name)
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle');

  enteredNodes
    .append('text')
    .attr('fill', (n) => getGetTextColorForBackground(n.data.color))
    .attr('fill', (n) => getGetTextColorForBackground(n.data.color))
    .attr('y', graphSettings.styles.labels.yOffsets.estimateYear)
    .text((node) => node.data.approximateYear.toLocaleString('en'))
    .attr('font-size', graphSettings.styles.labels.estimateYearFontSize)
    .attr('pointer-events', 'none')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle');

  highlightMainNode(enteredNodes);
  highlightNonEmptyNodes(enteredNodes);

  const allNodes = nodes.merge(enteredNodes);

  allNodes.transition().ease(d3.easeQuadInOut).duration(graphSettings.enterAnimationDuration).call(setNodeFinalState);

  nodes
    .exit()
    .transition()
    .duration(graphSettings.exitAnimationDuration)
    .remove()
    .call(setNodeEnterExitState, eventPosition);

  styleNodes(allNodes);
}

export function updateLinks(
  initialPosition: Point,
  links: d3.Selection<d3.BaseType, d3.HierarchyPointLink<Node>, SVGGElement, unknown>
) {
  const enteredLinks = links.enter().append('path');

  enteredLinks.attr('d', diagonal({ source: initialPosition, target: initialPosition }));

  links
    .merge(enteredLinks)
    .transition()
    .ease(d3.easeQuadInOut)
    .duration(graphSettings.enterAnimationDuration)
    .attr('d', diagonal);

  links.exit().remove();
}
