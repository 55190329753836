import { GeneticData, PublicGeneticData } from './geneticData';

export enum UserType {
  Admin = 'ADMIN',
  LabTech = 'LAB_TECH',
  PublicConsumer = 'PUBLIC_API_CONSUMER',
  Root = 'ROOT',
  User = 'USER',
}

export interface Address {
  street?: string;
  town?: string;
  zipCode?: string;
}

export interface ExpandedAddress extends Address {
  county?: string;
  municipality?: string;
}

export interface Profile {
  birthDate?: string;
  correspondenceAddress?: Address;
  email?: string;
  familyName?: string;
  givenName?: string;
  phoneNumber?: string;
  residenceAddress?: ExpandedAddress;
}

export interface Ancestor {
  address?: ExpandedAddress;
  birthDate?: string;
  deathDate?: string;
  familyName?: string;
  givenName?: string;
  placeOfBirth?: string;
  placeOfDeath?: string;
}

export interface AdministratorData {
  id?: number;
  note?: string;
}

export interface User {
  administrationAgreementSigned?: boolean;
  administratorData?: AdministratorData;
  ancestor?: Ancestor;
  geneticData?: GeneticData;
  id?: number;
  profile?: Profile;
  publicId?: string;
  regionId?: number;
}

export interface PublicAddress {
  municipality: string;
}

export interface PublicProfile {
  address?: PublicAddress;
  email?: string;
  familyName?: string;
  givenName?: string;
}

export interface PublicUser {
  ancestor?: Ancestor;
  geneticData?: PublicGeneticData;
  profile?: PublicProfile;
}
