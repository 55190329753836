import './PhylogeneticTree.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import useD3 from '../../hooks/useD3';
import { areInSameSubtree, collapseEntireTree, countChildren, expandEntireTree, find, Node } from './models/graph';
import addZoomAndPanFunctionality from './zoom-and-pan';
import graphSettings from './graph-settings';
import { Point } from '../../utils/geometry';
import Tooltip from '../xShared/Tooltip';
import { TooltipData } from './models/tooltip-data';
import ContextMenu from '../xShared/ContextMenu';
import { ContextMenuCallbacks, ContextMenuData } from './models/context-menu-data';
import { fullTreeQuery, getQueryVariables, getTreeFromRawApiData, getUsersHaploGroup } from './data-providers';
import { Transform } from './models/transform';
import {
  getTreeLayout,
  getTreeOffset,
  getUpdatedTreeStructureFor,
  PointLink,
  PointNode,
  updateLinks,
  updateNodes,
} from './d3-tree-logic';
import MyHaploGroupHelp from './MyHaploGroupHelp';
import routes from '../../route-definitions';
import HaplogroupInfo from './HaploGroupInfo';
import { getUserId } from '../../utils/api-client';

const ftdnaHaplogroupLink = 'https://discover.familytreedna.com/y-dna/{haplogroup}/story';

function PhylogeneticTree() {
  const { data } = useQuery(fullTreeQuery, {
    variables: getQueryVariables(),
  });

  const [toolTipData, setTooltipData] = useState<TooltipData>({
    top: 0,
    left: 0,
    content: [],
    moreUsersCount: null,
  });
  const [toolTipVisible, setTooltipVisible] = useState(false);
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>({
    position: { top: 0, left: 0 },
    node: null,
  });
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuCallBacks, setContextMenuCallBacks] = useState<ContextMenuCallbacks>(null);
  const { t } = useTranslation('phylogenetic-tree');
  const [rawRoot, setRawRoot] = useState<Node>(null);
  const [nodeForInfo, setNodeForInfo] = useState<Node>();
  const [usersHaploGroup, setUsersHaploGroup] = useState<Node>();

  useEffect(() => {
    if (!rawRoot) return;

    getUsersHaploGroup(getUserId()).then((haploGroupFromServer) => {
      const haploGroupNode = find(rawRoot, (n) => n.haplogroupId === haploGroupFromServer.id);
      setUsersHaploGroup(haploGroupNode);
    });
  }, [rawRoot]);

  if (data && !rawRoot) {
    setRawRoot(getTreeFromRawApiData(data.fullTreeUserfied));
  }

  const ref = useD3(
    (svgD3) => {
      if (!rawRoot) return;
      svgD3.selectChildren().remove();

      svgD3.on('mousemove', (event) => {
        setTooltipData((prev) => ({
          ...prev,
          top: event.clientY,
          left: event.clientX,
        }));
      });
      svgD3.on('mousedown', () => {
        setContextMenuVisible(false);
      });
      svgD3.on('wheel', () => {
        setContextMenuVisible(false);
      });

      function getRootOfCurrentTreeLayout() {
        const treeLayout = getTreeLayout();
        return treeLayout(getUpdatedTreeStructureFor(rawRoot));
      }

      const linkStyles = graphSettings.styles.links;
      const linksContainer = svgD3
        .append('g')
        .attr('class', 'links')
        .attr('fill', linkStyles.fill)
        .attr('stroke', linkStyles.stroke.color)
        .attr('stroke-opacity', linkStyles.stroke.opacity)
        .attr('stroke-width', linkStyles.stroke.width);

      const nodesContainer = svgD3.append('g').attr('class', 'nodes');

      let treeOffset: Transform = null;
      function update(sourcePosition?: Point, options = { centerEntireTree: false }) {
        const root = getRootOfCurrentTreeLayout();
        const nodes = root.descendants().reverse(); // .reverse() is called because I want children to be rendered first in svg (it is better for animations)
        const links = root.links();

        const gNodes = nodesContainer.selectAll('g').data(nodes, (node: PointNode) => node.data.id);
        const pLinks = linksContainer.selectAll('path').data(links, (d: PointLink) => d.target.data.id);

        updateNodes(
          sourcePosition,
          gNodes,
          update,
          { setTooltipData, setTooltipVisible },
          { setContextMenuData, setContextMenuVisible }
        );
        updateLinks(sourcePosition, pLinks);

        setContextMenuCallBacks({
          expandEntireSubtree: (node) => {
            expandEntireTree(node.data);
            update({ x: node.x, y: node.y });
            setContextMenuVisible(false);
          },
          collapseEntireSubtree: (node) => {
            collapseEntireTree(node.data);
            update({ x: node.x, y: node.y });
            setContextMenuVisible(false);
          },
        });

        if (options.centerEntireTree) {
          treeOffset = getTreeOffset(svgD3, nodes);
        }
      }

      update({ x: 0, y: 0 }, { centerEntireTree: true });

      addZoomAndPanFunctionality(svgD3, treeOffset);
    },
    [rawRoot]
  );

  function navigateToFTDNA(node: Node) {
    const link = ftdnaHaplogroupLink.replace('{haplogroup}', node.name);
    window.open(link, '_blank');
  }

  function handleClosestRelativeSearch({ inSubtree }: { inSubtree: boolean }) {
    let path = `${routes.relativesSearchRoute.path}?hg=${contextMenuData.node.data.haplogroupId}`;

    if (inSubtree) {
      path += '&subtree=1';
    }

    window.open(path, '_blank');
  }

  return (
    <>
      <div className="phylo-info-container">
        <MyHaploGroupHelp tree={rawRoot} />
        {/* disabled feature */}
        {/* <HaploGroupLegend tree={rawRoot} /> */}
      </div>
      <svg className="phylogenetic-tree-canvas" ref={ref} />
      {toolTipVisible && (
        <Tooltip top={toolTipData.top} left={toolTipData.left}>
          {toolTipData.content.map((c) => (
            <div key={c}>{c}</div>
          ))}
          {toolTipData.moreUsersCount && (
            <div>
              {(toolTipData.moreUsersCount > 4 ? t('moreUsersCount_>4') : t('moreUsersCount_<=4')).replace(
                '{}',
                `${toolTipData.moreUsersCount}`
              )}
            </div>
          )}
        </Tooltip>
      )}
      <HaplogroupInfo node={nodeForInfo} onClose={() => setNodeForInfo(null)} />
      {contextMenuVisible && (
        <ContextMenu
          position={contextMenuData.position}
          options={[
            {
              label: t('infoAboutNode'),
              callback: () => {
                setNodeForInfo(contextMenuData.node.data);
                setContextMenuVisible(false);
              },
            },
            {
              label: `${t('expandEntireSubtree')} - ${countChildren(contextMenuData.node.data)} ${t('childrenNode')}`,
              callback: () => contextMenuCallBacks.expandEntireSubtree(contextMenuData.node),
            },
            {
              label: t('collapseEntireSubtree'),
              callback: () => contextMenuCallBacks.collapseEntireSubtree(contextMenuData.node),
            },
            {
              label: 'FTDNA',
              callback: () => navigateToFTDNA(contextMenuData.node.data),
            },
            ...(areInSameSubtree(usersHaploGroup, contextMenuData.node.data)
              ? [
                  {
                    label: t('searchClosestRelatives'),
                    callback: null,
                    subOptions: [
                      {
                        label: t('inThisNode'),
                        callback: () => handleClosestRelativeSearch({ inSubtree: false }),
                      },
                      {
                        label: t('inEntireSubtree'),
                        callback: () => handleClosestRelativeSearch({ inSubtree: true }),
                      },
                    ],
                  },
                ]
              : []),
          ]}
        />
      )}
    </>
  );
}

export default PhylogeneticTree;
