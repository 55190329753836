import { Dispatch, SetStateAction } from 'react';

// if existing test result is being uploaded, where does it come from?
export enum GeneticDataType {
  Ftdna,
  Other,
}

// tuple (marker name, marker value) for manually input genetic markers
export interface MarkerInputType {
  name: string;
  value?: number;
}

export class RegistrationData {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  name: string = '';
  surname: string = '';
  birthdate: string = '';
  phone: string = '';
  email: string = '';
  password: string = '';
  passwordRepeat: string = '';
  street: string = '';
  town: string = '';
  zipCode: string = '';
  municipality: string = '';
  ancestorName?: string = undefined;
  ancestorSurname?: string = undefined;
  ancestorBirthday?: string = undefined;
  ancestorDateOfDeath?: string = undefined;
  ancestorStreet?: string = undefined;
  ancestorTown?: string = undefined;
  ancestorZipCode?: string = undefined;
  sendKit: boolean = false;
  dataType?: GeneticDataType;
  dataSource: string = '';
  file?: File;
  markers: MarkerInputType[] = [];
  consent: boolean = false;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  clone(): RegistrationData {
    const r = new RegistrationData();
    Object.getOwnPropertyNames(this).forEach((k) => {
      r[k] = this[k];
    });
    return r;
  }

  cloneWith<K extends keyof RegistrationData>(key: K, value: RegistrationData[K]): RegistrationData {
    const r = this.clone();
    r[key] = value;
    return r;
  }

  cloneWithMap(map: Partial<RegistrationData>): RegistrationData {
    const r = this.clone();
    Object.keys(map).forEach((key) => {
      r[key] = map[key];
    });
    return r;
  }
}

export interface RegistrationProps {
  data?: RegistrationData;
  setData?: Dispatch<SetStateAction<RegistrationData>>;
  onNext?: () => void;
  onBack?: () => void;
}

export type RegistrationForm = (props: RegistrationProps) => JSX.Element;
