export interface RouteElement {
  path: string;
}

const landingRoute: RouteElement = {
  path: '/landing',
};

const mapRoute: RouteElement = {
  path: '/map',
};

const phyloTreeRoute: RouteElement = {
  path: '/phylogenetic-tree',
};
const familyTreeRoute: RouteElement = {
  path: '/family-tree',
};
const userFormRoute: RouteElement = {
  path: '/profile/user',
};
const changePasswordRoute: RouteElement = {
  path: '/profile/change-password',
};
const ancestorFormRoute: RouteElement = {
  path: '/profile/ancestor',
};
const relativesSearchRoute: RouteElement = {
  path: '/relative-search',
};
const aboutPageRoute: RouteElement = {
  path: '/about',
};
const adminSearchRoute: RouteElement = {
  path: '/admin/search',
};
const adminKitViewRoute: RouteElement = {
  path: '/admin/kits',
};
const rootRoute: RouteElement = {
  path: '/',
};
const geneticDataView: RouteElement = {
  path: '/profile/my-data',
};
const loggingRoute: RouteElement = {
  path: '/admin/logs',
};
const registrationRoute: RouteElement = {
  path: '/register/*',
};
const registrationBaseRoute: RouteElement = {
  path: '/register',
};
const geneticDataConflictResolution: RouteElement = {
  path: '/genetic-data-conflict-resolution',
};
const rightsManagement: RouteElement = {
  path: '/manage-rights',
};
const snpTrackerRoute: RouteElement = {
  path: 'http://scaledinnovation.com/',
};
const nevgenRoute: RouteElement = {
  path: 'https://www.nevgen.org/',
};
const emailChangeConfirmRoute: RouteElement = {
  path: '/confirm/email',
};
const accountConfirmRoute: RouteElement = {
  path: '/confirm/account',
};
const passwordResetRoute: RouteElement = {
  path: '/confirm/password',
};
const consentConfirmRoute: RouteElement = {
  path: '/confirm/sign',
};
const staffManagement: RouteElement = {
  path: '/root/staff-management',
};
const loginRedirectRoute: RouteElement = {
  path: '/login-redirect',
};

const routes = {
  landingRoute,
  mapRoute,
  phyloTreeRoute,
  familyTreeRoute,
  userFormRoute,
  changePasswordRoute,
  ancestorFormRoute,
  relativesSearchRoute,
  aboutPageRoute,
  adminSearchRoute,
  adminKitViewRoute,
  rootRoute,
  geneticDataView,
  loggingRoute,
  registrationRoute,
  registrationBaseRoute,
  geneticDataConflictResolution,
  rightsManagement,
  snpTrackerRoute,
  nevgenRoute,
  emailChangeConfirmRoute,
  accountConfirmRoute,
  passwordResetRoute,
  consentConfirmRoute,
  staffManagement,
  loginRedirectRoute,
};

export default routes;
