import { gql } from '@apollo/client';

export const userQuery = gql`
  query UserProfile($id: Int!) {
    user(userId: $id) {
      profile {
        givenName
        familyName
        birthDate
        email
        phoneNumber
        residenceAddress {
          town
          street
          zipCode
          county
          municipality
        }
      }
      ancestor {
        givenName
        familyName
        birthDate
        deathDate
        address {
          town
          street
          zipCode
          municipality
        }
      }
    }
  }
`;

export const updateProfileMutation = gql`
  mutation UpdateProfile($id: Int!, $profile: ProfileInput!) {
    updateProfile(userId: $id, profile: $profile) {
      givenName
      familyName
    }
  }
`;

export const requestEmailUpdateQuery = gql`
  query RequestEmailChange($userId: Int!, $newEmail: String!, $language: Language!) {
    requestEmailChange(userId: $userId, newEmail: $newEmail, language: $language) {
      operationSuccessful
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation ChangePassword($userId: Int!, $currentPassword: String!, $newPassword: String!) {
    changePassword(userId: $userId, currentPassword: $currentPassword, newPassword: $newPassword) {
      operationSuccessful
    }
  }
`;

export const updateAncestorMutation = gql`
  mutation UpdateAncestor($userId: Int!, $ancestor: AncestorInput!) {
    updateAncestor(userId: $userId, ancestor: $ancestor) {
      givenName
    }
  }
`;
