import './Logs.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { FilterProps } from './models';
import LogsTable from './LogsTable';
import LogsFilter from './LogsFilter';

export default function Logs() {
  const [filter, setFilter] = useState<FilterProps>({});
  const { t } = useTranslation('logging');

  return (
    <Container>
      <h1 className="logs-title mt-5 mb-4">{t('logs')}</h1>

      <LogsFilter onFilterClick={(newFilter) => setFilter(newFilter)} />

      <LogsTable filter={filter} />

      <div className="mb-4" />
    </Container>
  );
}
