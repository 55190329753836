import { gql } from '@apollo/client';

export interface RawUserReferences {
  strConflicts: {
    user: {
      id: number;
      profile: {
        givenName: string;
        familyName: string;
      };
    };
  }[];
}

export const conflictingUsersQuery = gql`
  query ConflictingUserReferences {
    strConflicts {
      user {
        id
        profile {
          givenName
          familyName
        }
      }
    }
  }
`;

export interface RawDataOrigins {
  strConflicts: {
    user: {
      geneticData: {
        originalStrDataSources: {
          id: number;
          testProvider: {
            name: string;
          };
          strMarkers: {
            name: string;
            value: number;
          }[];
        }[];
      };
    };
  }[];
}

export const dataOriginsQuery = gql`
  query UserGeneticData($userIds: [Int!]) {
    strConflicts(userIds: $userIds) {
      user {
        geneticData {
          originalStrDataSources {
            testProvider {
              name
            }
            strMarkers {
              name
              value
            }
          }
        }
      }
    }
  }
`;
