import { Container } from 'react-bootstrap';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RegistrationData } from '../Registration/types';
import RegistrationConsentForm from '../Registration/RegistrationConsentForm';
import ApiClient, { logOut, logIn } from '../../utils/api-client';
import routes from '../../route-definitions';
import { consentSignMutation } from './queries';
import { AdministrationAgreementSignResult } from '../../apiTypes/confirmation';

function ConsentConfirmation() {
  const [data, setData] = useState(new RegistrationData());
  const [sign] = useMutation<AdministrationAgreementSignResult>(consentSignMutation);
  const { t } = useTranslation('confirm');
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleBack = () => {
    logOut().then(() => navigate(routes.rootRoute.path));
  };

  const handleNext = async () => {
    const result = await sign({ variables: { userId: state.userId } });
    if (result.data?.success) {
      await logOut();
      await logIn(state.userId, result.data.loginToken, state.userType);
      navigate(routes.loginRedirectRoute.path);
      ApiClient.ErrorContainer?.addMessage(t('confirmSign'), 'success', false);
    }
  };

  return (
    <Container>
      <RegistrationConsentForm data={data} setData={setData} onBack={handleBack} onNext={handleNext} />
    </Container>
  );
}

export default ConsentConfirmation;
