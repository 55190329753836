import { gql } from '@apollo/client';

export interface MarkerDto {
  name: string;
  value: number;
}

export const resolveMarkersMutation = gql`
  mutation ResolveConflicts($userId: Int!, $newMarkerValues: [StrMarkerInput!]!) {
    fixStrConflict(userId: $userId, newAggregatedMarkerValues: $newMarkerValues) {
      aggregatedStrMarkerValues {
        name
        value
      }
    }
  }
`;
