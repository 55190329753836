import './RegistrationSidePanelText.css';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function RegistrationSidePanelText() {
  const { t } = useTranslation('registration');
  return (
    <>
      <h1 className="text-center">{t('registration')}</h1>
      <br />
      <br />
      {(t('sidePanel', { returnObjects: true }) as string[]).map((paragraph) => (
        <p>{formatParagraph(paragraph)}</p>
      ))}
    </>
  );
}

function formatParagraph(paragraph: string) {
  return (
    <Trans defaults={paragraph} components={{ aboutLink: <Link className="registration-about-link" to="/about" /> }} />
  );
}

export default RegistrationSidePanelText;
