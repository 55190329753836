import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { FormEvent, useState } from 'react';
import { MarkerSet } from '../../apiTypes/geneticData';
import { GeneticDataType, MarkerInputType, RegistrationProps } from './types';
import { ContinuationButton } from './Buttons';
import { stateUpdater } from './utils';
import ApiClient from '../../utils/api-client';

interface Props extends RegistrationProps {
  show: boolean;
  onHide: () => void;
}

const query = gql`
  query MarkerSets {
    listAvailableMarkerSets {
      markers
    }
  }
`;

interface MarkerSetQueryData {
  listAvailableMarkerSets: MarkerSet[];
}

function ManualDataUploadModal({ data, show, onHide, onNext, setData }: Props) {
  const { t } = useTranslation('registration');
  const [markerInputs, setMarkerInputs] = useState<MarkerInputType[]>([]);
  const { data: markerData } = useQuery<MarkerSetQueryData>(query);
  let markers = [];
  if (markerData) {
    const markerSet = new Set<string>();
    markerData.listAvailableMarkerSets.forEach((ms) => ms.markers.forEach((m) => markerSet.add(m)));
    markers = Array.from(markerSet);
    markers.sort();
  }

  const handleConfirm = (e: FormEvent) => {
    e.preventDefault();
    if (!markerInputs.every(({ name, value }) => !!name && Number.isInteger(value) && value >= 0)) {
      ApiClient.ErrorContainer?.addMessage(t('validation.badMarkerValue'), 'error', false);
      return;
    }
    if (new Set(markerInputs.map(({ name }) => name)).size !== markerInputs.length) {
      ApiClient.ErrorContainer?.addMessage(t('validation.duplicateMarker'), 'error', false);
      return;
    }

    const { files } = document.getElementById('registration-file-upload') as HTMLInputElement;
    if (files.length === 0) {
      ApiClient.ErrorContainer?.addMessage(t('validation.required'), 'error', false);
      return;
    }

    setData((registrationData) =>
      registrationData.cloneWithMap({
        sendKit: false,
        dataType: GeneticDataType.Other,
        file: files[0],
        markers: markerInputs,
      })
    );

    onNext();
  };

  const addMarkerInput = () => {
    setMarkerInputs((inputs) => {
      const newInputs = [...inputs];
      newInputs.push({ name: markers.length ? markers[0] : '' });
      return newInputs;
    });
  };

  const deleteMarkerInput = (i) => () => {
    setMarkerInputs((inputs) => {
      const newInputs = [...inputs];
      newInputs.splice(i, 1);
      return newInputs;
    });
  };

  const handleMarkerInput = (i) => (name, value) => {
    setMarkerInputs((inputs) => {
      const newInputs = [...inputs];
      newInputs[i] = { name, value };
      return newInputs;
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>{t('geneticTest.manualUploadHeader')}</Modal.Header>
      <Form onSubmit={handleConfirm}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('geneticTest.dataSource')}</Form.Label>
            <Form.Control required value={data.dataSource} onChange={stateUpdater('dataSource', setData)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('geneticTest.manualUploadMarkerLabel')}</Form.Label>
            {markerInputs.map(({ name, value }, i) => (
              <MarkerInput
                options={markers}
                setValue={handleMarkerInput(i)}
                onDelete={deleteMarkerInput(i)}
                name={name}
                value={value}
              />
            ))}
            <Button className="mt-2" variant="success" onClick={addMarkerInput}>
              {t('geneticTest.manualUploadAddMarkerButton')}
            </Button>
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>{t('geneticTest.manualUploadFileInput')}</Form.Label>
            <Form.Control type="file" id="registration-file-upload" required />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ContinuationButton />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

interface MarkerInputProps {
  options: Array<string>;
  setValue: (name: string, num: number | null) => void;
  name: string;
  value: number | null;
  onDelete: () => void;
}
function MarkerInput({ options, setValue, name, value, onDelete }: MarkerInputProps) {
  return (
    <InputGroup hasValidation>
      <Form.Select value={name ?? ''} onChange={(e) => setValue(e.target.value, value)}>
        {options.map((option) => (
          <option>{option}</option>
        ))}
      </Form.Select>
      <Form.Control
        type="number"
        min={0}
        value={value ?? ''}
        onChange={(e) => {
          if (e.target.value === '') setValue(name, null);
          else {
            const numValue = Number(e.target.value);
            setValue(name, numValue);
          }
        }}
        isInvalid={!Number.isInteger(value)}
      />
      <Button variant="outline-danger" onClick={onDelete} aria-label="Delete marker">
        {'\u2a09' /* "times" operator symbol used as a closing 'X' */}
      </Button>
    </InputGroup>
  );
}
export default ManualDataUploadModal;
