import { ApolloQueryResult, gql } from '@apollo/client';
import UserOfHaplogroup, { UsersOfHaploGroupDto } from './models/users';
import ApiClient, { getUserId } from '../../utils/api-client';
import graphSettings from './graph-settings';
import { HaploGroupNodeDto, UsersHaploGroupDto } from './models/haplogroup-dto';
import { compareNodes, foreachInTree, getHaplogroupRoots, Node, showNeighborhoodOf } from './models/graph';

export const fullTreeQuery = gql`
  query getTree($loggedUserId: Int!) {
    fullTreeUserfied(userId: $loggedUserId) {
      name
      id
      haplogroupId
      parentId
      haplogroupId
      usersSample {
        profile {
          familyName
          givenName
        }
      }
      peopleCount {
        inside
        inSubtree
      }
      approximateYear
      haplogroupId
      isHaplogroupRoot
      isSpecifiedUser
    }
  }
`;

export const userHaplogroupQuery = gql`
  query UsersHaploGroup($userId: Int!) {
    user(userId: $userId) {
      geneticData {
        haplogroup {
          id
          name
        }
      }
    }
  }
`;

const usersOfHaplogroupQuery = gql`
  query UsersHaploGroup($haplogroupId: Int!, $userId: Int!) {
    usersForNode(userId: $userId, nodeId: $haplogroupId) {
      givenName
      familyName
      email
      residenceAddress {
        municipality
      }
    }
  }
`;

function resolveInitVisibility(root: Node) {
  root.visible = true;
  if (root.isHaplogroupRoot) return;

  root.children.forEach(resolveInitVisibility);
}

function resolveHaploGroupColors(root: Node) {
  const colors = graphSettings.styles.nodes.haploGroupColors;

  const haploGroupRoots = getHaplogroupRoots(root);

  haploGroupRoots.forEach((r, i) => {
    const color = colors[i % colors.length];
    foreachInTree(r, (n) => {
      n.color = color;
    });
  });
}

function sortAllChildrenInTree(root: Node) {
  root.children.sort(compareNodes);
  root.children.forEach(sortAllChildrenInTree);
}

function isRoot(n: { parentId: number }) {
  return n.parentId === null;
}

export function getTreeFromRawApiData(haploNodes: HaploGroupNodeDto[]): Node {
  const nodes = haploNodes.map((n) => ({
    id: n.id,
    haplogroupId: n.haplogroupId,
    parentId: n.parentId,
    parent: null,
    name: n.name,
    count: { inside: n.peopleCount.inside, inSubtree: n.peopleCount.inSubtree },
    children: [],
    visible: false,
    color: graphSettings.styles.nodes.default.color,
    isHaplogroupRoot: n.isHaplogroupRoot,
    approximateYear: Math.abs(n.approximateYear),
    usersSample: n.usersSample.map((s) => `${s.profile.givenName ?? ''} ${s.profile.familyName ?? ''}`.trim()),
    isMain: n.isSpecifiedUser,
  }));

  const nodesDict: { [id: number]: Node } = {};

  nodes.forEach((n) => {
    const node = n;
    nodesDict[node.id] = node;
    node.children = [];
  });

  nodes.forEach((n) => {
    if (isRoot(n)) return;

    const parent = nodesDict[n.parentId];
    n.parent = parent;

    parent.children.push(n);
  });

  const root = nodes.find(isRoot);
  const main = nodes.find((n) => n.isMain);

  sortAllChildrenInTree(root);
  resolveInitVisibility(root);
  showNeighborhoodOf(main);
  resolveHaploGroupColors(root);

  return root;
}

export function getQueryVariables() {
  return { loggedUserId: getUserId() };
}

export async function getUsersHaploGroup(userId: number) {
  const rawData = await ApiClient.current.query<UsersHaploGroupDto>({
    query: userHaplogroupQuery,
    variables: { userId },
  });

  return { ...rawData.data.user.geneticData.haplogroup };
}

export async function getUserOfHaplogroup(haplogroupId: number): Promise<UserOfHaplogroup[]> {
  let response: ApolloQueryResult<UsersOfHaploGroupDto>;

  try {
    response = await ApiClient.current.query<UsersOfHaploGroupDto>({
      query: usersOfHaplogroupQuery,
      variables: {
        userId: getUserId(),
        haplogroupId,
      },
    });
  } catch {
    // user cannot display users
    return null;
  }

  return response.data.usersForNode.map((rawUser) => ({
    name: `${rawUser.givenName} ${rawUser.familyName}`,
    email: rawUser.email,
    municipality: rawUser.residenceAddress.municipality,
  }));
}
