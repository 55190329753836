import './TopBar.css';
import './TopBar.phone.css';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import routes from '../../../route-definitions';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { getProfileName, isUserAdmin, logOut } from '../../../utils/api-client';

let mouseOnUserMenu = false;
let lastTimeout;

function TopBar() {
  const [userMenuVisible, setUserMenuVisible] = useState<boolean>(false);
  const { t } = useTranslation('skeleton');
  const profileName = getProfileName();

  const hideUserMenu = () => {
    if (lastTimeout) clearTimeout(lastTimeout);

    lastTimeout = setTimeout(() => {
      if (!mouseOnUserMenu) setUserMenuVisible(false);
      lastTimeout = null;
    }, 500);
  };

  const mouseEnteredUserMenuIcon = () => {
    mouseOnUserMenu = true;
    setUserMenuVisible(true);
  };

  const mouseLeftUserMenuIcon = () => {
    hideUserMenu();
    mouseOnUserMenu = false;
  };

  const mouseLeftUserMenu = () => {
    hideUserMenu();
    mouseOnUserMenu = false;
  };

  const mouseEnteredUserMenu = () => {
    mouseOnUserMenu = true;
  };

  return (
    <div className="top-bar-container">
      <div className="logo-container">
        <Link to="/" className="logo-link">
          <div className="logo-picture glow">🧬</div>
          <div className="logo-text">
            <span className="first-letter">G</span>
            <span className="rest-of-word">enetika</span>

            <span className="rest-of-word"> </span>
            <span className="first-letter">A</span>
            <span className="rest-of-word"> </span>

            <span className="first-letter">P</span>
            <span className="rest-of-word">říjmení</span>
          </div>
        </Link>
      </div>
      <div className="top-bar-middle" />
      {profileName && (
        <>
          <div className="lang-switch-container">
            <LanguageSwitch />
          </div>
          <div className="user-name">{profileName}</div>
          <button
            type="button"
            className="user-menu-container"
            onMouseEnter={mouseEnteredUserMenuIcon}
            onMouseLeave={mouseLeftUserMenuIcon}
          >
            <FontAwesomeIcon className="user-menu-icon" icon={faBars} />

            <div className="option-list-container">
              <div
                className={`window option-list ${userMenuVisible ? 'option-list-visible' : ''}`}
                onMouseEnter={mouseEnteredUserMenu}
                onMouseLeave={mouseLeftUserMenu}
              >
                <div className="user-menu-option">
                  <NavLink to={isUserAdmin() ? routes.changePasswordRoute.path : routes.userFormRoute.path}>
                    {t('profile')}
                  </NavLink>
                </div>
                <div className="user-menu-option">
                  <NavLink to="/manage-rights">{t('manageAccess')}</NavLink>
                </div>
                <div className="user-menu-option logout-btn">
                  <Link to={routes.landingRoute.path} onClick={logOut}>
                    {t('logout')}
                  </Link>
                </div>
              </div>
            </div>
          </button>
        </>
      )}
    </div>
  );
}

export default TopBar;
