import { Gender } from './models';
import femaleIcon from '../../pictures/female.svg';
import maleIcon from '../../pictures/man.svg';

const maleAvatar = maleIcon;
const femaleAvatar = femaleIcon;

const familyTreeConfig = {
  getAvatarFor: (gender: Gender) => {
    if (gender === Gender.Male) return maleAvatar;
    if (gender === Gender.Female) return femaleAvatar;

    throw new Error(`Undefined avatar for gender ${gender}`);
  },
  f3CardProperties: {
    card_dim: { w: 220, h: 70, text_x: 75, text_y: 15, img_w: 60, img_h: 60, img_x: 5, img_y: 5 },
    card_display: [(d) => `${d.data.firstName || ''} ${d.data.familyName || ''}`, (d) => `${d.data.dateOfBirth || ''}`],
    mini_tree: true,
    link_break: false,
  },
  f3StoreProperties: {
    node_separation: 250,
    level_separation: 150,
  },
};

export default familyTreeConfig;
