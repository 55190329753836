import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Kit, KitQueryResult } from '../../../apiTypes/kits';
import { kitSearchQuery } from './queries';

interface Props extends ModalProps {
  onFindKit: (kit: Kit) => void;
}

function KitSearchModal({ show, onHide, onFindKit }: Props) {
  const [findKit] = useLazyQuery<KitQueryResult>(kitSearchQuery);
  const [kitId, setKitId] = useState('');
  const [isInvalid, setInvalid] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation('kits');

  // automatically focus and clear the input box when the modal is opened
  useEffect(() => {
    if (show) {
      inputRef?.current?.focus();
      setKitId('');
    }
  }, [show]);

  const handleKitInputChange = (e) => {
    setKitId(e.target.value);
    setInvalid(false);
  };

  const handleFindKit = (e) => {
    e.preventDefault();
    findKit({ variables: { id: Number(kitId) } }).then((result) => {
      if (!result.data || result.data.kits.totalCount === 0) {
        setInvalid(true);
        return;
      }
      const kit = result.data.kits.items[0];
      onFindKit(kit);
      setKitId(''); // reset input when kit is successfully found
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>{t('searchKit')}</Modal.Header>
      <Form onSubmit={handleFindKit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('kitId')}</Form.Label>
            <Form.Control
              ref={inputRef}
              value={kitId}
              onChange={handleKitInputChange}
              isInvalid={isInvalid}
              inputMode="numeric"
            />
            <Form.Control.Feedback type="invalid">{t('kitNotFound')}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {t('search')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default KitSearchModal;
