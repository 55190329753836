import * as d3 from 'd3';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { Dispatch, SetStateAction } from 'react';
import * as topojson from 'topojson-client';
import { Topology } from 'topojson-specification';
import { D3Selection } from '../../hooks/useD3';
import { TooltipData, TopoJsonProps } from './types';
import { Region } from '../../apiTypes/map';

interface RenderData {
  mapData: Topology;
  regionData: Region[];
  currentLayer: number;
  openRegionDetail: (e: Event, d: Feature) => void;
  setTooltip: Dispatch<SetStateAction<TooltipData>>;
}

// D3 function for visualizing the haplogroup distribution on a map
export default function renderMap(
  svg: D3Selection,
  { mapData, regionData, currentLayer, openRegionDetail, setTooltip }: RenderData
) {
  if (!mapData) return;
  /* Data preparation */
  const o = svg.select('.current-layer');
  const k = svg.select('.kraje');
  const projection = d3.geoMercator().center([15.34, 49.75]).scale(10000).translate([0, 0]);

  const path = d3.geoPath().projection(projection);

  const color = d3.scaleSequential(d3.interpolate('rgb(242, 242, 242)', 'rgb(197, 33, 88)')); // magenta
  // parse topojson into feature arrays
  const kraje = topojson.feature(mapData, mapData.objects.VUSC_P) as FeatureCollection<Geometry, TopoJsonProps>;
  const okresy = topojson.feature(mapData, mapData.objects.OKRESY_P) as FeatureCollection<Geometry, TopoJsonProps>;
  const orp = topojson.feature(mapData, mapData.objects.ORP_P) as FeatureCollection<Geometry, TopoJsonProps>;
  const layers = [orp, okresy, kraje];

  const currentView = layers[currentLayer];

  // append region metadata to selected feature collection and calculate maximum count
  let max = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const region of currentView.features) {
    const meta = regionData.find((x) => x.id === region.id);
    region.properties.meta = meta;
    if (meta?.userCount > max) max = meta.userCount;
  }

  /* Data visualization */
  // "CSS" style constants for "kraje"
  const krajStrokeWidth = '.7';
  const krajStrokeWidthHover = '3';
  const krajStrokeColor = 'var(--color-6)';
  const krajFill = '#FFF4';
  const krajFillHover = 'transparent';

  // always render "kraje" layer with mostly transparent overlay and borders that expand on hover
  k.selectAll('.kraj')
    .data(kraje.features)
    .enter()
    .append('path')
    .attr('class', (d) => `kraj ${d.id}`)
    .attr('fill', krajFill)
    .attr('stroke', krajStrokeColor)
    .attr('stroke-width', krajStrokeWidth)
    .attr('d', path)
    .on('mouseenter', function onMouseEnter() {
      d3.select(this).attr('stroke-width', krajStrokeWidthHover).attr('fill', krajFillHover);
    })
    .on('mouseleave', function onMouseLeave() {
      d3.select(this).attr('stroke-width', krajStrokeWidth).attr('stroke', krajStrokeColor).attr('fill', krajFill);
    });

  let currentChunkName = '';
  // "CSS" style constants for selected layer
  const chunkStrokeWidth = '.5';
  const chunkHoverStrokeWidth = '5';
  const chunkStrokeColor = 'var(--color-5)';

  // render selected layer
  o.selectAll('.layer-chunk')
    .data(currentView.features)
    .join('path')
    .attr('class', (d) => `layer-chunk ${d.id}`)
    .attr('fill', (d) => color((d.properties.meta?.userCount ?? 0) / max))
    .attr('stroke', chunkStrokeColor)
    .attr('stroke-width', chunkStrokeWidth)
    .attr('d', path)
    .on('click', openRegionDetail)
    .on('mouseenter', function onMouseEnter(e: MouseEvent, d: Feature<Geometry, TopoJsonProps>) {
      const chunkName = d.properties.name;
      let kraj = d;
      // go up the tree to find "kraj" corresponding to this region. "kraj" is always the last layer in the list
      for (let i = currentLayer; i < layers.length - 1; i++) {
        /* eslint-disable-next-line @typescript-eslint/no-loop-func */ // (the find fn is executed only inside the loop)
        kraj = layers[i + 1].features.find((parent) => parent.id === kraj.properties.parent_id);
      }

      // don't duplicate the tooltip label for regions
      const krajName = currentLayer === 2 ? undefined : kraj.properties.name;
      setTooltip({ chunk: chunkName, kraj: krajName, top: e.clientY, left: e.clientX });
      currentChunkName = chunkName;

      d3.select(this).attr('stroke-width', chunkHoverStrokeWidth);
    })
    .on('mouseleave', function onMouseLeave(e: MouseEvent, d: Feature<Geometry, TopoJsonProps>) {
      if (d.properties.name === currentChunkName) setTooltip(null);

      d3.select(this).attr('stroke-width', chunkStrokeWidth).attr('stroke', chunkStrokeColor);
    });

  /* Data interaction */
  // dispatch clicks to relevant regions whenever the SVG is clicked on
  svg.on('click', handleClick);

  // updates the coordinates of the React-rendered hover tooltip based on pointer position
  function modifyTooltipCoords(e: PointerEvent) {
    const newCoords = { top: e.clientY, left: e.clientX };
    setTooltip((prev) => (prev ? { ...prev, ...newCoords } : null));
  }

  let lastChunkEntered: Element = null;
  let lastKrajEntered: Element = null;
  // handle pointer moving across regions
  function mouseEnteredLeft(e: PointerEvent) {
    e.stopPropagation();
    e.preventDefault();

    modifyTooltipCoords(e);

    lastChunkEntered = dispatchEnterLeaveEventsTo(e, 'layer-chunk', lastChunkEntered);
    lastKrajEntered = dispatchEnterLeaveEventsTo(e, 'kraj', lastKrajEntered);
  }

  // handle pointer leaving the SVG element
  function mouseLeftSvg(e: PointerEvent) {
    e.stopPropagation();
    e.preventDefault();

    dispatchMouseLeave(e, lastKrajEntered);
    dispatchMouseLeave(e, lastChunkEntered);
  }

  svg.on('mousemove', mouseEnteredLeft);
  svg.on('mouseleave', mouseLeftSvg);
}

function handleClick(e: PointerEvent) {
  e.stopPropagation();
  e.preventDefault();
  const elements = document
    .elementsFromPoint(e.clientX, e.clientY)
    .filter((el) => el.classList.contains('layer-chunk'));
  elements.forEach((el) => el.dispatchEvent(new MouseEvent('click', { clientX: e.clientX, clientY: e.clientY })));
}

function dispatchMouseLeave(e: PointerEvent, elem: Element) {
  if (elem) elem.dispatchEvent(new MouseEvent('mouseleave', { clientX: e.clientX, clientY: e.clientY }));
}

function dispatchMouseEnter(e: PointerEvent, elem: Element) {
  if (elem) elem.dispatchEvent(new MouseEvent('mouseenter', { clientX: e.clientX, clientY: e.clientY }));
}

function dispatchEnterLeaveEventsTo(e: PointerEvent, targetClass: string, lastEntered: Element) {
  const elements = document.elementsFromPoint(e.clientX, e.clientY).filter((el) => el.classList.contains(targetClass));

  const currElem = elements.length !== 0 ? elements[0] : null;

  if (lastEntered === currElem) return currElem;

  dispatchMouseLeave(e, lastEntered);
  dispatchMouseEnter(e, currElem);

  return currElem;
}
