import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import Map from '../Map/Map';
import './LandingPage.css';
import './LandingPage.phone.css';
import routes from '../../route-definitions';
import LanguageSwitch from '../AppSkeleton/LanguageSwitch/LanguageSwitch';
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import ftdnaLogo from '../../pictures/ftdna-logo.svg';
import nevgenLogo from '../../pictures/nevgen-logo.png';

const VISIBLE_STRIP_WIDTH = 10;

function LandingPage(): JSX.Element {
  const [infoClosed, setInfoClosed] = useState(false);
  const [hoverToggleTextBtn, setHoverToggleTextBtn] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isPwdResetOpen, setPwdResetOpen] = useState(false);
  const { t } = useTranslation('skeleton');
  const [textOffset, setTextOffset] = useState<number>(null);

  const textContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!textContainerRef.current) return;

    if (infoClosed) {
      const textWidth = textContainerRef.current.clientWidth;

      setTextOffset(-textWidth + VISIBLE_STRIP_WIDTH);
    } else {
      setTextOffset(null);
    }
  }, [textContainerRef, infoClosed]);

  useEffect(() => {
    window.addEventListener('resize', () => setInfoClosed(false));
  }, []);

  const landingTextAdditionalClasses =
    (infoClosed ? 'landing-text-closed ' : 'landing-text-opened ') +
    (hoverToggleTextBtn ? 'hover-on-toggle-landing-page-btn' : '');

  const handleForgotPassword = () => {
    setLoginOpen(false);
    setPwdResetOpen(true);
  };

  return (
    <div className="landing-page">
      <div
        ref={textContainerRef}
        style={{ marginLeft: textOffset }}
        className={`landing-text ${landingTextAdditionalClasses}`}
      >
        <div className="text-content">
          <div className="title">
            <h1>Genetika a Příjmení</h1>
          </div>
          <p className="description">
            V projektu Genetika a příjmení jsou primárně zkoumány haplotypy chromozomu Y, což jsou specifické sekvence
            DNA, předávané výhradně z otce na syna. Stejně jako chromozom Y, také většina příjmení je děděna v mužské
            linii. Příjmení tedy představují jakýsi zástupný znak. Cílem projektu je hledat vztahy mezi genetickými
            liniemi a příjmeními a zasadit je do kontextu celkové populační struktury, geografie a historie.
          </p>
        </div>

        <div className="footer-inside-landing-text">
          <LegalInfo />
        </div>
      </div>
      <div className="landing-page-buttons mt-2 ms-2 mb-2">
        <Link to={routes.registrationBaseRoute.path}>
          <Button color="success" variant="contained" type="button" className="px-3">
            {t('register')}
          </Button>
        </Link>
        <Button
          color="success"
          variant="outlined"
          type="button"
          className="ms-2 me-3"
          onClick={() => setLoginOpen(true)}
        >
          {t('login')}
        </Button>
        <div className="landing-lan-switch-container mt-2">
          <LanguageSwitch />
        </div>
      </div>
      <div className="landing-map">
        <button
          type="button"
          className="toggle-landing-page-btn"
          onClick={() => setInfoClosed(!infoClosed)}
          onMouseEnter={() => setHoverToggleTextBtn(true)}
          onMouseLeave={() => setHoverToggleTextBtn(false)}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={`toggle-landing-page-icon ${infoClosed ? 'landing-text-closed' : ''}`}
          />
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={`toggle-landing-page-icon ${infoClosed ? 'landing-text-closed' : ''}`}
          />
        </button>

        <Map />
      </div>

      <div className="footer-landing-page">
        <LegalInfo />
      </div>

      <LoginModal show={isLoginOpen} onHide={() => setLoginOpen(false)} onForgotPassword={handleForgotPassword} />
      <ForgotPasswordModal show={isPwdResetOpen} onHide={() => setPwdResetOpen(false)} />
    </div>
  );
}

function LegalInfo() {
  return (
    <div className="legal-info">
      <Row>
        <Col xs={6}>
          <a href="https://familytreedna.com">
            <img src={ftdnaLogo} alt="FTDNA logo" className="landing-logo" />
          </a>
        </Col>
        <Col xs={6}>
          <a href="https://nevgen.org">
            <img src={nevgenLogo} alt="NevGen logo" className="landing-logo" />
          </a>
        </Col>
      </Row>
      <p>
        Výzkumná databáze DNA profilů. Copyright © 2023 Kriminalistický ústav PČR a Matematicko-fyzikální fakulta UK.
      </p>
      <p>
        <i>
          Osobní údaje jsou zabezpečeny v souladu se zák. č. 110/2019 Sb. a s nařízením Evropského parl. a Rady EU č.
          2016/679 (obecné nařízení o ochraně osobních údajů – GDPR).{' '}
          <Link to={routes.aboutPageRoute.path}>Důležité&nbsp;informace.</Link>
        </i>
      </p>
    </div>
  );
}

export default LandingPage;
