import { useLazyQuery } from '@apollo/client';
import { Form, FormGroup, Modal, ModalProps, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import routes from '../../route-definitions';
import { getProfileName, getUserId, logIn } from '../../utils/api-client';
import { loginQuery } from './queries';

interface Props extends ModalProps {
  onForgotPassword: () => void;
}

function LoginModal({ show, onHide, onForgotPassword }: Props) {
  const [sendLogin, { loading, data }] = useLazyQuery(loginQuery);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(0);
  const { t } = useTranslation('skeleton');
  const navigate = useNavigate();

  if (show && getProfileName()) {
    return <Navigate to={routes.geneticDataView.path} />;
  }

  if (data) {
    const {
      loginByPassword: { token, userId, userType },
    } = data;

    if (token) {
      if (loggedIn === 0 && !getUserId()) {
        setLoggedIn(1);
        logIn(userId, token, userType).then(() => {
          setLoggedIn(2);
        });
      } else if (loggedIn === 2) {
        setLoggedIn(0);
        navigate(routes.loginRedirectRoute.path, { state: { email } });
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    sendLogin({ variables: { userName: email, password } });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="header-3">{t('login')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormGroup>
              <TextField
                label="Email"
                type="email"
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                size="small"
                className="mt-2"
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                type="password"
                disabled={loading}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                label={t('password')}
                size="small"
                className="mt-2"
              />
              <Button type="button" color="error" size="small" className="mt-3" onClick={onForgotPassword}>
                {t('forgottenPassword')}
              </Button>
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant="contained" color="success" type="submit">
            {loading ? <Spinner /> : t('login')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default LoginModal;
