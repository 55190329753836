import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getUserId } from '../../utils/api-client';
import getGetTextColorForBackground from './d3-tree-logic';
import { getUsersHaploGroup } from './data-providers';
import graphSettings from './graph-settings';
import { find, Node } from './models/graph';
import './MyHaploGroupHelp.css';

export default function MyHaploGroupHelp({ tree }: { tree: Node }) {
  const [helpInfo, setHelpInfo] = useState<{
    nodeColor: string;
    isInTree: boolean;
  }>({
    nodeColor: graphSettings.styles.nodes.default.color,
    isInTree: false,
  });

  const [haplogroup, setHaplogroup] = useState<{
    id: number;
    name: string;
  }>();

  const [loading, setLoading] = useState(true);

  const haploNameRef = useRef<HTMLSpanElement>();
  const mockNodeRef = useRef<HTMLDivElement>();
  const { t } = useTranslation('phylogenetic-tree');

  useEffect(() => {
    const userId = getUserId();
    getUsersHaploGroup(userId)
      .then(setHaplogroup)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!haplogroup || !tree) return;

    const haploNode = find(tree, (n) => n.haplogroupId === haplogroup.id);

    if (haploNode) {
      setHelpInfo({
        nodeColor: haploNode.color,
        isInTree: true,
      });
    }
  }, [tree, haplogroup]);

  useEffect(() => {
    if (!haploNameRef.current || !mockNodeRef.current) return;

    const nodeWidth = mockNodeRef.current.clientWidth;
    const textWidth = haploNameRef.current.clientWidth;

    if (textWidth <= nodeWidth) return;

    const oldFontSizeInPx = window.getComputedStyle(haploNameRef.current, null).getPropertyValue('font-size');
    const newFontSize = (parseInt(oldFontSizeInPx, 10) * nodeWidth) / textWidth;

    haploNameRef.current.style.fontSize = `${newFontSize}px`;
  }, [haploNameRef, mockNodeRef, haplogroup]);

  const nodeStyle: CSSProperties = {
    animationDuration: `${graphSettings.styles.nodes.main.pulsingCircle.animationPeriod}ms`,
    borderColor: graphSettings.styles.nodes.default.stroke.color,
    backgroundColor: helpInfo.nodeColor,
  };

  const nodeTextStyles: CSSProperties = {
    color: getGetTextColorForBackground(helpInfo.nodeColor),
  };

  if (loading)
    return (
      <div className="my-haplo-group-help-container mt-2 ms-3 p-4">
        <Spinner />
      </div>
    );

  return (
    <div className="my-haplo-group-help-container mt-2 ms-3">
      {haplogroup ? (
        <>
          <div
            style={nodeStyle}
            ref={mockNodeRef}
            className={`${!helpInfo.isInTree ? 'disabled-node' : ''} mock-haplo-node`}
          >
            <span style={nodeTextStyles} ref={haploNameRef}>
              {haplogroup.name}
            </span>
          </div>
          <div className="my-haplo-group-help-text">
            <div className="help-text-fst-row">{t('yourHaploGroup')}</div>
            <div className="help-text-snd-row">{haplogroup.name}</div>
            <div className="help-text-thr-row mt-1">
              <i>{helpInfo.isInTree ? t('youHaploGroupIsHightedByPulsingCircle') : t('nodeIsNotInTree')}</i>
            </div>
          </div>
        </>
      ) : (
        <div className="no-haplo-group-container">{t('youHaveNoHaploGroup')}</div>
      )}
    </div>
  );
}
