import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { isMenuItemVisible, SideMenuItem } from './side-menu-items';
import './SideMenu.css';
import './SideMenu.phone.css';

interface Props {
  items: SideMenuItem[];
}
function SideMenu({ items }: Props) {
  const currentPath = useLocation().pathname;
  const { t } = useTranslation('skeleton');

  const [sideMenuOpened, setSideMenuOpened] = useState<Boolean>(false);

  return (
    <div className={`side-menu-container ${sideMenuOpened ? 'side-menu-opened' : 'site-menu-closed'}`}>
      <button className="side-menu-toggle-btn" type="button" onClick={() => setSideMenuOpened((x) => !x)}>
        {sideMenuOpened ? t('closeMenu') : t('openMenu')}
      </button>

      {items.filter(isMenuItemVisible).map((item) => {
        let classes = 'side-menu-item ';

        const isSelectedLink = item.routes.some((e) => e.path === currentPath);
        if (isSelectedLink) classes += 'selected-side-menu-item';

        if (item.isAbsolute || item.isHardLink) {
          return (
            // eslint-disable-next-line react/jsx-no-target-blank -- cannot happen
            <a
              key={item.label}
              className={classes}
              href={item.routes[0].path}
              target={item.openInNewTab ? '_blank' : null}
              rel={item.openInNewTab ? 'noreferrer' : null}
            >
              <div>
                {item.prefix} {t(item.label)}
              </div>
            </a>
          );
        }

        return (
          <Link
            key={item.label}
            className={classes}
            to={item.routes[0].path}
            target={item.openInNewTab ? '_blank' : null}
          >
            <div>
              {item.prefix} {t(item.label)}
            </div>
          </Link>
        );
      })}
    </div>
  );
}

export default SideMenu;
