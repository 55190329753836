import { PropsWithChildren, useRef } from 'react';
import './Tooltip.css';

interface TooltipProps {
  top: number;
  left: number;
}

const widthPadding = 20;

export default function Tooltip({ top, left, children }: PropsWithChildren<TooltipProps>) {
  const totalWidth = window.innerWidth;

  const tooltipRef = useRef<HTMLDivElement>();

  const pos = { top, left };

  if (tooltipRef.current) {
    const tooltipWidth = tooltipRef.current.clientWidth;

    if (left + tooltipWidth > totalWidth - widthPadding) {
      pos.left = left - tooltipWidth;
    }
  }

  return (
    <div ref={tooltipRef} className="tool-tip-container" style={pos}>
      {children}
    </div>
  );
}
