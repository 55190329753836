import { Button, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function ContinuationButton({ finalStage }: { finalStage?: boolean }) {
  const { t } = useTranslation('registration');

  return (
    <Button type="submit" variant="success">
      {t(finalStage ? 'buttons.register' : 'buttons.continue')}
    </Button>
  );
}

interface BackButtonProps {
  onClick: () => void;
}

export function BackButton({ onClick }: BackButtonProps) {
  const { t } = useTranslation('registration');
  return (
    <Button type="button" variant="secondary" onClick={onClick}>
      {t('buttons.back')}
    </Button>
  );
}

interface ButtonRowProps {
  onBackClick: () => void;
  finalStage?: boolean;
}

export function ButtonRow({ onBackClick, finalStage }: ButtonRowProps) {
  return (
    <ButtonToolbar className="mt-4 justify-content-between">
      <BackButton onClick={onBackClick} />
      <ContinuationButton finalStage={finalStage} />
    </ButtonToolbar>
  );
}
