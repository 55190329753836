import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { RegistrationProps } from './types';
import FtdnaDataUploadModal from './FtdnaDataUploadModal';
import ManualDataUploadModal from './ManualDataUploadModal';
import { BackButton } from './Buttons';

function RegistrationKitTypeForm({ data, setData, onNext, onBack }: RegistrationProps) {
  const [isFtdnaModalOpen, setFtdnaModalOpen] = useState(false);
  const [isManualModalOpen, setManualModalOpen] = useState(false);
  const { t } = useTranslation('registration');

  const handleSendKitClick = () => {
    setData((d) => d.cloneWith('sendKit', true));
    onNext();
  };

  const handleFtdnaClick = () => {
    setFtdnaModalOpen(true);
  };

  const handleManualClick = () => {
    setManualModalOpen(true);
  };

  return (
    <div>
      <h3>{t('geneticTest.title')}</h3>
      <p className="text-secondary">{t('description.testKit')}</p>
      <div className="text-center">
        <Row>
          <Col sm={6} className="mt-5">
            <Button variant="success" onClick={handleSendKitClick}>
              {t('geneticTest.sendKitButton')}
            </Button>
          </Col>
          <Col sm={6} className="mt-5">
            <Dropdown>
              <Dropdown.Toggle variant="secondary">{t('geneticTest.haveOwnDataButton')}</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="ftdna" onClick={handleFtdnaClick}>
                  FTDNA
                </Dropdown.Item>
                <Dropdown.Item eventKey="other" onClick={handleManualClick}>
                  {t('geneticTest.haveOwnOtherOption')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <FtdnaDataUploadModal
        isOpen={isFtdnaModalOpen}
        onHide={() => setFtdnaModalOpen(false)}
        setData={setData}
        onNext={onNext}
      />
      <ManualDataUploadModal
        show={isManualModalOpen}
        onHide={() => setManualModalOpen(false)}
        setData={setData}
        onNext={onNext}
        data={data}
      />

      <div className="text-center mt-5">
        <BackButton onClick={onBack} />
      </div>
    </div>
  );
}
export default RegistrationKitTypeForm;
