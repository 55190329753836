import { gql } from '@apollo/client';

export const availableViewDataQuery = gql`
  query AvailableData($userId: Int!) {
    userViewSettings(userId: $userId) {
      viewableUsers {
        id
        profile {
          familyName
          givenName
        }
      }
    }
  }
`;

export const availableEditDataQuery = gql`
  query AvailableData($userId: Int!) {
    userManagementSettings(userId: $userId) {
      managedUsers {
        id
        profile {
          familyName
          givenName
        }
      }
    }
  }
`;

export interface RawUserGeneticData {
  user: {
    geneticData: {
      haplogroup: {
        name: string;
      };
      strConflict: {
        conflictingMarkers: string[];
      };
      strMarkers: {
        name: string;
        value: number;
      }[];
      originalStrDataSources: {
        id: number;
        userId: number;
        testProvider: {
          name: string;
        };
        strMarkers: {
          name: string;
          value: number;
        }[];
      }[];
    };
  };
  conflictingMarkers: string[];
}

export const userGeneticDataQuery = gql`
  query UserGeneticData($userId: Int!) {
    user(userId: $userId) {
      geneticData {
        haplogroup {
          name
        }
        strConflict {
          conflictingMarkers
        }
        strMarkers {
          name
          value
        }
        originalStrDataSources {
          testProvider {
            name
          }
          strMarkers {
            name
            value
          }
        }
      }
    }
  }
`;
