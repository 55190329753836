import { Box, Button, Chip, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './EditFamilyNodeModal.css';
import dayjs, { Dayjs } from 'dayjs';
import {
  FamilyNodeEditLogic,
  FamilyTreeEditFormState,
  FamilyTreeNode,
  FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT,
  Gender,
} from './models';
import { deepCopyOfSimpleObject } from '../../utils/js-helpers';

export default function EditFamilyNodeModal({ show, onSave, tree, onCancel, formLogic }: EditProps) {
  const { t } = useTranslation('familyTree');

  const [formState, setFormState] = useState<FamilyTreeEditFormState>(getEmptyFormState());
  const [errorMessageKey, setErrorMessageKey] = useState<string>();

  useEffect(() => {
    const emptyState = getEmptyFormState();
    setFormState({ ...emptyState, ...formLogic?.defaultValues });
    setErrorMessageKey(null);
  }, [formLogic]);

  function handleChangeFunctionOf(key: keyof FamilyTreeEditFormState, valueAccessor?: (event: any) => any) {
    return (event) => {
      const value = valueAccessor ? valueAccessor(event) : defaultAccessor(event);

      setFormState((prev) => {
        const newState = { ...prev };
        newState[key] = value;
        return newState;
      });
    };

    function defaultAccessor(e) {
      return e.target.value;
    }
  }

  function handleSaveClick() {
    const state = deleteFalsyFieldsFrom(formState);

    formLogic.validate
      .beforeSubmit(state)
      .onValid(() => {
        const newTree = formLogic.updateTreeWith(state, tree);
        onSave(newTree);
      })
      .onError(setErrorMessageKey);
  }

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t(formLogic?.keyToTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <h5 className="edit-family-node-title mt-2">{t('personalData')}</h5>
          <div className="mt-4 row">
            <div className="col-6">
              <TextField
                fullWidth
                value={formState.firstName}
                disabled={formLogic?.disabled.firstName}
                onChange={handleChangeFunctionOf('firstName')}
                size="small"
                label={t('firstName')}
                variant="outlined"
              />
            </div>
            <div className="col-6">
              <TextField
                fullWidth
                value={formState.familyName}
                disabled={formLogic?.disabled.familyName}
                onChange={handleChangeFunctionOf('familyName')}
                size="small"
                label={t('familyName')}
                variant="outlined"
              />
            </div>
          </div>
          <div className="mt-4 row">
            <div className="col-8">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="me-2"
                  label={t('dateOfBirth')}
                  disabled={formLogic?.disabled.dateOfBirth}
                  value={dayjs(formState.dateOfBirth, FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT)}
                  format={FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT}
                  onChange={handleChangeFunctionOf('dateOfBirth', (date: Dayjs) =>
                    date.format(FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT)
                  )}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-4 mb-4">
              <TextField
                select
                label={t('gender')}
                disabled={formLogic?.disabled.gender}
                value={formState.gender}
                onChange={handleChangeFunctionOf('gender')}
                size="small"
                fullWidth
              >
                <MenuItem value={Gender.Male}>{t('male')}</MenuItem>
                <MenuItem value={Gender.Female}>{t('female')}</MenuItem>
              </TextField>
            </div>
          </div>

          <h5 className="edit-family-node-title mt-2">{t('relations')}</h5>
          <div className="mt-4 row">
            <div className="col-6">
              <TextField
                select
                label={t('father')}
                disabled={formLogic?.disabled.father}
                value={formState.father}
                onChange={handleChangeFunctionOf('father')}
                size="small"
                fullWidth
              >
                <MenuItem value="">
                  <em>{t('noneFather')}</em>
                </MenuItem>
                {formLogic?.optionsForSelects.father.map((fatherOption) => (
                  <MenuItem key={fatherOption} value={fatherOption}>
                    {formLogic?.getPersonName(fatherOption)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-6">
              <TextField
                select
                label={t('mother')}
                disabled={formLogic?.disabled.mother}
                value={formState.mother}
                onChange={handleChangeFunctionOf('mother')}
                size="small"
                fullWidth
              >
                <MenuItem value="">
                  <em>{t('noneMother')}</em>
                </MenuItem>
                {formLogic?.optionsForSelects.mother.map((motherOption) => (
                  <MenuItem key={motherOption} value={motherOption}>
                    {formLogic?.getPersonName(motherOption)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="col-6 mb-4">
              <TextField
                select
                label={t('spouses')}
                disabled={formLogic?.disabled.spouses}
                value={formState.spouses}
                onChange={handleChangeFunctionOf('spouses')}
                size="small"
                fullWidth
                SelectProps={{
                  multiple: true,
                  renderValue: (selected: any[]) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={formLogic?.getPersonName(value)} />
                      ))}
                    </Box>
                  ),
                }}
              >
                {formLogic?.optionsForSelects.spouses.map((spousesOption) => (
                  <MenuItem key={spousesOption} value={spousesOption}>
                    {formLogic?.getPersonName(spousesOption)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-6 mb-4">
              <TextField
                select
                label={t('children')}
                disabled={formLogic?.disabled.children}
                value={formState.children}
                onChange={handleChangeFunctionOf('children')}
                size="small"
                fullWidth
                SelectProps={{
                  multiple: true,
                  renderValue: (selected: any[]) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={formLogic?.getPersonName(value)} />
                      ))}
                    </Box>
                  ),
                }}
              >
                {formLogic?.optionsForSelects.children.map((childrenOption) => (
                  <MenuItem key={childrenOption} value={childrenOption}>
                    {formLogic?.getPersonName(childrenOption)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
      </Modal.Body>
      {errorMessageKey && (
        <div className="family-tree-edit-form-error-msg">
          {t('cannotSave')}: {t(errorMessageKey)}
        </div>
      )}
      <Modal.Footer>
        <Button
          className="me-2"
          variant="contained"
          color="success"
          onClick={() => handleSaveClick()}
          disabled={!formLogic?.validate.realtime(formState)}
        >
          {t('save')}
        </Button>
        <Button variant="outlined" color="error" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getEmptyFormState(): FamilyTreeEditFormState {
  return {
    firstName: '',
    familyName: '',
    dateOfBirth: dayjs().format(FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT),
    gender: Gender.Male,

    father: '',
    mother: '',
    spouses: [],
    children: [],
  };
}

function deleteFalsyFieldsFrom(state: FamilyTreeEditFormState): FamilyTreeEditFormState {
  const newState = deepCopyOfSimpleObject(state);

  Object.keys(newState).forEach((key) => {
    if (!newState[key]) {
      delete newState[key];
    }
  });

  return newState;
}

interface EditProps {
  show: boolean;
  tree: FamilyTreeNode[];
  onCancel: () => void;
  onSave: (newTree: FamilyTreeNode[]) => void;
  formLogic: FamilyNodeEditLogic;
}
