import { useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { profileQuery } from './queries';
import {
  getToken,
  getUserId,
  getUserType,
  logOut,
  parseJwt,
  setProfileName,
  setRawProfileName,
} from '../../utils/api-client';
import routes from '../../route-definitions';
import { UserType } from '../../apiTypes/user';

function LoginRedirection() {
  const token = getToken();
  const userId = getUserId();
  const userType = getUserType();

  const isAdmin = userType === UserType.Admin || userType === UserType.Root;
  const { data, error } = useQuery(profileQuery, { variables: { userId }, skip: isAdmin });
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    const parsedToken = parseJwt(token);
    if (!isAdmin && parsedToken.AdministrationAgreementSigned === 'False') {
      navigate(routes.consentConfirmRoute.path, {
        replace: true,
        state: { userId, token, userType, email: state.email },
      });
      return;
    }

    if (error) {
      logOut().then(() => navigate(routes.landingRoute.path, { replace: true }));
      return;
    }

    if (!data) {
      return;
    }

    const { profile } = data.user;
    setProfileName(profile);
    navigate(routes.rootRoute.path, { replace: true });
  }, [token, data, error]);

  if (isAdmin) {
    setRawProfileName(state.email);
    return <Navigate to={routes.rootRoute.path} replace />;
  }

  return <Spinner />;
}

export default LoginRedirection;
