import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import dayjs from 'dayjs';
import { Profile, User, UserType } from '../apiTypes/user';
import Errors from '../features/xShared/Errors';

export type ClientType = ApolloClient<NormalizedCacheObject>;

const httpLink = createUploadLink({
  uri: '/api',
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      ApiClient.ErrorContainer?.addMessage(String(extensions?.code));
    });
  else if (networkError) ApiClient.ErrorContainer?.addMessage('networkError');
});

const ApiClient: { current: ApolloClient<NormalizedCacheObject>; ErrorContainer: Errors } = {
  current: null,
  ErrorContainer: null,
};

export function initializeClient() {
  ApiClient.current = new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache(),
  });
}

// for non-GraphQL calls, emulate regular fetch with inserted auth header
export function fetchWithAuth(query: RequestInfo, init?: RequestInit) {
  const token = getToken();
  const headers = { ...init?.headers, authorization: token ? `Bearer ${token}` : '' };
  return fetch(query, { ...init, headers });
}

export function getProfileName() {
  return localStorage.getItem('profileName');
}

export function getUserId(): number {
  return Number(localStorage.getItem('userId'));
}

export function getUserType(): string {
  return localStorage.getItem('userType');
}

export function isUserAdmin(): boolean {
  return [String(UserType.Admin), String(UserType.Root)].includes(localStorage.getItem('userType'));
}

export async function logOut() {
  localStorage.removeItem('userId');
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('profileName');
  localStorage.removeItem('realUserId');
  localStorage.removeItem('realProfileName');
  localStorage.removeItem('userType');
  await ApiClient.current.resetStore();
}

export function setProfileName(profile: Profile) {
  localStorage.setItem('profileName', `${profile.givenName} ${profile.familyName}`);
}

export function setRawProfileName(name: string) {
  localStorage.setItem('profileName', name);
}

export async function logIn(userId: string, token: string, userType: UserType) {
  if (localStorage.getItem('userId')) return;
  localStorage.setItem('userId', userId);
  localStorage.setItem('jwtToken', token);
  localStorage.setItem('userType', userType);
  await ApiClient.current.resetStore();
}

export async function maskUser(user: User, returnPath: string) {
  if (!localStorage.getItem('realUserId')) {
    localStorage.setItem('realUserId', localStorage.getItem('userId'));
    localStorage.setItem('realProfileName', getProfileName());
    localStorage.setItem('realUserType', getUserType());
  }
  localStorage.setItem('userId', String(user.id!));
  localStorage.setItem('maskReturnPath', returnPath);
  localStorage.setItem('realIsAdmin', localStorage.getItem('userType'));
  localStorage.setItem('userType', UserType.User);
  setProfileName(user.profile);
  await ApiClient.current.resetStore();
}

export async function unmaskUser() {
  const realId = localStorage.getItem('realUserId');
  if (!realId) return '.';
  localStorage.setItem('userId', realId);
  localStorage.setItem('profileName', localStorage.getItem('realProfileName'));
  localStorage.setItem('userType', localStorage.getItem('realUserType'));
  localStorage.removeItem('realUserId');
  localStorage.removeItem('realProfileName');
  const path = localStorage.getItem('maskReturnPath');
  localStorage.removeItem('maskReturnPath');
  await ApiClient.current.resetStore();
  return path;
}

export function isMasking(): boolean {
  return !!localStorage.getItem('realUserId');
}

export function getToken() {
  return localStorage.getItem('jwtToken');
}

export function getLoginExpirationTime() {
  const token = getToken();

  if (!token) return null;

  const decoded = parseJwt(token);

  const expDate = new Date(0);
  expDate.setUTCSeconds(decoded.exp);
  // !!! only for debug
  // expDate.setUTCSeconds(20);

  return dayjs(expDate);
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export default ApiClient;
