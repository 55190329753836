import { Button, ButtonToolbar, Col, FormCheck, Nav, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import KitDetailModal from './KitDetailModal';
import KitSearchModal from './KitSearchModal';
import KitsTab from './KitsTab';
import Paged from '../../xShared/Paged';
import LoadingIcon from '../../xShared/LoadingIcon';
import { Kit, KitQueryResult, KitStage } from '../../../apiTypes/kits';
import { kitsInStageQuery } from './queries';
import UploadAnalysedModal from './UploadAnalysedModal';

const DEFAULT_PAGE_SIZE = 21; // 21 labels fit on a standard page
const SAMPLE_PAGE_SIZE = 96; // 96 samples fit into one container

function KitViewPage() {
  const [stage, setStage] = useState<KitStage>(KitStage.Pending);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalKit, setInfoModalKit] = useState<Kit | undefined>(undefined);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const { t } = useTranslation(['kits', 'profile']);

  const { data, loading } = useQuery<KitQueryResult>(kitsInStageQuery, {
    variables: {
      take: pageSize,
      skip: page * pageSize,
      stage,
    },
    onCompleted: (d) => {
      setPageCount(Math.ceil(d.kits.totalCount / pageSize));
    },
  });

  const changeStage = (newStage: KitStage) => {
    setPage(0);
    // up to 96 samples fit into one container, so we use that when generating sequencer inputs
    setPageSize(newStage === 'RECEIVED' ? SAMPLE_PAGE_SIZE : DEFAULT_PAGE_SIZE);
    setStage(newStage);
    setSelectAllBox(false);
  };

  const changePage = (newPage: number) => {
    setPage(newPage);
    setSelectAllBox(false);
  };

  const handleOpenKitDetail = (kit: Kit) => {
    setInfoModalKit(kit);
    setInfoModalOpen(true);
  };

  return (
    <Col xs={9} className="ms-auto me-auto">
      <ButtonToolbar className="justify-content-center mt-5 mb-3">
        <Button variant="success" className="me-3" onClick={() => setSearchModalOpen(true)}>
          {t('searchKit')}
        </Button>
        <Button onClick={() => setUploadModalOpen(true)}>{t('uploadAnalysedTitle')}</Button>
      </ButtonToolbar>
      <Nav variant="tabs" defaultActiveKey="0">
        <Nav.Link eventKey="0" onClick={() => changeStage(KitStage.Pending)}>
          {t('stage.PENDING')}
        </Nav.Link>
        <Nav.Link eventKey="1" onClick={() => changeStage(KitStage.Sent)}>
          {t('stage.SENT')}
        </Nav.Link>
        <Nav.Link eventKey="2" onClick={() => changeStage(KitStage.Received)}>
          {t('stage.RECEIVED')}
        </Nav.Link>
        <Nav.Link eventKey="3" onClick={() => changeStage(KitStage.Analysed)}>
          {t('stage.ANALYSED')}
        </Nav.Link>
      </Nav>
      <KitsTab tab={stage} />
      {loading ? (
        <LoadingIcon />
      ) : (
        <Paged count={pageCount} onSetPage={changePage} current={page} surrounding={2}>
          <Table>
            <thead>
              <tr>
                <th aria-label="check all">
                  <FormCheck
                    id="kit-select-all-checkbox"
                    onClick={(e) => selectAll((e.target as HTMLInputElement).checked)}
                  />
                </th>
                <th>{t('profile:firstName')}</th>
                <th>{t('profile:lastName')}</th>
                <th>{t('kitNumber')}</th>
                <th>{t('info')}</th>
              </tr>
            </thead>
            <tbody>
              {data.kits.items.map((kit) => (
                <tr key={kit.id}>
                  <td>
                    <FormCheck type="checkbox" className="kit-select-checkbox" onClick={updateSelectAllBox} />
                  </td>
                  <td>{kit.user.profile.givenName}</td>
                  <td>{kit.user.profile.familyName}</td>
                  <td className="kit-id">{kit.id}</td>
                  <td>
                    <Button variant="secondary" onClick={() => handleOpenKitDetail(kit)}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>{' '}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paged>
      )}
      <KitDetailModal show={isInfoModalOpen} onHide={() => setInfoModalOpen(false)} kit={infoModalKit} />
      <KitSearchModal
        show={isSearchModalOpen}
        onHide={() => setSearchModalOpen(false)}
        onFindKit={handleOpenKitDetail}
      />
      <UploadAnalysedModal show={isUploadModalOpen} onHide={() => setUploadModalOpen(false)} />
    </Col>
  );
}

function selectAll(checked: boolean) {
  const checkBoxes = document.getElementsByClassName('kit-select-checkbox');

  Array.prototype.forEach.call(checkBoxes, (box: HTMLDivElement) => {
    box.querySelector('input').checked = checked;
  });
  setSelectAllBox(checked);
}

function updateSelectAllBox(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
  if (!(e.target as HTMLInputElement)?.checked) setSelectAllBox(false);
}

function setSelectAllBox(checked: boolean) {
  (document.getElementById('kit-select-all-checkbox') as HTMLInputElement).checked = checked;
}
export default KitViewPage;
