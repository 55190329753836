import { Alert, AlertTitle, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLoginExpirationTime, logOut } from '../../../utils/api-client';
import './LoginExpirationPopup.css';

const DISPLAY_ALERT_TIME_SEC = 60 * 60;
const LOGOUT_TIME_SEC = 5 * 60;

export default function LoginExpirationPopup() {
  const { t } = useTranslation('skeleton');
  const [ignored, setIgnored] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [timeToLogOutSec, setTimeToLogOutSec] = useState<number>();

  const waiter = useMemo(() => ({ logoutTimer: null, showAlertTimeout: null, timeTickTimeout: null }), []);

  useEffect(() => {
    clearTimeoutIfDefined(waiter.logoutTimer);
    clearTimeoutIfDefined(waiter.showAlertTimeout);

    const timeToLogOut = getSecondsToLoginExpiration();

    if (timeToLogOut < 0) {
      logOutAndNavigateHome();
    } else if (timeToLogOut < DISPLAY_ALERT_TIME_SEC) {
      setShowAlert(true);
      waiter.logoutTimer = setTimeout(() => logOutAndNavigateHome(), timeToLogOut * 1000);
    } else {
      waiter.showAlertTimeout = setTimeout(() => setShowAlert(true), (timeToLogOut - DISPLAY_ALERT_TIME_SEC) * 1000);
    }
  }, []);

  useEffect(() => {
    clearTimeoutIfDefined(waiter.timeTickTimeout);

    if (!showAlert) {
      return;
    }

    refreshTimeToExpire();

    function refreshTimeToExpire() {
      const secsToExpire = getSecondsToLoginExpiration();
      setTimeToLogOutSec(secsToExpire);

      if (secsToExpire < 0) {
        waiter.timeTickTimeout = null;
        logOutAndNavigateHome();
      }

      waiter.timeTickTimeout = setTimeout(refreshTimeToExpire, 200);
    }
  }, [showAlert, ignored]);

  const hours = Math.floor(timeToLogOutSec / 60 / 60);
  const minutes = Math.floor((timeToLogOutSec % (60 * 60)) / 60);
  const seconds = timeToLogOutSec % 60;

  const timeToLogOutCountDown = `${hours}:${formatTimePart(minutes)}:${formatTimePart(seconds)}`;
  return (
    <div className="login-expiration-container">
      {showAlert && (
        <>
          {!ignored && <div className="login-expiration-white-overlay" />}
          {ignored && (
            <div className="login-expiration-alert-smaller-container">
              <Alert className="login-expiration-alert-smaller" variant="filled" severity="warning">
                {t('loginExpiration.timeToForceLogout')}: {timeToLogOutCountDown}
              </Alert>
            </div>
          )}
          <Alert
            className={`pt-2 login-expiration-alert ${ignored ? 'ignored-exp-alert' : ''}`}
            variant="filled"
            severity="warning"
          >
            <AlertTitle>{t('loginExpiration.warning')}</AlertTitle>
            <div className="mt-3">{t('loginExpiration.youLoginIsAboutToExpire')}</div>
            <div className="mt-4">{t('loginExpiration.timeToForceLogout')}:</div>
            <div className="force-logout-timeout mt-1 mb-4">
              <b>{timeToLogOutCountDown}</b>
            </div>
            <div className="mt-3 mb-2">
              <Button variant="contained" color="success" className="me-2" onClick={() => logOutAndNavigateHome()}>
                {t('logout')}
              </Button>
              <Button variant="contained" color="error" onClick={() => setIgnored(true)}>
                {t('loginExpiration.ignore')}
              </Button>
            </div>
          </Alert>
        </>
      )}
    </div>
  );
}

function getSecondsToLoginExpiration() {
  const expirationDateTime = getLoginExpirationTime();
  const now = dayjs();

  const timeToLogOut = expirationDateTime.diff(now, 'seconds', false) - LOGOUT_TIME_SEC;
  return timeToLogOut;
}

function clearTimeoutIfDefined(timeout) {
  if (timeout) clearTimeout(timeout);
}

function formatTimePart(num: number) {
  return num < 10 ? `0${num}` : `${num}`;
}

function logOutAndNavigateHome() {
  logOut();
  window.location.href = '/';
}
