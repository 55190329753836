import { gql } from '@apollo/client';

export interface RawFamilyTreeIds {
  familyTreesOfUser: { id: number }[];
}

export const userFamilyTreesQuery = gql`
  query FamilyTreeOfUser($userId: Int!) {
    familyTreesOfUser(userId: $userId) {
      id
    }
  }
`;

export interface RawFamilyTree {
  familyTree: {
    id: string;
    personData: {
      givenName: string;
      familyName: string;
      birthDate: string;
      avatar: string;
      gender: string;
    };
    fatherId: string;
    motherId: string;
    children: string[];
    partners: string[];
  }[];
}

export const familyTreeQuery = gql`
  query FamilyTree($userId: Int!, $treeId: Int!) {
    familyTree(userId: $userId, treeId: $treeId) {
      id
      personData {
        givenName
        familyName
        birthDate
        avatar
        gender
      }
      fatherId
      motherId
      children
      partners
    }
  }
`;
