import './RegistrationUserInfoForm.css';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { RegistrationProps } from './types';
import { ButtonRow } from './Buttons';
import { stateUpdater, validateAndSubmit } from './utils';
import { municipalitiesQuery } from './queries';

function RegistrationUserInfoForm({ data, setData, onNext, onBack }: RegistrationProps) {
  const [validated, setValidated] = useState(false);
  const [municipalities, setMunicipalities] = useState<string[]>([]);
  const { loading } = useQuery(municipalitiesQuery, {
    onCompleted: (d) => {
      setMunicipalities(d.administrativeRegions.map((m) => m.name).sort());
    },
  });
  const { t } = useTranslation('registration');

  const handleChangeMunicipality = (newValue: { value: string }) => {
    setData((o) => o.cloneWith('municipality', newValue?.value ?? ''));
  };

  return (
    <Form noValidate validated={validated} onSubmit={validateAndSubmit(onNext, setValidated)}>
      <h3>{t('userInfo.title')}</h3>
      <Form.Group controlId="registration-name">
        <Form.Label>{t('userInfo.name')}</Form.Label>
        <Form.Control required name="name" value={data.name} onChange={stateUpdater('name', setData)} />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.surname')}</Form.Label>
        <Form.Control required name="surname" value={data.surname} onChange={stateUpdater('surname', setData)} />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.birthDate')}</Form.Label>
        <Form.Control
          required
          name="birthdate"
          type="date"
          value={data.birthdate}
          onChange={stateUpdater('birthdate', setData)}
        />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.phoneNumber')}</Form.Label>
        <Form.Control required name="phone" value={data.phone} onChange={stateUpdater('phone', setData)} />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>

      <h3 className="mt-4">{t('userInfo.address')}</h3>
      <Form.Group>
        <Form.Label>{t('userInfo.street')}</Form.Label>
        <Form.Control required name="street" value={data.street} onChange={stateUpdater('street', setData)} />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.town')}</Form.Label>
        <Form.Control required name="town" value={data.town} onChange={stateUpdater('town', setData)} />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.zipCode')}</Form.Label>
        <Form.Control
          required
          name="zipCode"
          inputMode="numeric"
          placeholder="12345"
          pattern="[0-9]{5}"
          value={data.zipCode}
          onChange={stateUpdater('zipCode', setData)}
        />
        <Form.Control.Feedback type="invalid">{t('validation.invalidZipCode')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('userInfo.municipality')}</Form.Label>
        <Select
          required
          isClearable
          name="municipalities"
          onChange={handleChangeMunicipality}
          isLoading={loading}
          options={municipalities.map((m) => ({ value: m, label: m }))}
        />
        <Form.Control.Feedback type="invalid">{t('validation.required')}</Form.Control.Feedback>
      </Form.Group>
      <ButtonRow onBackClick={onBack} />
    </Form>
  );
}

export default RegistrationUserInfoForm;
