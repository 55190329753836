import dayjs from 'dayjs';
import { clearArray, deepCopyOfSimpleObject, getRandomString, symmetricDifference } from '../../utils/js-helpers';
import familyTreeConfig from './config';
import {
  FamilyTreeNode,
  FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT,
  Flagged,
  Gender,
  NodeId,
  PersonData,
  Relations,
} from './models';

export function fixRelationsOf(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  removeAllRelationsInOtherNodesWith(node, tree);

  const { father, mother, children, spouses } = findNearestRelatives(node, tree);

  if (father) addChildrenRelationTo(father, node.id);
  if (mother) addChildrenRelationTo(mother, node.id);

  if (children) {
    children.forEach((child) => addParentRelationTo(child, node));
  }

  if (spouses) {
    spouses.forEach((spouse) => addSpouseRelationTo(spouse, node.id));
  }
}

function addParentRelationTo(child: FamilyTreeNode, parent: FamilyTreeNode) {
  if (parent.data.gender === Gender.Male) {
    child.rels.father = parent.id;
  } else {
    child.rels.mother = parent.id;
  }
}

function addChildrenRelationTo(parent: FamilyTreeNode, childId: NodeId) {
  if (!parent.rels.children) {
    parent.rels.children = [];
  }

  parent.rels.children.push(childId);
}

function addSpouseRelationTo(spouse: FamilyTreeNode, otherSpouseId: NodeId) {
  if (!spouse.rels.spouses) {
    spouse.rels.spouses = [];
  }

  spouse.rels.spouses.push(otherSpouseId);
}

export function remove(node: FamilyTreeNode, tree: FamilyTreeNode[]): FamilyTreeNode[] {
  const treeWithoutNode = tree.filter((n) => n.id !== node.id);

  removeAllRelationsInOtherNodesWith(node, treeWithoutNode);

  return removeUselessPlaceHolders(treeWithoutNode);
}

export function removeUselessPlaceHolders(tree: FamilyTreeNode[]): FamilyTreeNode[] {
  return tree.reduce((newTree, currNode) => {
    if (!isPlaceHolder(currNode)) return newTree;

    reconstructRelationsFromNearestRelatives(currNode, tree);

    const isUseless =
      currNode.rels.spouses.length === 0 || currNode.rels.children.length === 0 || currNode.rels.spouses.length === 0;

    return isUseless ? remove(currNode, newTree) : newTree;
  }, tree);
}

function reconstructRelationsFromNearestRelatives(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  const { mother, father, spouses, children } = findNearestRelatives(node, tree);

  clearArray(node.rels.children);
  clearArray(node.rels.spouses);
  delete node.rels.father;
  delete node.rels.mother;

  if (mother) fixRelationsOf(mother, tree);
  if (father) fixRelationsOf(father, tree);
  (spouses ?? []).forEach((s) => fixRelationsOf(s, tree));
  (children ?? []).forEach((ch) => fixRelationsOf(ch, tree));
}

export function hasBothParents(child: NodeId | FamilyTreeNode, tree: FamilyTreeNode[]) {
  const childNode = typeof child === 'string' ? find(child, tree) : child;

  return hasFather(childNode, tree) && hasMother(childNode, tree);
}

export function hasNoParents(child: NodeId | FamilyTreeNode, tree: FamilyTreeNode[]) {
  const childNode = typeof child === 'string' ? find(child, tree) : child;

  return !hasFather(childNode, tree) && !hasMother(childNode, tree);
}

export function hasFather(node: FamilyTreeNode, tree: FamilyTreeNode[]): boolean {
  const fatherNode = find(node.rels.father, tree);

  return fatherNode && !isPlaceHolder(fatherNode);
}

export function hasMother(node: FamilyTreeNode, tree: FamilyTreeNode[]): boolean {
  const motherNode = find(node.rels.mother, tree);

  return motherNode && !isPlaceHolder(motherNode);
}

export function breaksTreeIfRemoved(nodeToBeRemoved: FamilyTreeNode, tree: FamilyTreeNode[]) {
  const treeWithoutTheNode = tree.filter((n) => n.id !== nodeToBeRemoved.id);
  return !treeHasOneComponent(treeWithoutTheNode);
}

export function getInitialTree(): FamilyTreeNode[] {
  return [
    {
      id: getNewId([]),
      data: {
        firstName: 'John',
        familyName: 'Smith',
        avatar: familyTreeConfig.getAvatarFor(Gender.Male),
        dateOfBirth: dayjs().format(FAMILY_TREE_DATE_OF_BIRTH_DATETIME_FORMAT),
        gender: Gender.Male,
      },
      rels: {
        children: [],
        spouses: [],
      },
    },
  ];
}

export function treeHasOneComponent(tree: FamilyTreeNode[]) {
  const traversedTree = deepCopyOfSimpleObject(tree).filter((n) => !isPlaceHolder(n)) as Flagged<FamilyTreeNode>[];
  traversedTree.forEach((n) => {
    n.visited = false;
  });

  if (traversedTree.length <= 1) return true;

  visit(traversedTree[0]);
  return !treeHasMoreComponents(traversedTree);

  function visit(node: Flagged<FamilyTreeNode>) {
    const { father, mother, children, spouses } = findNearestRelatives(node, traversedTree);

    const nodesToVisit = [father, mother, ...children, ...spouses].filter((n) => n && !n.visited);

    nodesToVisit.forEach((n) => {
      n.visited = true;
    });

    nodesToVisit.forEach(visit);
  }

  function treeHasMoreComponents(t: Flagged<FamilyTreeNode>[]) {
    return t.some((n) => !n.visited);
  }
}

export function treeContainsNodeWhoseParentsAreNotSpouses(tree: FamilyTreeNode[]) {
  return tree
    .filter((node) => hasBothParents(node, tree))
    .some((node) => {
      const father = find(node.rels.father, tree);
      const mother = find(node.rels.mother, tree);

      return !areSpouses(mother, father);
    });
}

export function removePlaceholdersFrom(tree: FamilyTreeNode[]): FamilyTreeNode[] {
  const treeCopy = deepCopyOfSimpleObject(tree);

  return treeCopy.filter(isPlaceHolder).reduce((newTree, n) => remove(n, newTree), treeCopy);
}

function areSpouses(n1: FamilyTreeNode, n2: FamilyTreeNode) {
  return n1.rels?.spouses.includes(n2.id) && n1.rels?.spouses.includes(n2.id);
}

export function find(nodeId: NodeId, tree: FamilyTreeNode[]): FamilyTreeNode;
export function find(nodeIds: NodeId[], tree: FamilyTreeNode[]): FamilyTreeNode[];
export function find(nodeId: NodeId | NodeId[], tree: FamilyTreeNode[]): FamilyTreeNode | FamilyTreeNode[] {
  if (!nodeId) return null;

  let nodeIds: NodeId[];
  const inputIsArray = typeof nodeId === 'object';

  if (inputIsArray) {
    nodeIds = nodeId;
  } else {
    nodeIds = [nodeId];
  }

  const result = tree.filter((n) => nodeIds.includes(n.id));

  if (inputIsArray) {
    return result.length !== 0 ? result : null;
  }

  return result.length !== 0 ? result[0] : null;
}

export function getSpousesOf(node: NodeId | FamilyTreeNode, tree: FamilyTreeNode[]): NodeId[] {
  const treeNode = typeof node === 'string' ? find(node, tree) : node;
  return (treeNode.rels.spouses ?? []).filter((spouseId) => !isPlaceHolder(spouseId, tree));
}

function findNearestRelatives<TTreeNode extends FamilyTreeNode>(node: TTreeNode, tree: TTreeNode[]) {
  const father = find(node.rels.father, tree) as TTreeNode;
  const mother = find(node.rels.mother, tree) as TTreeNode;
  const children = (find(node.rels.children, tree) ?? []) as TTreeNode[];
  const spouses = (find(node.rels.spouses, tree) ?? []) as TTreeNode[];

  return { father, mother, children, spouses };
}

export function addNewNodeToTheTree(
  personData: PersonData,
  relations: Relations,
  tree: FamilyTreeNode[]
): FamilyTreeNode[] {
  const newNode = getNewNode(personData, tree);

  replaceRelations(newNode, relations);

  const newTree = [newNode, ...tree];
  fixRelationsOf(newNode, newTree);

  return removeUselessPlaceHolders(newTree);
}

export function isPlaceHolder(node: FamilyTreeNode);
export function isPlaceHolder(nodeId: NodeId, tree: FamilyTreeNode[]);
export function isPlaceHolder(node: FamilyTreeNode | NodeId, tree?: FamilyTreeNode[]) {
  let realNode: FamilyTreeNode;

  if (typeof node === 'object') {
    realNode = node;
  }

  if (typeof node === 'string') {
    realNode = find(node, tree);
  }

  return !!realNode.to_add;
}

export function getAllPeopleInSameSpouseCluster(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  const result = [];

  gatherSpouses(node.id);
  return result;

  function gatherSpouses(currNodeId: NodeId) {
    if (result.includes(currNodeId)) return;

    result.push(currNodeId);

    getSpousesOf(currNodeId, tree).forEach(gatherSpouses);
  }
}

export function updateNode(node: FamilyTreeNode, newData: PersonData, newRelations: Relations, tree: FamilyTreeNode[]) {
  const affectedNodes = getAffectedNodeIdsByUpdate(node, newRelations)
    .map((nodeId) => find(nodeId, tree))
    .filter((n) => !isPlaceHolder(n));

  replaceData(node, newData);
  replaceRelations(node, newRelations);

  fixRelationsOf(node, tree);
  affectedNodes.forEach((n) => fixRelationsOf(n, tree));
}

export function hasNamesake(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  return tree.some(
    (n) => n.id !== node.id && n.data.firstName === node.data.firstName && n.data.familyName === node.data.familyName
  );
}

export function getMainNode(tree: FamilyTreeNode[]) {
  return tree?.find((n) => n.main);
}

export function getFullName(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  const dateOfBirthString = hasNamesake(node, tree) ? `(${node.data.dateOfBirth})` : '';

  return `${node.data.firstName} ${node.data.familyName} ${dateOfBirthString}`.trim();
}

function getAffectedNodeIdsByUpdate(node: FamilyTreeNode, newRelations: Relations): NodeId[] {
  const affectedNodes = [
    ...symmetricDifference([node.rels.father], [newRelations.father]),
    ...symmetricDifference([node.rels.mother], [newRelations.mother]),
    ...symmetricDifference(node.rels.children ?? [], newRelations.children ?? []),
    ...symmetricDifference(node.rels.spouses ?? [], newRelations.spouses ?? []),
  ];

  return affectedNodes.filter((id) => !!id);
}

function getNewNode(data: PersonData, tree: FamilyTreeNode[]): FamilyTreeNode {
  return {
    id: getNewId(tree),
    rels: {
      children: [],
      spouses: [],
    },
    data,
  };
}

function getNewId(existingObjects: { id: NodeId }[]) {
  const existingIds = existingObjects.map((o) => o.id);
  let newId: string;

  do {
    newId = getRandomString();
  } while (existingIds.includes(newId));

  return newId;
}

function removeAllRelationsInOtherNodesWith(node: FamilyTreeNode, tree: FamilyTreeNode[]) {
  tree.forEach((n) => {
    if (n.rels.father === node.id) delete n.rels.father;
    if (n.rels.mother === node.id) delete n.rels.mother;

    if (n.rels.children) {
      n.rels.children = n.rels.children.filter((chId) => chId !== node.id);
    }
    if (n.rels.spouses) {
      n.rels.spouses = n.rels.spouses.filter((sId) => sId !== node.id);
    }
  });
}

function replaceData(node: FamilyTreeNode, newData: PersonData) {
  updateValue('firstName');
  updateValue('familyName');
  updateValue('dateOfBirth');
  updateValue('gender');

  function updateValue(key: keyof PersonData) {
    if (newData[key]) {
      node.data[key] = newData[key] as any;
    } else {
      delete node.data[key];
    }
  }
}

function replaceRelations(node: FamilyTreeNode, newRelations: Relations) {
  updateValue('mother');
  updateValue('father');

  updateArray('children');
  updateArray('spouses');

  function updateValue(key: keyof Relations) {
    if (newRelations[key]) {
      node.rels[key] = newRelations[key] as any;
    } else {
      delete node.rels[key];
    }
  }

  function updateArray(key: keyof Relations) {
    if (newRelations[key]) {
      node.rels[key] = [] as any;

      const arrayToUpdate = node.rels[key] as any[];

      (newRelations[key] as any[]).forEach((item) => arrayToUpdate.push(item));
    } else {
      delete node.rels[key];
    }
  }
}
