import { ReactNode } from 'react';
import { ArrowBack } from '@mui/icons-material';
import routes, { RouteElement } from '../../../route-definitions';
import { getUserType } from '../../../utils/api-client';
import { UserType } from '../../../apiTypes/user';

const UserVisibility = {
  onlyUser: [UserType.User],
  onlyAdmin: [UserType.Admin, UserType.Root],
  onlyRoot: [UserType.Root],
  onlyWorker: [UserType.Admin, UserType.Root, UserType.LabTech],
  all: [UserType.User, UserType.Admin, UserType.Root, UserType.LabTech],
};

export interface SideMenuItem {
  label: string;
  prefix?: ReactNode;
  routes: RouteElement[];
  visibility: UserType[];
  isAbsolute?: boolean;
  openInNewTab?: boolean;
  isHardLink?: boolean;
}

export const baseSideMenuItems: SideMenuItem[] = [
  {
    label: 'baseSideMenu.myData',
    routes: [routes.geneticDataView, routes.rootRoute],
    visibility: UserVisibility.onlyUser,
  },
  {
    label: 'baseSideMenu.relativesSearch',
    routes: [routes.relativesSearchRoute],
    visibility: UserVisibility.onlyUser,
  },
  {
    label: 'baseSideMenu.phyloTree',
    routes: [routes.phyloTreeRoute],
    visibility: UserVisibility.onlyUser,
    isHardLink: true,
  },
  {
    label: 'baseSideMenu.map',
    routes: [routes.mapRoute],
    visibility: UserVisibility.all,
  },
  {
    label: 'baseSideMenu.snpTracker',
    routes: [routes.snpTrackerRoute],
    visibility: UserVisibility.onlyUser,
    isAbsolute: true,
    openInNewTab: true,
  },
  {
    label: 'baseSideMenu.nevgen',
    routes: [routes.nevgenRoute],
    visibility: UserVisibility.onlyUser,
    isAbsolute: true,
    openInNewTab: true,
  },
  // disabled feature
  // {
  //   label: 'baseSideMenu.familyTree',
  //   routes: [routes.familyTreeRoute],
  //   visibility: UserVisibility.onlyUser,
  // },
  {
    label: 'baseSideMenu.userSearch',
    routes: [routes.adminSearchRoute],
    visibility: UserVisibility.onlyAdmin,
  },
  {
    label: 'baseSideMenu.logs',
    routes: [routes.loggingRoute],
    visibility: UserVisibility.onlyAdmin,
  },
  {
    label: 'baseSideMenu.geneticDataConflictResolution',
    routes: [routes.geneticDataConflictResolution],
    visibility: UserVisibility.onlyAdmin,
  },
  {
    label: 'baseSideMenu.kits',
    routes: [routes.adminKitViewRoute],
    visibility: UserVisibility.onlyAdmin,
  },
  {
    label: 'baseSideMenu.staffManagement',
    routes: [routes.staffManagement],
    visibility: UserVisibility.onlyRoot,
  },
  {
    label: 'baseSideMenu.projectInfo',
    routes: [routes.aboutPageRoute],
    visibility: UserVisibility.all,
    openInNewTab: true,
  },
];

export const userSideMenuItems: SideMenuItem[] = [
  { label: 'userSideMenu.backHome', routes: [routes.rootRoute], visibility: UserVisibility.all, prefix: <ArrowBack /> },
  {
    label: 'profile',
    routes: [routes.userFormRoute],
    visibility: UserVisibility.onlyUser,
  },
  {
    label: 'userSideMenu.changePwd',
    routes: [routes.changePasswordRoute],
    visibility: UserVisibility.all,
  },
  {
    label: 'userSideMenu.ancestorInfo',
    routes: [routes.ancestorFormRoute],
    visibility: UserVisibility.onlyUser,
  },
];

export function isMenuItemVisible(item: SideMenuItem) {
  return item.visibility.map((t) => String(t)).includes(getUserType());
}
