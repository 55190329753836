import './Tabs.css';

interface TabProps {
  content: any;
  onClick: () => void;
  isSelected: boolean;
}

interface Tab<TData> {
  key: string | number;
  label?: string;
  data: TData;
}

interface TabsProps<TData> {
  tabs: Tab<TData>[];
  selectedTabIndex: number;
  renderFunction?: React.FC<Tab<TData>>;
  hideTabs?: boolean;
  onFocusedChanged: (data: TData, index: number) => void;
}

function TabComponent({ content, isSelected, onClick }: TabProps) {
  return (
    <button type="button" className={`pt-2 tab ${isSelected ? '' : 'not-'}focused-tab`} onClick={() => onClick()}>
      {content}
    </button>
  );
}

export default function Tabs<TData>({
  tabs,
  selectedTabIndex,
  renderFunction,
  onFocusedChanged,
  hideTabs,
  children,
}: React.PropsWithChildren<TabsProps<TData>>) {
  function handleClickOnTab(tab: Tab<TData>, index: number) {
    onFocusedChanged(tab.data, index);
  }

  return (
    <>
      {hideTabs || (
        <div className="all-tabs-container">
          {tabs.map((t, i) => (
            <TabComponent
              key={t.key}
              content={renderFunction ? renderFunction(t) : t.label}
              isSelected={i === selectedTabIndex}
              onClick={() => handleClickOnTab(t, i)}
            />
          ))}
        </div>
      )}
      <div className="tabs-content">{children}</div>
    </>
  );
}
