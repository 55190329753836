import { ChangeEvent, ChangeEventHandler, Dispatch, FormEvent, FormEventHandler, SetStateAction } from 'react';
import { GeneticDataType, RegistrationData } from './types';
import { RegisterInput, RegistrationUserInput } from '../../apiTypes/registration';
import { Language } from '../../apiTypes/general';
import {
  registerWithSupportedProviderMutation,
  registerWithTestMutation,
  registerWithUnsupportedProviderMutation,
} from './queries';

type TargetProp = 'value' | 'checked';

export function stateUpdater<K extends keyof RegistrationData>(
  key: K,
  setter: Dispatch<SetStateAction<RegistrationData>>,
  targetProp: TargetProp = 'value'
): ChangeEventHandler {
  return (e: ChangeEvent<HTMLInputElement>) =>
    setter((o) => o.cloneWith(key, e.target[targetProp] as RegistrationData[K]));
}

export function validateAndSubmit(onSubmit: () => void, setValidated: (_: boolean) => void): FormEventHandler {
  return (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) onSubmit();
    setValidated(true);
  };
}

export function convertToQueryInput(data: RegistrationData, language: Language): RegisterInput {
  const user: RegistrationUserInput = {
    profile: {
      givenName: data.name,
      familyName: data.surname,
      birthDate: data.birthdate,
      email: data.email,
      phoneNumber: data.phone,
      residenceAddress: {
        street: data.street,
        town: data.town,
        zipCode: data.zipCode,
        municipality: data.municipality,
      },
      correspondenceAddress: {
        street: data.street,
        town: data.town,
        zipCode: data.zipCode,
      },
    },
    administrationAgreementSigned: data.consent,
    password: data.password,
    ancestor: {
      givenName: data.ancestorName,
      familyName: data.ancestorSurname,
      birthDate: data.ancestorBirthday,
      deathDate: data.ancestorDateOfDeath,
      address: {
        street: data.ancestorStreet,
        town: data.ancestorTown,
        zipCode: data.ancestorZipCode,
      },
    },
  };

  if (data.sendKit) {
    return { user, language };
  }

  const inputBase = { user, language, labTestRequested: false, strFile: data.file };

  if (data.dataType === GeneticDataType.Ftdna) {
    return inputBase;
  }

  return {
    ...inputBase,
    testProvider: { name: data.dataSource },
    strMarkers: data.markers.map((m) => ({ name: m.name, value: m.value ?? 0 })),
  };
}

export function chooseMutation(data: RegistrationData) {
  if (data.sendKit) return registerWithTestMutation;
  if (data.dataType === GeneticDataType.Ftdna) return registerWithSupportedProviderMutation;
  return registerWithUnsupportedProviderMutation;
}
