import './LogsFilter.css';
import { Button, FormControl, MenuItem, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FilterProps, RequestStatus } from './models';
import { getEndpointOptions } from './data-providers';

interface LogsFilterComponentProperties {
  onFilterClick: (filter: FilterProps) => void;
}

const FILTER_DATETIME_FORMAT = 'DD.MM.YY HH:mm:ss';
const INVALID_SELECT_OPTION = '';

export default function LogsFilter({ onFilterClick }: LogsFilterComponentProperties) {
  const { t } = useTranslation('logging');
  const [availableEndpointNames, setAvailableEndpointNames] = useState<string[]>();
  const [filterProps, setFilterProps] = useState<FilterProps>({});

  useEffect(() => {
    getEndpointOptions().then((endpoints) => setAvailableEndpointNames(endpoints));
  }, []);

  function handleFilterChanged(prop: keyof FilterProps, value: any) {
    setFilterProps((prev) => {
      const newProps = { ...prev };

      const realVal = value === '' ? null : value;
      newProps[prop] = realVal as never;

      return newProps;
    });
  }

  return (
    <div className="log-filter-window">
      <h3 className="logs-filter-header mt-4 mb-3">{t('filter')}</h3>
      <div className="logs-filter-row">
        <div className="logs-filter-outline">
          <div className="log-filter-outline-title">{t('performer')}</div>
          <div className="log-filter-input log-filter-input-email">
            <TextField
              id="outlined-basic"
              size="small"
              value={filterProps.performerEmail ?? ''}
              onChange={(val) => handleFilterChanged('performerEmail', val.target.value)}
              label={t('email')}
              variant="outlined"
            />
          </div>
        </div>

        <div className="logs-filter-outline">
          <div className="log-filter-outline-title">{t('affectedUser')}</div>

          <div className="log-filter-input log-filter-input-email">
            <TextField
              id="outlined-basic"
              size="small"
              value={filterProps.affectedUserEmail ?? ''}
              onChange={(val) => handleFilterChanged('affectedUserEmail', val.target.value)}
              label={t('email')}
              variant="outlined"
            />
          </div>
        </div>

        <div className="logs-filter-outline">
          <div className="log-filter-outline-title">{t('request')}</div>

          <div className="log-filter-input log-filter-request-id-input">
            <TextField
              id="outlined-basic"
              size="small"
              value={filterProps.requestId ?? ''}
              onChange={(val) => handleFilterChanged('requestId', val.target.value)}
              label={t('requestId')}
              variant="outlined"
            />
          </div>
          <div className="log-filter-input">
            <FormControl>
              <TextField
                select
                size="small"
                className="logs-filter-select-input"
                id="logs-filter-endpoint"
                value={filterProps.endpointName ?? INVALID_SELECT_OPTION}
                label={t('endpointName')}
                onChange={(val) =>
                  handleFilterChanged(
                    'endpointName',
                    val.target.value === INVALID_SELECT_OPTION ? null : val.target.value
                  )
                }
              >
                {!availableEndpointNames && (
                  <div className="logs-filter-spinner-container">
                    <Spinner />
                  </div>
                )}
                {availableEndpointNames && (
                  <MenuItem value={INVALID_SELECT_OPTION}>
                    <em>{t('none')}</em>
                  </MenuItem>
                )}
                {availableEndpointNames &&
                  availableEndpointNames.map((endpointName) => (
                    <MenuItem key={endpointName} value={endpointName}>
                      {endpointName}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </div>
          <div className="log-filter-input">
            <FormControl>
              <TextField
                select
                fullWidth
                size="small"
                className="logs-filter-select-input"
                id="logs-filter-status"
                value={filterProps.requestStatus ?? INVALID_SELECT_OPTION}
                label={t('requestStatus')}
                onChange={(val) =>
                  handleFilterChanged(
                    'requestStatus',
                    val.target.value === INVALID_SELECT_OPTION ? null : val.target.value
                  )
                }
              >
                <MenuItem value={INVALID_SELECT_OPTION}>
                  <em>{t('none')}</em>
                </MenuItem>
                <MenuItem value={RequestStatus.OK}>{t('OK')}</MenuItem>
                <MenuItem value={RequestStatus.FAIL}>{t('FAIL')}</MenuItem>
              </TextField>
            </FormControl>
          </div>
        </div>

        <div className="logs-filter-outline">
          <div className="log-filter-outline-title">{t('timestamp')}</div>

          <div className="log-filter-input">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t('from')}
                format={FILTER_DATETIME_FORMAT}
                onChange={(val) => handleFilterChanged('from', val)}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </LocalizationProvider>
          </div>
          <div className="log-filter-input">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t('to')}
                format={FILTER_DATETIME_FORMAT}
                onChange={(val) => handleFilterChanged('to', val)}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="logs-filter-row">
        <Button variant="outlined" size="large" onClick={() => onFilterClick({ ...filterProps })}>
          {t('filterVerb')}
        </Button>
      </div>
    </div>
  );
}
