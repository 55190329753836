import './RegistrationPage.css';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RegistrationUserInfoForm from './RegistrationUserInfoForm';
import RegistrationAncestorInfoForm from './RegistrationAncestorInfoForm';
import RegistrationKitTypeForm from './RegistrationKitTypeForm';
import RegistrationConsentForm from './RegistrationConsentForm';
import { RegistrationData, RegistrationForm } from './types';
import RegistrationConfirmationForm from './RegistrationConfirmationForm';
import RegistrationSidePanelText from './RegistrationSidePanelText';
import RegistrationLoginForm from './RegistrationLoginForm';
import ApiClient from '../../utils/api-client';

const STAGE_MAP: [string, RegistrationForm][] = [
  ['login', RegistrationLoginForm],
  ['user', RegistrationUserInfoForm],
  ['ancestor', RegistrationAncestorInfoForm],
  ['kit', RegistrationKitTypeForm],
  ['consent', RegistrationConsentForm],
  ['confirm', RegistrationConfirmationForm],
];

function RegistrationPage() {
  const [data, setData] = useState<RegistrationData>(new RegistrationData());
  const navigate = useNavigate();
  const { t } = useTranslation('confirm');

  function goToNext(i: number) {
    if (i === STAGE_MAP.length - 1) {
      return () => {
        ApiClient.ErrorContainer.addMessage(t('registrationSuccess'), 'success', false);
        navigate('/');
      };
    }
    return () => {
      navigate(`./${STAGE_MAP[i + 1][0]}`);
    };
  }

  function goBack(i: number) {
    if (i === 0) return () => navigate('/');
    return () => navigate(`./${STAGE_MAP[i - 1][0]}`);
  }

  return (
    <Container fluid>
      <Row className="justify-content-end">
        <Col sm={6} className="pt-5">
          <Container>
            <Col xs={8}>
              <Routes>
                {STAGE_MAP.map(([path, Page], i) => (
                  <Route
                    path={path}
                    element={<Page setData={setData} data={data} onNext={goToNext(i)} onBack={goBack(i)} />}
                  />
                ))}
              </Routes>
            </Col>
          </Container>
        </Col>
        <Col sm={4} className="px-5 pt-5 registration-text">
          <RegistrationSidePanelText />
        </Col>
      </Row>
    </Container>
  );
}

export function RegistrationBasePage() {
  return <Navigate to={`./${STAGE_MAP[0][0]}`} replace />;
}

export default RegistrationPage;
