import './RelativesSearch.css';
import { useQuery } from '@apollo/client';
import { Col, FormGroup, FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Alert } from '@mui/material';
import { getUserId } from '../../utils/api-client';
import LoadingIcon from '../xShared/LoadingIcon';
import { PublicUser } from '../../apiTypes/user';
import GeneticComparisonModal from './GeneticComparisonModal';
import { RelativeSearchResult, UserRelativeDistance } from '../../apiTypes/relativesSearch';
import ComparisonCard from './ComparisonCard';
import UserInfoModal from './UserInfoModal';
import closestRelativesQuery from './queries';
import { HaplogroupSelect, SelectValue } from '../xShared/HaplogroupSelect';

interface ComparisonQueryData {
  closestStrRelatives: RelativeSearchResult;
}

interface Props {
  selectedMarkerSet: string;
  limitToOwnSurname: boolean;
  searchSubtree?: boolean;
}

function RelativesSearch({ selectedMarkerSet, limitToOwnSurname, searchSubtree }: Props) {
  const userId = getUserId();
  const { t } = useTranslation(['relatives', 'map']);
  const [isEmailOpen, setEmailOpen] = useState(false);
  const [selectedEmailUser, setSelectedEmailUser] = useState<PublicUser>(null);
  const [isDetailOpen, setDetailOpen] = useState(false);
  const [selectedComparison, setSelectedComparison] = useState<UserRelativeDistance>(null);
  const [selectedHaplogroup, setSelectedHaplogroup] = useQueryParam('hg', NumberParam);

  const { data: comparisonData, loading } = useQuery<ComparisonQueryData>(closestRelativesQuery, {
    skip: !selectedMarkerSet,
    variables: {
      userId,
      markerSet: selectedMarkerSet,
      ownFamily: limitToOwnSurname,
      forHaplogroup: selectedHaplogroup,
      isSubtree: searchSubtree,
    },
  });

  const comparisonUsers = comparisonData?.closestStrRelatives.userRelativeDistances ?? [];

  const openEmail = (comparison: UserRelativeDistance) => {
    setSelectedEmailUser(comparison.user);
    setEmailOpen(true);
  };

  const openDetail = (comparison: UserRelativeDistance) => {
    setSelectedComparison(comparison);
    setDetailOpen(true);
  };

  const handleSelect = (selected: SelectValue | null) => {
    setSelectedHaplogroup(selected ? Number(selected.value) : null);
  };

  return (
    <>
      <FormGroup className="mt-2 ms-2 mb-5">
        <FormLabel column="sm" sm="auto">
          <h5 className="header">{t('map:haplogroup')}</h5>
        </FormLabel>
        <div className="relative-search-haplogroup-select-container">
          <HaplogroupSelect onSelect={handleSelect} haplogroupId={selectedHaplogroup} />
        </div>
      </FormGroup>
      {loading && <LoadingIcon className="mt-4" />}
      {comparisonUsers.length > 0
        ? comparisonUsers.map((cu) => (
            <ComparisonCard key={cu.user.profile.email} comparison={cu} onInfo={openEmail} onDetail={openDetail} />
          ))
        : !loading && (
            <Col xs={12} className="text-center mt-5">
              <Alert className="pt-3 pb-3" severity="warning">
                {t('noMatches')}
              </Alert>
            </Col>
          )}
      {isEmailOpen && <UserInfoModal show onHide={() => setEmailOpen(false)} user={selectedEmailUser} />}
      {isDetailOpen && (
        <GeneticComparisonModal
          show
          handleClose={() => setDetailOpen(false)}
          currentUser={comparisonData.closestStrRelatives.userMarkerSetValues}
          comparison={selectedComparison}
          markers={comparisonData.closestStrRelatives.markerSet.markers}
        />
      )}
    </>
  );
}

export default RelativesSearch;
