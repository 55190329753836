import graphSettings from '../graph-settings';

export function childrenAccessor(node: Node) {
  return node.children.filter((ch) => ch.visible);
}

export function compareNodes(n1: Node, n2: Node) {
  return n1.name > n2.name ? 1 : -1;
}

export function foreachInTree(node: Node, callback: (n: Node) => void) {
  callback(node);
  node.children.forEach((ch) => foreachInTree(ch, callback));
}

export function isExpandable(node: Node) {
  const hiddenChildExists = node.children.some((ch) => !ch.visible);
  return hiddenChildExists;
}

export function expandNode(node: Node) {
  node.children.forEach((n) => {
    n.visible = true;
    if (n.children.length <= 1) {
      expandNode(n);
    }
  });
}

export function expandEntireTree(node: Node) {
  foreachInTree(node, (n) => {
    n.visible = true;
  });
}

export function collapseEntireTree(node: Node) {
  foreachInTree(node, (n) => {
    n.visible = false;
  });
  node.visible = true;
}

export function countChildren(node: Node) {
  return node.children.reduce((acc, ch) => 1 + acc + countChildren(ch), 0);
}

export function contractNode(node: Node) {
  node.children.forEach((n) => {
    n.visible = false;
  });
}

export function getHaplogroupRoot(node: Node) {
  if (!node) return null;

  if (node.isHaplogroupRoot) return node;

  return getHaplogroupRoot(node.parent);
}

export function getRoot(node: Node): Node {
  if (!node) return null;

  if (!node.parent) return node;

  return getRoot(node.parent);
}

export function find(node: Node, predicate: (n: Node) => boolean): Node | null {
  if (predicate(node)) return node;

  // eslint-disable-next-line no-restricted-syntax
  for (const child of node.children) {
    const foundNode = find(child, predicate);

    if (foundNode) {
      return foundNode;
    }
  }

  return null;
}

export function getHaplogroupRoots(node: Node): Node[] {
  return node.children.reduce((acc, n) => {
    if (n.isHaplogroupRoot) {
      acc.push(n);
    } else {
      acc = acc.concat(getHaplogroupRoots(n));
    }

    return acc;
  }, []);
}

export function showNeighborhoodOf(node: Node) {
  function show(currNode: Node, prevNode: Node, depth: number) {
    if (!currNode) return;

    currNode.visible = true;

    if (currNode.children.length === 1 && prevNode !== currNode.children[0]) {
      show(currNode.children[0], currNode, depth - 1);
      return;
    }

    if (depth <= 0) return;

    if (currNode.parent !== prevNode) show(currNode.parent, currNode, depth - 1);

    currNode.children.filter((ch) => ch !== prevNode).forEach((ch) => show(ch, currNode, depth - 1));
  }

  function showPathToRoot(n: Node) {
    if (!n) return;

    n.visible = true;

    showPathToRoot(n.parent);
  }

  show(node, null, graphSettings.neighborhoodDepth);
  showPathToRoot(node);
}

export function areInSameSubtree(n1: Node, n2: Node) {
  if (!n1 || !n2) return false;

  return getHaplogroupRoot(n1) === getHaplogroupRoot(n2);
}

export interface Node {
  id: number;
  haplogroupId: number;
  parent: Node;
  name: string;
  children: Node[];
  visible: boolean;
  count: {
    inside: number;
    inSubtree: number;
  };
  usersSample: string[];
  color: string;
  isHaplogroupRoot: boolean;
  approximateYear: number;
  isMain: boolean;
}
