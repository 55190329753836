const graphSettings = {
  nodeDisplacement: 120,
  enterAnimationDuration: 300, // ms
  exitAnimationDuration: 300, // ms

  neighborhoodDepth: 5,

  initialScale: 0.8,

  styles: {
    labels: {
      lightColor: 'white',
      darkColor: 'black',
      luminanceThreshold: 0.4,
      estimateYearFontSize: 13,
      yOffsets: {
        haplogroupName: -5,
        estimateYear: 20,
      },
    },
    links: {
      fill: 'none',
      stroke: {
        color: '#555',
        opacity: 0.4,
        width: 1.5,
      },
    },
    graph: {
      separation: {
        siblings: 1,
        others: 1.5,
      },
    },
    nodes: {
      haploGroupColors: [
        '#e6194b',
        '#3cb44b',
        '#ffe119',
        '#4363d8',
        '#f58231',
        '#911eb4',
        '#46f0f0',
        '#f032e6',
        '#bcf60c',
        '#fabebe',
        '#dcbeff',
        '#800000',
        '#ffd8b1',
        '#000075',
      ],
      default: {
        scale: 0.7,
        color: 'white',
        transition: { duration: 200 },
        r: 55,
        stroke: {
          color: 'rgb(230, 57, 70)',
          width: 1,
        },
      },
      expandable: {
        scale: 0.8,
        transition: { duration: 200 },
        r: 60,
        stroke: {
          color: '#292b59',
          width: 10,
        },
      },
      withPeopleInside: {
        borderCircle: {
          r: 60,
          fill: 'transparent',
          stroke: {
            color: 'rgb(230, 57, 70)',
            with: 3,
          },
        },
        scale: 1,
      },
      main: {
        pulsingCircle: {
          r: 70,
          rAfterExpansion: 90,
          fill: 'transparent',
          stroke: {
            color: 'rgb(230, 57, 70)',
            width: 5,
          },
          animationPeriod: 500,
        },
      },
      root: {
        radiusAnimation: {
          duration: 700,
          minR: 80,
          maxR: 100,
        },
        stroke: {
          color: 'rgb(230, 57, 70)',
          width: 10,
        },
      },
    },
  },
};

export default graphSettings;
