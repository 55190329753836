import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { DocumentNode } from 'graphql/language';
import { FormEvent, useState } from 'react';
import { GeneticDataType, RegistrationData, RegistrationProps } from './types';
import { ButtonRow } from './Buttons';
import ApiClient from '../../utils/api-client';
import { RegisterInput } from '../../apiTypes/registration';
import { chooseMutation, convertToQueryInput } from './utils';
import { Language } from '../../apiTypes/general';
import LoadingIcon from '../xShared/LoadingIcon';

function getGeneticTestSummary(data: RegistrationData, t: TFunction<'registration'>) {
  if (data.sendKit) {
    return t('confirmation.sendKit');
  }
  if (data.dataType === GeneticDataType.Ftdna) {
    return t('confirmation.uploadFtdna');
  }
  if (data.dataType === GeneticDataType.Other) {
    return t('confirmation.uploadOther');
  }
  throw new Error('Unable to determine kit type from registration data.');
}

function sendRegistration(mutation: DocumentNode, input: RegisterInput) {
  return ApiClient.current.mutate({ mutation, variables: { input } });
}

function RegistrationConfirmationForm({ data, onNext, onBack }: RegistrationProps) {
  const { t, i18n } = useTranslation('registration');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const mutation = chooseMutation(data);
    const input = convertToQueryInput(data, i18n.language === 'cs' ? Language.Czech : Language.English);
    sendRegistration(mutation, input)
      .then((result) => {
        setLoading(false);
        if (result.data) onNext();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <h2>{t('confirmation.title')}</h2>
      <Form onSubmit={handleSubmit}>
        <h4>{t('userInfo.title')}</h4>
        <Form.Control plaintext value={`${data.name} ${data.surname}`} />
        <Form.Control plaintext value={data.birthdate} />
        <Form.Control plaintext value={data.phone} />
        <Form.Control plaintext value={data.email} />
        <Form.Control plaintext value={`${data.street}, ${data.zipCode} ${data.town}`} />
        <Form.Control plaintext value={data.municipality} />
        <Form.Control plaintext value={getGeneticTestSummary(data, t)} />
        <h4>{t('ancestorInfo.title')}</h4>
        <Form.Control plaintext value={`${data.ancestorName ?? ''} ${data.ancestorSurname ?? ''}`} />
        <Form.Control plaintext value={data.ancestorBirthday} />
        <Form.Control plaintext value={data.ancestorDateOfDeath} />
        <Form.Control
          plaintext
          value={`${data.ancestorStreet ?? ''}, ${data.ancestorZipCode ?? ''} ${data.ancestorTown ?? ''}`}
        />
        {isLoading ? <LoadingIcon /> : <ButtonRow onBackClick={onBack} finalStage />}
      </Form>
    </div>
  );
}

export default RegistrationConfirmationForm;
