import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import './ErrorAlert.css';

interface Props {
  messages: string[];
}

export default function ErrorAlert({ messages }: Props) {
  if (!messages || messages.length === 0) return null;

  const [displayedMessages, setDisplayedMessages] = useState<string[]>([]);

  useEffect(() => {
    setDisplayedMessages([...messages]);
  }, [messages]);

  function removeIthMessage(index: number) {
    setDisplayedMessages(displayedMessages.filter((_, i) => i !== index));
  }

  return (
    <div className="error-alert-container">
      {displayedMessages.map((message, i) => (
        <Alert
          key={message}
          className="error-alert"
          onClose={() => removeIthMessage(i)}
          variant="filled"
          severity="error"
        >
          {message}
        </Alert>
      ))}
    </div>
  );
}
