import { UserType } from '../../apiTypes/user';
import ApiClient from '../../utils/api-client';
import Staff from './models';
import { addStaffMutation, deleteStaffMutation } from './mutations';
import { getStaffQuery, RawStaff } from './queries';

export function createStaff(email: string, role: UserType): Promise<unknown> {
  return ApiClient.current.mutate({
    mutation: addStaffMutation,
    variables: {
      email,
      role,
    },
  });
}

export function deleteStaff(staffEmail: string): Promise<unknown> {
  return ApiClient.current.mutate({
    mutation: deleteStaffMutation,
    variables: {
      staffEmail,
    },
  });
}

export async function getStaff(): Promise<Staff[]> {
  const response = await ApiClient.current.query<RawStaff>({
    query: getStaffQuery,
    fetchPolicy: 'no-cache',
  });

  return response.data.staff.map((raw) => ({
    id: raw.id,
    email: raw.profile.email,
    role: raw.adminType,
  }));
}
