/* eslint-disable  jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-static-element-interactions */
import './LanguageSwitch.css';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import czeIcon from '../../../pictures/cze-icon.png';
import engIcon from '../../../pictures/eng-icon.png';

const langs = ['cs', 'en'];
const LANG_KEY = '__LANG__';

function loadSavedLang() {
  return localStorage.getItem(LANG_KEY) ?? langs[0];
}

function saveLang(lang) {
  localStorage.setItem(LANG_KEY, lang);
}

export default function LanguageSwitch() {
  const [lang, setLang] = useState(loadSavedLang());
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  function toggleLang() {
    const newLang = langs.filter((l) => l !== lang)[0];

    saveLang(newLang);
    setLang(newLang);
  }

  const langProps = {
    cs: { class: 'lang-cze', icon: czeIcon },
    en: { class: 'lang-eng', icon: engIcon },
  }[lang];

  return (
    <div className={`language-switch-background ${langProps.class}`} onClick={toggleLang}>
      <div className="language-switch-toggle" style={{ backgroundImage: `url(${langProps.icon})` }} />
      <div className="cols-container">
        <div className="eng-back-col" />
        <div className="cze-back-col" />
      </div>
    </div>
  );
}
