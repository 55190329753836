import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Modal, Spinner } from 'react-bootstrap';
import { areInSameSubtree, find, getHaplogroupRoot, getRoot, Node } from './models/graph';
import './HaploGroupInfo.css';
import UserOfHaplogroup from './models/users';
import { getUserOfHaplogroup, getUsersHaploGroup } from './data-providers';
import { getUserId } from '../../utils/api-client';

export default function HaplogroupInfo({ node, onClose }: { node: Node; onClose: () => void }) {
  const [displayedUsers, setDisplayedUsers] = useState<UserOfHaplogroup[]>();
  const [loadingUsers, setLoadingUsers] = useState<boolean>();
  const [usersHaplogroup, setUsersHaploGroup] = useState<Node>();
  const [loadingHaploGroup, setLoadingHaploGroup] = useState(true);

  const { t } = useTranslation('phylogenetic-tree');

  const baseNode = getHaplogroupRoot(node);

  useEffect(() => {
    if (!node) return;

    getUsersHaploGroup(getUserId())
      .then((haplogroupFromServer) => {
        if (!haplogroupFromServer) return;

        const treeRoot = getRoot(node);
        const usersNode = find(treeRoot, (n) => n.haplogroupId === haplogroupFromServer.id);

        setUsersHaploGroup(usersNode);
      })
      .finally(() => setLoadingHaploGroup(false));
  }, [node]);

  useEffect(() => {
    if (!node || loadingHaploGroup) {
      setDisplayedUsers(null);
      return;
    }

    setLoadingUsers(true);

    if (areInSameSubtree(node, usersHaplogroup)) {
      getUserOfHaplogroup(node.id)
        .then(setDisplayedUsers)
        .finally(() => setLoadingUsers(false));
    } else {
      setLoadingUsers(false);
    }
  }, [node, loadingHaploGroup, usersHaplogroup]);

  return (
    <Modal show={!!node} size="lg">
      <Modal.Header>
        <h3 className="header mt-2">{t('infoAboutNode')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="haplo-group-info-container mt-2 container">
          <div>
            <h5 className="header">{t('haplogroupName')}</h5>
            <p>{node?.name}</p>
          </div>
          <div>
            <h5 className="header">{t('baseHaplogroup')}</h5>
            <p>{baseNode?.name ?? '--'}</p>
          </div>
          <div>
            <h5 className="header">{t('approximateYear')}</h5>
            <p>{node?.approximateYear.toLocaleString('en').replace(',', ' ')}</p>
          </div>
          <div>
            <h5 className="header">{t('numberOfPeople')}</h5>
            <p>{node?.count.inside}</p>
          </div>
          <div>
            <h5 className="header">{t('numberOfPeopleInSubtree')}</h5>
            <p>{node?.count.inSubtree}</p>
          </div>
        </div>

        <div className="haplo-info-user-table-container">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="log-table-title-row">
                  <TableCell> {t('name')}</TableCell>
                  <TableCell align="right">{t('email')}</TableCell>
                  <TableCell align="right">{t('municipality')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingUsers && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <div className="haplo-group-info-table-full-row">
                        <Spinner />
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {!loadingUsers && !displayedUsers && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <div className="haplo-group-info-table-full-row">
                        <Alert severity="error" className="haplo-info-alert">
                          {t('youCannotDisplayUsersOfThisNode')
                            .split('\n')
                            .map((line) => (
                              <div key={line}>{line}</div>
                            ))}
                        </Alert>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {!loadingUsers && displayedUsers && displayedUsers.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <div className="haplo-group-info-table-full-row">
                        <Alert severity="warning" className="haplo-info-alert">
                          {t('noUsersInNode')}
                        </Alert>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {displayedUsers &&
                  displayedUsers.length !== 0 &&
                  displayedUsers.map((user, i) => (
                    <TableRow className="haplo-detail-table-row" key={user.email + user.name + getKeyFromIndex(i)}>
                      <TableCell component="th" scope="row">
                        {user.name || <FontAwesomeIcon className="haplo-detail-eye-slash" icon={faEyeSlash} />}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {user.email || <FontAwesomeIcon className="haplo-detail-eye-slash" icon={faEyeSlash} />}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {user.municipality || <FontAwesomeIcon className="haplo-detail-eye-slash" icon={faEyeSlash} />}
                      </TableCell>
                      {(!user.email || !user.municipality) && (
                        <div className="haplo-detail-table-empty-column-explanation-container">
                          <Alert severity="warning" className="haplo-detail-table-empty-column-explanation-alert">
                            {t('cannotDisplayUsersInfoAsDidNotSignedConsent')}
                          </Alert>
                        </div>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="error" variant="outlined" onClick={onClose}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getKeyFromIndex(i: number) {
  return `${i}`;
}
