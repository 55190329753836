import ApiClient from '../../utils/api-client';
import {
  addEditQuery,
  AddEditResult,
  addViewQuery,
  AddViewResult,
  removeEditQuery,
  RemoveEditResult,
  removeViewQuery,
  RemoveViewResult,
} from './api-mutations';
import {
  editRightsQuery,
  RawEditUserRights,
  RawUserForRights,
  RawViewUserRights,
  viewRightsQuery,
} from './api-queries';
import { UserReference, UsersRights } from './models';

export default async function fetchRights(userId: number): Promise<UsersRights> {
  const viewRightsPromise = ApiClient.current.query<RawViewUserRights>({
    query: viewRightsQuery,
    variables: {
      userId,
    },
  });

  const editRightsPromise = ApiClient.current.query<RawEditUserRights>({
    query: editRightsQuery,
    variables: {
      userId,
    },
  });

  const [viewRights, editRights] = await Promise.all([viewRightsPromise, editRightsPromise]);

  return {
    withViewRight: viewRights.data.userViewSettings.viewers.map(fromRawUser),
    canView: viewRights.data.userViewSettings.viewableUsers.map(fromRawUser),

    withEditRight: editRights.data.userManagementSettings.managers.map(fromRawUser),
    canEdit: editRights.data.userManagementSettings.managedUsers.map(fromRawUser),
  };
}

export async function addViewRight(userId: number, viewerEmail: string): Promise<UsersRights> {
  const response = await ApiClient.current.mutate<AddViewResult>({
    mutation: addViewQuery,
    variables: {
      userId,
      viewerEmail,
    },
  });

  return { withViewRight: response.data.addUserViewer.viewers.map(fromRawUser) };
}

export async function removeEditRight(userId: number, editorId: number): Promise<UsersRights> {
  const response = await ApiClient.current.mutate<RemoveEditResult>({
    mutation: removeEditQuery,
    variables: {
      userId,
      editorId,
    },
  });

  return { withEditRight: response.data.removeUserManager.managers.map(fromRawUser) };
}

export async function removeViewRight(userId: number, viewerId: number): Promise<UsersRights> {
  const response = await ApiClient.current.mutate<RemoveViewResult>({
    mutation: removeViewQuery,
    variables: {
      userId,
      viewerId,
    },
  });

  return { withViewRight: response.data.removeUserViewer.viewers.map(fromRawUser) };
}

export async function addEditRight(userId: number, editorEmail: string): Promise<UsersRights> {
  const response = await ApiClient.current.mutate<AddEditResult>({
    mutation: addEditQuery,
    variables: {
      userId,
      editorEmail,
    },
  });

  return { withEditRight: response.data.addUserManager.managers.map(fromRawUser) };
}

function fromRawUser(rawUser: RawUserForRights): UserReference {
  return {
    id: rawUser.id,
    name: `${rawUser.profile.givenName} ${rawUser.profile.familyName}`,
    email: rawUser.profile.email,
  };
}
