import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useMutation } from '@apollo/client';
import { Button, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OperationResult } from '../../apiTypes/general';
import { changeEmailMutation } from './queries';
import useMount from '../../hooks/useMount';
import LoadingIcon from '../xShared/LoadingIcon';
import { logOut } from '../../utils/api-client';
import routes from '../../route-definitions';

function ConfirmEmailChange() {
  const [userId] = useQueryParam('userId', NumberParam);
  const [token] = useQueryParam('token', StringParam);
  const [newEmail] = useQueryParam('newEmail', StringParam);
  const { t } = useTranslation('confirm');

  const [confirmChange, { loading, data, error }] = useMutation<{ changeEmail: OperationResult }>(changeEmailMutation, {
    variables: {
      userId,
      token,
      newEmail,
    },
  });

  useMount(() => {
    if (loading) return;
    confirmChange().then((response) => {
      if (!response.errors && response.data.changeEmail.operationSuccessful) {
        logOut();
      }
    });
  });

  if (!userId || !token || !newEmail || error || (!loading && data && !data.changeEmail.operationSuccessful))
    return (
      <Container>
        <Col sm={6}>
          <h3>Something went wrong</h3>
          <p>The operation could not be completed.</p>
        </Col>
      </Container>
    );

  if (loading) return <LoadingIcon className="mt-5 min-vw-100" />;

  return (
    <Container>
      <Col sm={6}>
        <h3>{t('success')}</h3>
        <p>{t('emailChanged', { newEmail })}</p>
        <p>{t('goHome')}</p>
        <Link to={routes.rootRoute.path}>
          <Button variant="secondary">{t('home')}</Button>
        </Link>
      </Col>
    </Container>
  );
}

export default ConfirmEmailChange;
