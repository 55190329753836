import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Accordion, Button, ButtonToolbar, Form, FormControl, FormGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import LoadingIcon from '../../xShared/LoadingIcon';
import { maskUser } from '../../../utils/api-client';
import routes from '../../../route-definitions';
import { haplogroupQuery, userSearchQuery } from './queries';
import { EmptyFilterInput, OperationFilterInput } from '../../../apiTypes/general';
import { CollectionSegment } from '../../../apiTypes/collectionSegment';
import { User } from '../../../apiTypes/user';
import Paged from '../../xShared/Paged';
import userSearchToCsv from './userSearchToCsv';
import downloadBlob from '../../../utils/downloadBlob';

const PAGE_SIZE = 20; // seems like a good default
function UserSearchPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('admin');
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [familyName, setFamilyName] = useState<string>('');
  const [haplogroup, setHaplogroup] = useState<{ id: number; name: string }[]>([]);
  const [isMasking, setMasking] = useState(false);
  // values in filter form may be different from actual filter (e.g. when changing page with non-applied filter)
  const [filter, setFilter] = useState<{
    nameFilter: OperationFilterInput<string>;
    hgFilter: OperationFilterInput<number>;
  }>({ nameFilter: EmptyFilterInput, hgFilter: EmptyFilterInput });
  const { data: hgData, loading: hgLoading } = useQuery(haplogroupQuery);
  /* eslint-disable-next-line prefer-const */ // loading is const, but we reassign data
  let [getUsers, { data, loading }] = useLazyQuery<{ users: CollectionSegment<User> }>(userSearchQuery, {
    onCompleted: ({ users }) => setPageCount(Math.ceil(users.totalCount / PAGE_SIZE)),
    variables: {
      familyNames: filter.nameFilter,
      haplogroups: filter.hgFilter,
      skip: PAGE_SIZE * page,
      take: PAGE_SIZE,
    },
  });

  useEffect(() => {
    getUsers();
  }, [filter, page]);

  if (hgLoading) return <LoadingIcon />;
  if (!data) data = { users: { items: [], totalCount: 0 } };

  const handleUserClick = async (user: User) => {
    setMasking(true);
    await maskUser(user, routes.adminSearchRoute.path);
    setMasking(false);
    navigate(routes.userFormRoute.path);
  };

  const handleHaplogroupSelect = (newValue: { id: number; name: string }[]) => {
    setHaplogroup(newValue);
  };

  const handleSearchClick = () => {
    const nameFilter: OperationFilterInput<string> = familyName.length > 0 ? { eq: familyName } : EmptyFilterInput;
    const hgFilter: OperationFilterInput<number> =
      haplogroup.length > 0 ? { in: haplogroup.map((hg) => hg.id) } : EmptyFilterInput;
    setFilter({ hgFilter, nameFilter });
    setPage(0); // resetting the page will fetch users with the new filter applied
  };

  const handleDownloadClick = async () => {
    // collect all users
    const allItems: User[] = [];
    let hasNextPage = true;
    while (hasNextPage) {
      // eslint-disable-next-line no-await-in-loop
      const segment = await getUsers({ variables: { skip: allItems.length, take: 100 } });
      allItems.push(...segment.data.users.items);
      hasNextPage = segment.data.users.pageInfo.hasNextPage;
    }
    // convert to CSV
    const csvString = userSearchToCsv(allItems);
    // download CSV
    const blob = new Blob([csvString], { type: 'text/csv' });
    downloadBlob(blob, 'userSearch.csv');
  };

  return (
    <>
      <Accordion defaultActiveKey="0" className="my-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('filter')}</Accordion.Header>
          <Accordion.Body>
            <FormGroup>
              <Form.Label>{t('lastName')}</Form.Label>
              <FormControl type="input" value={familyName} onChange={(e) => setFamilyName(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Form.Label>{t('haplogroup')}</Form.Label>
              <Select
                isMulti
                options={hgData.haplogroups}
                onChange={handleHaplogroupSelect}
                getOptionLabel={(hg) => hg.name}
                getOptionValue={(hg) => String(hg.id)}
              />
            </FormGroup>
            <ButtonToolbar className="d-flex justify-content-between mt-3">
              <Button variant="success" onClick={handleSearchClick}>
                {t('searchUsers')}
              </Button>
              <Button onClick={handleDownloadClick}>Stáhnout výsledek jako CSV</Button>
            </ButtonToolbar>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Paged current={page} count={pageCount} onSetPage={setPage}>
          <Table>
            <thead>
              <tr>
                <th>{t('firstName')}</th>
                <th>{t('lastName')}</th>
                <th>{t('haplogroup')}</th>
                <th>ID</th>
                <th aria-label="User detail" />
              </tr>
            </thead>
            <tbody>
              {data.users.items.map((user) => (
                <tr>
                  <td>{user.profile.givenName}</td>
                  <td>{user.profile.familyName}</td>
                  <td>{user.geneticData.haplogroup.name}</td>
                  <td>{user.id}</td>
                  <td>
                    <Button variant="secondary" disabled={isMasking} onClick={() => handleUserClick(user)}>
                      {isMasking ? <LoadingIcon /> : <FontAwesomeIcon icon={faMagnifyingGlass} />}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paged>
      )}
    </>
  );
}

export default UserSearchPage;
