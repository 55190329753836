import './Map.css';
import type { Feature, Geometry } from 'geojson';
import type { Topology } from 'topojson-specification';
import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Accordion, Spinner } from 'react-bootstrap';
import useD3 from '../../hooks/useD3';
import MapModal from './MapModal';
import Tooltip from '../xShared/Tooltip';
import ApiClient, { ClientType, getUserId } from '../../utils/api-client';
import useMount from '../../hooks/useMount';
import { MapRegionDetail, Region, RegionLayer, RegionUserFilter } from '../../apiTypes/map';
import renderMap from './renderMap';
import { MapModalProps, TooltipData, TopoJsonProps } from './types';
import { MainHaplogroupSelect } from '../xShared/HaplogroupSelect';

const EmptyRegionUserFilter = Object.freeze({ familyNames: [], haplogroupIds: [] });

type RegionData = { regionLayer: RegionLayer };
async function fetchRegionData(client: ClientType, layerId: number, userFilter: RegionUserFilter): Promise<Region[]> {
  const query = gql`
    query GetLayerCounts($userFilter: RegionUserFilterInput, $layerId: Int!) {
      regionLayer(layerId: $layerId, userFilterInput: $userFilter) {
        regions {
          id
          userCount
          name
        }
      }
    }
  `;
  const { data } = (await client.query({
    query,
    variables: { userFilter, layerId },
    fetchPolicy: 'no-cache',
  })) as {
    data: RegionData;
  };

  return data.regionLayer.regions;
}

async function fetchMapData() {
  const res = await fetch('topo.json');
  return res.json();
}

async function fetchFamilyNames(client: ClientType): Promise<string[]> {
  const { data } = (await client.query({
    query: gql`
      query GetFamilyNames {
        familyNames
      }
    `,
  })) as { data: { familyNames: string[] } };
  return data.familyNames;
}

const regionDetailByFamilyNameQuery = gql`
  query GetRegionDetail($id: Int!, $userFilter: RegionUserFilterInput) {
    regionDetail(regionId: $id, userFilterInput: $userFilter) {
      haplogroupFamilyNameCounts {
        haplogroup {
          id
          name
        }
        familyNameCounts {
          familyName
          count
        }
      }
    }
  }
`;

const regionDetailQuery = gql`
  query GetRegionDetail($id: Int!, $userFilter: RegionUserFilterInput) {
    regionDetail(regionId: $id, userFilterInput: $userFilter) {
      haplogroupUserCounts {
        haplogroup {
          id
          name
        }
        count
      }
    }
  }
`;

type DetailsData = { regionDetail: MapRegionDetail };
async function getDetails(client: ClientType, id: number, userFilter: RegionUserFilter): Promise<MapRegionDetail> {
  const query = getUserId() ? regionDetailByFamilyNameQuery : regionDetailQuery;
  const { data } = (await client.query({ query, variables: { id, userFilter } })) as {
    data: DetailsData;
  };

  return data.regionDetail;
}

let currentModalProps = [];
function addModal(
  e: MouseEvent,
  d: Feature<Geometry, TopoJsonProps>,
  regionDetail: MapRegionDetail,
  setCurrentModalProps
) {
  const newModal = {
    regionDetail,
    region: d.properties,
    top: e.clientY,
    left: e.clientX,
  };

  if (currentModalProps.filter((m) => m.region === newModal.region).length !== 0) return;

  const newModalPropsArr = currentModalProps.concat([newModal]);

  setCurrentModalProps(newModalPropsArr);
}

function Map() {
  const [mapData, setMapData] = useState<Topology | undefined>(undefined);
  const [currentLayer, setCurrentLayer] = useState<number>(0);
  const [regionData, setRegionData] = useState<Region[]>([]);
  const [familyNames, setFamilyNames] = useState<string[]>([]);
  const [modalPropsArr, setModalPropsArr] = useState<MapModalProps[]>([]);
  const [regionUserFilter, setRegionUserFilter] = useState<RegionUserFilter>(EmptyRegionUserFilter);
  const [tooltip, setTooltip] = useState<TooltipData>(null);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation('map');

  currentModalProps = modalPropsArr;

  const isLoggedIn = !!getUserId();

  const layerSelectOptions = (t('granularity', { returnObjects: true }) as string[]).map((name, i) => ({
    value: i,
    label: name,
  }));

  const openRegionDetail = (e: MouseEvent, d: Feature<Geometry, TopoJsonProps>) => {
    getDetails(ApiClient.current, Number(d.id), regionUserFilter).then((huc) => addModal(e, d, huc, setModalPropsArr));
  };

  function getRemoveModalFunction(region) {
    return () => {
      const newModalProps = modalPropsArr.filter((m) => m.region !== region);
      setModalPropsArr(newModalProps);
    };
  }

  useMount(() => {
    fetchMapData().then(setMapData);
    if (isLoggedIn) fetchFamilyNames(ApiClient.current).then(setFamilyNames);
  });

  useEffect(() => {
    setLoading(true);
    fetchRegionData(ApiClient.current, currentLayer, regionUserFilter)
      .then(setRegionData)
      .then(() => setLoading(false));
  }, [regionUserFilter, currentLayer]);

  const ref = useD3(renderMap, [regionData, mapData], {
    mapData,
    regionData,
    currentLayer,
    openRegionDetail,
    setTooltip,
  });

  const handleHgSelect = (selectedItems: { value: string; label: string }[]) => {
    const haplogroupIds = selectedItems.map((s) => Number(s.value));
    setRegionUserFilter((u) => ({ haplogroupIds, familyNames: u.familyNames }));
  };

  const handleFamilyNameSelect = (selectedItems: { value: string; label: string }[]) => {
    const newFamilyNames = selectedItems.map((s) => s.value);
    setRegionUserFilter((u) => ({ familyNames: newFamilyNames, haplogroupIds: u.haplogroupIds }));
  };

  const handleLayerSelect = (selectedItem: { value: number; label: string }) => {
    setCurrentLayer(selectedItem.value);
  };

  return (
    <div className="map">
      <Accordion className="haplo-map-params-container">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="haplo-params-title">
            <span>{t('params')}</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="haplo-param-label">
              {t('haplogroups')} <small>{t('haplogroupSubtitle')}</small>{' '}
            </div>
            <MainHaplogroupSelect isMulti onSelect={handleHgSelect} />
            {isLoggedIn && (
              <>
                <div className="haplo-param-label">{t('familyName')}</div>
                <Select
                  isMulti
                  className="haplo-group-select haplo-param"
                  classNamePrefix="select"
                  options={familyNames.map((n) => ({ label: n, value: n }))}
                  isLoading={isLoading}
                  onChange={handleFamilyNameSelect}
                />
              </>
            )}

            <div className="haplo-param-label">{t('view')}</div>
            <Select
              className="haplo-group-select haplo-param"
              classNamePrefix="select"
              isLoading={isLoading}
              options={layerSelectOptions}
              defaultValue={layerSelectOptions[0]}
              onChange={handleLayerSelect}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {isLoading ? (
        <div className="map-spinner">
          <Spinner />
        </div>
      ) : (
        <svg ref={ref} className="map-canvas" viewBox="-600 -400 1250 750">
          <g className="current-layer" />
          <g className="kraje" />
        </svg>
      )}
      {modalPropsArr.map((modalProps) => (
        <MapModal
          key={modalProps.region.name}
          region={modalProps.region}
          regionDetail={modalProps.regionDetail}
          userFilter={regionUserFilter}
          top={modalProps.top}
          left={modalProps.left}
          onClose={getRemoveModalFunction(modalProps.region)}
        />
      ))}
      {tooltip && (
        <Tooltip top={tooltip.top} left={tooltip.left}>
          <div className="tooltip-kraj">{tooltip.kraj}</div>
          <div className="tooltip-chunk">{tooltip.chunk}</div>
        </Tooltip>
      )}
    </div>
  );
}

export default Map;
