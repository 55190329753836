import { gql } from '@apollo/client';

export const changeEmailMutation = gql`
  mutation ChangeEmail($userId: Int!, $newEmail: String!, $token: String!) {
    changeEmail(userId: $userId, newEmail: $newEmail, token: $token) {
      operationSuccessful
    }
  }
`;

export const setNewPasswordMutation = gql`
  mutation SetNewPassword($userId: Int!, $newPassword: String!, $token: String!) {
    resetPassword(userId: $userId, newPassword: $newPassword, token: $token) {
      operationSuccessful
    }
  }
`;

export const confirmEmailMutation = gql`
  mutation ConfirmEmail($userId: Int!, $token: String!) {
    confirmEmail(userId: $userId, token: $token) {
      operationSuccessful
    }
  }
`;

export const consentSignMutation = gql`
  mutation SignAgreement($userId: Int!) {
    signAdministrationAgreementSignStatus(userId: $userId) {
      success
      loginToken
    }
  }
`;
