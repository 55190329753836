import { gql } from '@apollo/client';

const closestRelativesQuery = gql`
  query Comparison($userId: Int!, $markerSet: String!, $ownFamily: Boolean, $forHaplogroup: Int, $isSubtree: Boolean) {
    closestStrRelatives(
      userId: $userId
      markerSetName: $markerSet
      ownFamilyNameFilter: $ownFamily
      forHaplogroup: $forHaplogroup
      isSubtree: $isSubtree
    ) {
      markerSet {
        markers
      }
      userRelativeDistances {
        user {
          profile {
            givenName
            familyName
            email
          }
          geneticData {
            haplogroup
          }
          ancestor {
            givenName
            familyName
          }
        }
        distance
        comparedMarkers
        markerSetValues
      }
      userMarkerSetValues
    }
  }
`;

export default closestRelativesQuery;
