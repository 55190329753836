import * as d3 from 'd3';
import { DependencyList, useEffect, useRef } from 'react';

export type D3Selection = d3.Selection<d3.BaseType, unknown, HTMLElement, any>;

type RenderFunction<TData> = (selection: D3Selection, data: TData) => void;

function useD3<TData>(renderFn: RenderFunction<TData>, dependencies: DependencyList, data?: TData) {
  const ref = useRef();

  useEffect(() => {
    renderFn(d3.select(ref.current), data);
  }, dependencies);

  return ref;
}

export default useD3;
