import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../apiTypes/user';
import { getUserId } from '../../utils/api-client';
import { isEmail } from '../../utils/js-helpers';
import { createStaff, getStaff, deleteStaff } from './data-providers';
import Staff from './models';
import './StaffManagement.css';

export default function StaffManagement() {
  const { t } = useTranslation('staff-management');
  const [currentEmailInInput, setCurrentEmailInInput] = useState('');
  const [displayedStaff, setDisplayedStaff] = useState<Staff[]>();
  const [loadingStaff, setLoadingStaff] = useState(true);
  const [sendingNewStaffInfoToServer, setSendingNewStaffInfoToServer] = useState(false);
  const [staffRole, setStaffRole] = useState<UserType>(UserType.Admin);
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const [deletingRequests, setDeletingRequests] = useState<number[]>([]);

  useEffect(() => {
    if (displayedStaff) return;

    setLoadingStaff(true);

    getStaff()
      .then(setDisplayedStaff)
      .finally(() => {
        setLoadingStaff(false);
      });
  }, [displayedStaff]);

  function handleEmailInputChanged(newValue: string) {
    setCurrentEmailInInput(newValue);
  }

  function handleChangeOfRole(e) {
    setStaffRole(e.target.value);
  }

  function handleAddStaff() {
    setSendingNewStaffInfoToServer(true);
    setSuccessfullyCreated(false);

    createStaff(currentEmailInInput, staffRole)
      .then(() => {
        setSuccessfullyCreated(true);
        setDisplayedStaff(null);
        setCurrentEmailInInput('');
      })
      .finally(() => {
        setSendingNewStaffInfoToServer(false);
      });
  }

  function handleDeleteOf(staff: Staff) {
    setDeletingRequests((prev) => [staff.id, ...prev]);
    setSuccessfullyCreated(false);

    deleteStaff(staff.email)
      .then(() => {
        setDisplayedStaff(null);
      })
      .finally(() => {
        setDeletingRequests((prev) => prev.filter((id) => id !== staff.id));
      });
  }

  const rolesDictionary = {};
  rolesDictionary[UserType.Admin] = 'roleAdmin';
  rolesDictionary[UserType.LabTech] = 'roleLabTech';
  rolesDictionary[UserType.Root] = 'roleRoot';

  const myId = getUserId();

  return (
    <div className="container">
      <h1 className="header mt-5">{t('staffManagement')}</h1>

      <h3 className="header mt-5 mb-4">{t('addStaff')}</h3>

      <div className="staff-input-with-alert-container">
        <div className="staff-input-container">
          <TextField
            fullWidth
            value={currentEmailInInput}
            className="manage-staff-email-input"
            onChange={(e) => {
              handleEmailInputChanged(e.target.value);
            }}
            size="small"
            label={t('newStaffEmail')}
            variant="outlined"
          />
          <TextField
            select
            label={t('staffRole')}
            className="manage-staff-role-select ms-2"
            value={staffRole}
            onChange={(e) => handleChangeOfRole(e)}
            size="small"
            fullWidth
          >
            <MenuItem value={UserType.Admin}>{t(rolesDictionary[UserType.Admin])}</MenuItem>
            <MenuItem value={UserType.LabTech}>{t(rolesDictionary[UserType.LabTech])}</MenuItem>
            <MenuItem value={UserType.Root}>{t(rolesDictionary[UserType.Root])}</MenuItem>
          </TextField>

          <LoadingButton
            loading={sendingNewStaffInfoToServer}
            disabled={!isEmail(currentEmailInInput) || sendingNewStaffInfoToServer}
            type="button"
            className="ms-3"
            color="success"
            variant="contained"
            onClick={() => handleAddStaff()}
          >
            {t('addStaff')}
          </LoadingButton>
          {successfullyCreated && (
            <Alert className="staff-success-alert" severity="success" variant="filled">
              {t('staffSuccessfullyAdded')}
            </Alert>
          )}
        </div>
      </div>

      <h3 className="header mt-5 mb-5">{t('existingStaff')}</h3>
      <TableContainer component={Paper} className="mt-4 mb-5">
        <Table aria-label="simple table" className="manage-staff-table">
          <TableHead>
            <TableRow>
              <TableCell>{t('id')}</TableCell>
              <TableCell align="right">{t('staffEmail')}</TableCell>
              <TableCell align="right">{t('staffRole')}</TableCell>
              <TableCell align="right" />{' '}
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingStaff && (
              <TableRow>
                <TableCell colSpan={4} component="th" scope="row">
                  <div className="staff-management-spinner-container">
                    <Spinner className="staff-management-spinner" />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {displayedStaff &&
              displayedStaff.map((staff) => (
                <TableRow className="staff-management-table-row" key={staff.id}>
                  <TableCell component="th" scope="row">
                    {staff.id}
                  </TableCell>
                  <TableCell align="right">{staff.email}</TableCell>
                  <TableCell align="right">{t(rolesDictionary[staff.role])}</TableCell>
                  <TableCell align="right">
                    <LoadingButton
                      disabled={staff.id === myId}
                      loading={deletingRequests.includes(staff.id)}
                      color="error"
                      variant="contained"
                      onClick={() => handleDeleteOf(staff)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} className="me-2" />
                      {t('deleteStaff')}
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
