import Select from 'react-select';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DocumentNode } from 'graphql/language';

const getAllHaplogroupsQuery = gql`
  query GetAllHaplogroups {
    haplogroups {
      id
      name
    }
  }
`;

const getMainHaplogroupsQuery = gql`
  query GetMainHaplogroups {
    allMainHaplogroups {
      id
      name
    }
  }
`;

export interface SelectValue {
  value: string;
  label: string;
}

interface Props {
  isMulti?: boolean;
  onSelect?: (selection: SelectValue | SelectValue[]) => void;
  haplogroupId?: number;
}

interface GenericProps extends Props {
  query: DocumentNode;
  queryName: string;
}

function GenericHaplogroupSelect({ query, queryName, isMulti, onSelect, haplogroupId }: GenericProps) {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const { loading } = useQuery(query, {
    onCompleted: (data) => setOptions(data[queryName].map((hg) => ({ value: String(hg.id), label: hg.name }))),
  });
  const { t } = useTranslation('map');

  const value = options.find((hg) => hg.value === String(haplogroupId));
  return (
    <Select
      className="haplo-group-select haplo-param"
      classNamePrefix="select"
      options={options}
      onChange={onSelect}
      isMulti={isMulti}
      isClearable
      isLoading={loading}
      placeholder={t('allHaplogroups')}
      value={value}
    />
  );
}

export function HaplogroupSelect(props: Props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <GenericHaplogroupSelect query={getAllHaplogroupsQuery} queryName="haplogroups" {...props} />;
}

export function MainHaplogroupSelect(props: Props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <GenericHaplogroupSelect query={getMainHaplogroupsQuery} queryName="allMainHaplogroups" {...props} />;
}
