import * as d3 from 'd3';
import { Transform } from './models/transform';

function handleZoom(elems, e: { transform: Transform }) {
  elems.attr('transform', e.transform);
}

function addZoomAndPanFunctionality(svg: d3.Selection<any, any, any, any>, offset?: Transform) {
  const elems = svg.selectChildren();

  const zoom = d3.zoom().on('zoom', (e) => {
    handleZoom(elems, e);
  });

  if (offset) {
    zoom.translateBy(svg, offset.x, offset.y);
    zoom.scaleBy(svg, offset.scale);
  }

  svg.call(zoom);
}

export default addZoomAndPanFunctionality;
