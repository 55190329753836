import { gql } from '@apollo/client';

export const userSearchQuery = gql`
  query UserSearch(
    $familyNames: StringOperationFilterInput
    $haplogroups: ComparableInt32OperationFilterInput
    $skip: Int
    $take: Int
  ) {
    users(
      where: { and: [{ profile: { familyName: $familyNames } }, { geneticData: { haplogroup: { id: $haplogroups } } }] }
      skip: $skip
      take: $take
    ) {
      totalCount
      items {
        id
        profile {
          givenName
          familyName
        }
        geneticData {
          haplogroup {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const haplogroupQuery = gql`
  query GetAllHaplogroups {
    haplogroups {
      id
      name
    }
  }
`;
