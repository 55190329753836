import { gql } from '@apollo/client';

export const addStaffMutation = gql`
  mutation CreateAdmin($email: String!, $role: UserType!) {
    createAccount(email: $email, language: ENGLISH, userType: $role) {
      userId
    }
  }
`;

export const deleteStaffMutation = gql`
  mutation DeleteStaff($staffEmail: String!) {
    deleteuser(email: $staffEmail) {
      operationSuccessful
    }
  }
`;
