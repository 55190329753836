import { Dayjs } from 'dayjs';

interface LogUser {
  id: number;
  email?: string;
  name?: string;
}

export enum RequestStatus {
  OK,
  FAIL,
}

export interface LogRecord {
  requestId: string;
  performer: LogUser;
  affectedUser: LogUser;
  endpoint: string;
  date: Dayjs;
  requestStatus: RequestStatus;
  raw: string;
}

export interface LogsResponse {
  logs: LogRecord[];
  metadata: {
    totalCount: number;
  };
}

export interface LogsPaginationParams {
  pageSize: number;
  pageIndex: number;
}

export interface FilterProps {
  requestId?: number;
  performerEmail?: string;
  affectedUserEmail?: string;
  endpointName?: string;
  from?: Dayjs;
  to?: Dayjs;
  requestStatus?: RequestStatus;
}

export interface PagingInfo {
  pageSize: number;
  currentPage: number;
}

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
