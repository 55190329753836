import { gql } from '@apollo/client';
import { RawUserForRights } from './api-queries';

export interface AddViewResult {
  addUserViewer: {
    viewers: RawUserForRights[];
  };
}

export const addViewQuery = gql`
  mutation AddView($userId: Int!, $viewerEmail: String!) {
    addUserViewer(userId: $userId, viewerEmail: $viewerEmail) {
      viewers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
    }
  }
`;

export interface RemoveEditResult {
  removeUserManager: {
    managers: RawUserForRights[];
  };
}

export const removeEditQuery = gql`
  mutation RemoveEdit($userId: Int!, $editorId: Int!) {
    removeUserManager(userId: $userId, managerId: $editorId) {
      managers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
    }
  }
`;

export interface RemoveViewResult {
  removeUserViewer: {
    viewers: RawUserForRights[];
  };
}

export const removeViewQuery = gql`
  mutation RemoveView($userId: Int!, $viewerId: Int!) {
    removeUserViewer(userId: $userId, viewerId: $viewerId) {
      viewers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
    }
  }
`;

export interface AddEditResult {
  addUserManager: {
    managers: RawUserForRights[];
  };
}

export const addEditQuery = gql`
  mutation AddEdit($userId: Int!, $editorEmail: String!) {
    addUserManager(userId: $userId, managerEmail: $editorEmail) {
      managers {
        id
        profile {
          familyName
          givenName
          email
        }
      }
    }
  }
`;
