import { MutationResult, useMutation } from '@apollo/client';
import { Kit, SequencerInput } from '../../../apiTypes/kits';
import { fetchWithAuth } from '../../../utils/api-client';
import { sendToNextStageMutation } from './queries';
import downloadBlob from '../../../utils/downloadBlob';

export function useSendToNextStage(): [(ids: number[]) => Promise<void>, MutationResult] {
  const [sendFn, result] = useMutation(sendToNextStageMutation);
  const modifiedSendFn = (kits: number[]) =>
    sendFn({ variables: { kitActions: kits.map((id) => ({ kitId: id, note: '' })) } }).then(() =>
      window.location.reload()
    );
  return [modifiedSendFn, result];
}

export function getCheckedKitIds() {
  const rows = document.getElementsByTagName('tr');
  return Object.values(rows)
    .filter((row) => row.querySelector('.kit-select-checkbox :checked') !== null)
    .map((row) => Number(row.querySelector('td.kit-id')?.textContent));
}

export function getFullName(kit: Kit) {
  const profile = kit.user?.profile;
  return `${profile?.givenName} ${profile?.familyName}`;
}

export function getAddressSummary(kit: Kit) {
  const address = kit.user?.profile?.residenceAddress;
  return `${address?.street}, ${address?.zipCode} ${address.town}`;
}

const enc = encodeURIComponent;

export async function fetchKitLabels(kitIds: number[]) {
  const queryParams = {
    addressLabel: 2,
    sampleLabel: 2,
    labelLayout: 'LabelLayout63X38',
  };
  const optsQueryString = Object.entries(queryParams)
    .map(([k, v]) => `${enc(k)}=${enc(v)}`)
    .join('&');
  const kitsQueryString = kitIds.map((id) => `kitIds=${enc(id)}`).join('&');
  const queryString = `${optsQueryString}&${kitsQueryString}`;

  const response = await fetchWithAuth(`/laboratory/label?${queryString}`);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_blank');
}

export async function fetchSequencerInput(config: SequencerInput) {
  const fileResponse = await fetchWithAuth('/laboratory/sequencer-input', {
    method: 'POST',
    body: JSON.stringify(config),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const blob = await fileResponse.blob();
  downloadBlob(blob, 'sequencerInput.txt');
}
