import { useTranslation } from 'react-i18next';
import { HaplogroupCount } from '../../apiTypes/geneticData';
import { GlobalCounts } from '../../apiTypes/map';

interface Props {
  haplogroupUserCounts: HaplogroupCount[];
  globalCounts: GlobalCounts;
}

function UnregisteredMapModalTable({ haplogroupUserCounts, globalCounts }: Props) {
  const { t } = useTranslation('map');
  return (
    <table className="haplo-table">
      <thead>
        <tr>
          <th>{t('haplogroup')}</th>
          <th>{t('userCount')}</th>
          <th>{t('countryUserCount')}</th>
        </tr>
      </thead>
      <tbody>
        {haplogroupUserCounts.map((huc) => (
          <tr>
            <td>{huc.haplogroup.name}</td>
            <td>{huc.count}</td>
            <td>{globalCounts[huc.haplogroup.id]?.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UnregisteredMapModalTable;
