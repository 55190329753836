import './ComparisonCard.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDna, faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons';
import { UserRelativeDistance } from '../../apiTypes/relativesSearch';

function ComparisonItem({ name, data }: { name: string; data: string | number }) {
  return (
    <Row>
      <Col xs={12}>
        <strong>{name}</strong>
      </Col>
      <Col xs={12}>{data}</Col>
    </Row>
  );
}

interface Props {
  comparison: UserRelativeDistance;
  onInfo: (_: UserRelativeDistance) => void;
  onDetail: (_: UserRelativeDistance) => void;
}

const formatDistance = (n: number, t: TFunction) => (n === 0 ? t('exactMatch') : n);

function ComparisonCard({ comparison, onInfo, onDetail }: Props) {
  const { t } = useTranslation('relatives');
  return (
    <div className="relative-card">
      <div className="relative-card-header">
        <div className="relative-name ms-3">
          <FontAwesomeIcon icon={faUser} />
          <span className="ms-3">
            {comparison.user.profile.givenName} {comparison.user.profile.familyName}
          </span>
        </div>
        <Button
          className="ms-2 me-2"
          variant="contained"
          color="info"
          onClick={() => onInfo(comparison)}
          sx={{ borderRadius: 28 }}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} /> <span className="ms-2"> Detail </span>
        </Button>
      </div>
      <div className="relative-card-body ps-3 pt-3 pb-3">
        <ComparisonItem name={t('markersTested')} data={comparison.comparedMarkers} />
        <ComparisonItem name={t('haplogroup')} data={comparison.user.geneticData.haplogroup} />
        <ComparisonItem name={t('genDistance')} data={formatDistance(comparison.distance, t)} />
        <ComparisonItem
          name={t('oldestKnownRelative')}
          data={`${comparison.user.ancestor.givenName ?? ''} ${comparison.user.ancestor.familyName ?? ''}`}
        />
        <Button
          color="warning"
          variant="outlined"
          className="relative-comparison-btn pt-2 pb-2 ps-3 pe-3 me-2"
          onClick={() => onDetail(comparison)}
          sx={{ borderRadius: 28 }}
        >
          <FontAwesomeIcon className="me-2" icon={faDna} />
          {t('comparison')}
        </Button>
      </div>
    </div>
  );
}

export default ComparisonCard;
