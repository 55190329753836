import { Col, Container, Row } from 'react-bootstrap';
import TopBar from '../AppSkeleton/TopBar/TopBar';
import './AboutPage.css';
import Footer from '../AppSkeleton/Footer/Footer';

function AboutPage() {
  return (
    <div className="about-page">
      <TopBar />
      <div className="about-page-content">
        <Container className="mt-5">
          <Col>
            <h1 className="header mb-5">O projektu</h1>
            <p>
              Genetika a příjmení je vědecký projekt zkoumající haplotypy chromozomu Y, což jsou specifické regiony DNA,
              předávané výhradně z otce na syna. Stejně jako chromozom Y také většina příjmení je děděna v mužské linii,
              představují tedy jakýsi zástupný genetický znak. Cílem projektu je hledat vztahy mezi genetickými liniemi
              a příjmeními a zasadit je do kontextu celkové populační struktury, geografie a historie.
            </p>
            <p>
              Do projektu se mohou zapojit muži bez ohledu na věk, u jejichž přímé otcovské linie se dá předpokládat
              přítomnost na území Čech, Moravy a Slezska nejpozději v 19. století.
            </p>
            <p>
              Účastníci zjistí, k jaké paternální linii (Y-haploskupině) náleží. Dále získají přístup do databáze DNA
              profilů s možností vyhledávání shod. DNA analýza je pro účastníky projektu zdarma.
            </p>
            <p>
              Výzkum je otevřený akademické spolupráci. Geneticko-genealogická data nabývají na významu nejen pro
              genealogii, ale také pro archeologii, historii, demografii nebo lingvistiku.
            </p>
          </Col>
          <Row className="mt-5 mb-4">
            <Col sm={6}>
              <h3 className="header mb-4">Řešitel výzkumu</h3>
              <p>
                Kriminalistický ústav
                <br />
                Strojnická 27
                <br />
                Praha 7, 170 89
              </p>
            </Col>
            <Col sm={6}>
              <h3 className="header mb-4">Kontaktní osoba</h3>
              <p>
                Mgr. Vlastimil Stenzl
                <br />
                <a href="mailto:vlastimil.stenzl@pcr.cz">vlastimil.stenzl@pcr.cz</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer px-0">
        <Footer />
      </div>
    </div>
  );
}

export default AboutPage;
